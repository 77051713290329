import React from 'react'
import {Form, Input} from 'semantic-ui-react'
import { parsePhonenumber, formatPhonenumber } from 'utils/phonenumberFormat';

const TextFormField = (props) => {
    let value = props.object[props.valueKey]?props.object[props.valueKey]:'';
    return (
        <Form.Field 
        autoComplete={props.autoComplete||'off'}
        onChange={(event, {value}) => props.update(value,props.valueKey)} 
        control={props.control} 
        label={props.label} 
        placeholder={props.placeholder?props.placeholder:props.label} 
        value={value}
        width={props.width}
        disabled={props.disabled}
        type={props.type}
        error={props.error}
        className={props.className}
    />

    )
}

const PhoneFormField = (props) => {

    return (
        <Form.Field
        onChange={(event, {value}) => {
                props.update(parsePhonenumber(value),props.valueKey)
            }
        } 
        autoComplete="disabled"
        type="tel"
        pattern="[\(]\d{3}[\)]\d{3}[\-]\d{4}"
        control={Input} 
        label={props.label} 
        placeholder="(xxx) xxx-xxxx" 
        value={props.number?formatPhonenumber(props.number):''}
        width={props.width}
    />

    )
}

export {TextFormField, PhoneFormField}