import { SHOW_MIC, SHOW_MIC_FULLFILLED, RESET_MIC_TOKEN } from 'actions/MICActions';

export const MICReducer = (state = {
    showMIC: 'true'===window.sessionStorage.getItem('showMIC')?true:false,
    account_token: window.sessionStorage.getItem('account_token')
}, action) => {
    switch (action.type) {
        case SHOW_MIC:
            return state;
        case SHOW_MIC_FULLFILLED:
            window.sessionStorage.setItem('showMIC', action.payload.showMIC)
            window.sessionStorage.setItem('account_token', action.payload.account_token)
            return {...state, ...action.payload};
        case RESET_MIC_TOKEN: {
            window.sessionStorage.setItem('showMIC', false)
            window.sessionStorage.setItem('account_token', null)
            return {...state, showMIC:false,account_token:null};
        }
        default:
            return state;
    }
}