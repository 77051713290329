import {Observable} from 'rxjs'
import { SEND_INSTANT_MESSAGE, sendInstantMessageFullfilled } from 'actions/InstantMessageActions';
import { BASE_URL } from 'App'
import { handleAjaxError } from './sessionEpic';


export function sendInstantMessageEpic(action$, store) {
    return action$.ofType(SEND_INSTANT_MESSAGE)
    .switchMap(({payload}) => {
        return Observable.ajax(
            {
                url: BASE_URL+`/ep/${store.getState().session.scope}/send/${payload.communication_type}`,
                method:'POST',
                responseType:'json',
                body:`at=${encodeURIComponent(store.getState().session.at)}'&pl=${encodeURIComponent(JSON.stringify(payload))}`
                }
        );
    }).map(result => sendInstantMessageFullfilled())
    .catch(e => {
        return Observable.concat([handleAjaxError(e), sendInstantMessageFullfilled()])
    })
}