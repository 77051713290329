import React, { Component } from 'react'
import { Modal, Header, Button, Form, TextArea, Grid, Dimmer, Loader, Input} from 'semantic-ui-react'
import moment from 'moment'
import 'react-widgets/dist/css/react-widgets.css';
import {TextFormField, PhoneFormField} from 'widgets/FormWidgets'
import { ClientsSelectionContainer } from 'containers/ClientsSelectionContainer/ClientsSelectionContainer';
import ClickableDateTimePicker from 'widgets/ClickableDateTimePicker';
import modalInlineStyle from 'utils/modalCSSFix';
import validateEmail from 'utils/emailValidation';
import { AppointmentDetail } from 'components/AppointmentDetail/AppointmentDetail';

export class AppointmentRequestDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAppointmentRequest:{...props.selectedAppointmentRequest},
           endPicker_open:'date',
           selectedClients:[],
           availableClients:[],
           newClient:[]
        }

    }

    static getDerivedStateFromProps(props, state) {
        if(state.availableClients.length<props.availableClients.length) {
            return {...state,availableClients:props.availableClients}
        }
        return {...state};
    }

    createNewReminder(index) {
            let offset = this.props.reminderOffsets.length>index?this.props.reminderOffsets[index]:this.props.reminderOffsets[this.props.reminderOffsets.length-1];
            let offsetDate = moment(this.props.selectedAppointment.start_timestamp).add(offset.offset, 'ms').endOf('day');
            return {
                communication_type:this.props.communicationTypes[0].name,
                communication_message_template:this.props.communicationMessageTemplates[0].uuid,
                status:moment().isAfter(offsetDate)?'Inactive':'Active',
                offset_type:offset.name
                };
    }

    update(value, key) {
        let updated = {...this.state.selectedAppointmentRequest};
        updated[key] = value;
        /*if(key==='clients') {
            updated.title = concatedClientNames(value, this.props.availableClients);
        }*/
        this.setState(
            {...this.state, 
                selectedAppointmentRequest:updated
            }
        )
    }

    _endTimePicker_openClick(event) {
        if (event.target.tagName === 'INPUT') {
          this.setState({...this.state, endTimePicker_open: 'calendar'});
        }
      }

      _endTimePicker_handleToggle(view) {
        this.setState({ ...this.state, endTimePicker_open: view })
      }
    
    editAppointment() {
        let clients = this.state.selectedClients;
        let {given_name, family_name, email, area_code, phone_number, extension, uuid} = this.state.selectedAppointmentRequest;        
        if(clients.length<1){
            const newClient = [{
                given_name,
                family_name,
                email,
                uuid:uuid,
                client_phones: [{
                    type:'Mobile',
                    area_code,
                    phone_number,
                    extension
                }]
            }];
            this.setState({...this.state,
                newClient,
                possibleClients:this.state.availableClients.concat(newClient)});
            clients = [uuid]
        } else {
            this.setState({...this.state,possibleClients:this.state.availableClients});
        }
        this.props.appendAppointmentToApptRequest(this.state.selectedAppointmentRequest,clients);
    }



    saveSelectedAppointment(appointment) {
        let date = appointment;
        let newClient = this.state.newClient[0];
        if(newClient) {
            date = {...appointment,
                clients:appointment.clients.map(clientId => {
                        if(clientId===newClient.uuid){
                            let {given_name, family_name, email, client_phones} = newClient;
                            return {
                                given_name,
                                family_name,
                                email,
                                client_phones,
                                client_type:'Person',
                                professional:this.props.professional,
                            }
                        }
                        return clientId;
                    })
                }
        }
        let apptRequest = {...this.props.selectedAppointmentRequest, date, status:'PROCESSED'}
        this.props.onSaveAppointmentRequest(apptRequest);
    }

    render () {

        if(this.props.selectedAppointmentRequest.date&&this.state.possibleClients) {
            return <AppointmentDetail 
            occasions={this.props.occasions}
            communicationMessageTemplates={this.props.communicationMessageTemplates}
            reminderOffsets={this.props.reminderOffsets}
            communicationTypes={this.props.communicationTypes}
            availableClients={this.state.possibleClients}
            deleteAppointment={(appointment) => this.deleteAppointment(appointment)}
            loading={this.props.loading}
            selectedAppointment={this.props.selectedAppointmentRequest.date} 
            close={() => this.props.close()} 
            saveSelectedAppointment={(appointment) => this.saveSelectedAppointment(appointment)}
            availableTags={this.props.availableTags}
            professional={this.props.professional}
            communicationPreferences={this.props.communicationPreferences}
            dateLocations={this.props.dateLocations}
            scope={this.props.scope}
            />
        }
        return (
            <Modal
                dimmer={this.dimmer}
                closeOnDimmerClick={false}
                open={this.state.selectedAppointmentRequest!=null}
                style={modalInlineStyle}
                onClose={() => this.props.close()}
                closeIcon
            >
                  <Modal.Header>Inspect Appointment Request</Modal.Header>
                  <Dimmer active={this.props.loading}>
                    <Loader />
                </Dimmer>
                <Modal.Content>
                <Modal.Description>
                    <Form size='tiny'>
                    
                    <Grid>
                    <h3>Add as new Client</h3>
        <Grid.Column width={16} textAlign="left"> 
        <Form.Group widths='equal'>
        <TextFormField 
            valueKey='email'
            error={this.state.selectedAppointmentRequest.email&&!validateEmail(this.state.selectedAppointmentRequest.email)}
            control={Input} 
            label='Email' 
            object={this.state.selectedAppointmentRequest}
            update={(value, key) => this.update(value, key)}            
        />
        <Form.Field>
    <label>Phone&#42;</label>
        <PhoneFormField
        number={`${this.state.selectedAppointmentRequest.area_code}${this.state.selectedAppointmentRequest.phone_number}${this.state.selectedAppointmentRequest.extension}`}
        />
        </Form.Field>
        </Form.Group>
        </Grid.Column>
        <Grid.Column width={16} textAlign="left"> 
        <Form.Group widths='equal'>
        <TextFormField 
            valueKey='given_name'
            control={Input} 
            label='First Name' 
            object={this.state.selectedAppointmentRequest}
            update={(value, key) => this.update(value, key)}            
        />
                <TextFormField 
            valueKey='family_name'
            control={Input} 
            label='Last Name' 
            object={this.state.selectedAppointmentRequest}
            update={(value, key) => this.update(value, key)}            
        />
</Form.Group>
</Grid.Column>
<h2 style={{textAlign:'center',width:'100%',margin:'0px'}}>- OR -</h2>
<h5>Tie to an existing client or we'll create a new client automatically</h5>
<Grid.Column width={16} textAlign="left"> 

<Form.Field>
    <label>Client(s)&#42;</label>
<ClientsSelectionContainer 
    availableClients={this.state.availableClients}
    selectedClients={this.state.selectedClients} 
    onChange={(clients) => this.setState({...this.state,selectedClients:clients})} 
    />
</Form.Field>
</Grid.Column>

<Grid.Column width={16} textAlign="left">

<TextFormField 
                    valueKey='message'
                    control={TextArea} 
                    label='Message' 
                    object={this.state.selectedAppointmentRequest}
                    update={(value, key) => this.update(value, key)}
                />
</Grid.Column>

<Grid.Column width={16} textAlign="left"> 
<Header as="h5">Appointment Date</Header>
    <Form.Group widths='equal'>
    <Form.Field>
    <label>Start&#42;</label>
    <ClickableDateTimePicker 
    dropUp={true}
    value={moment(this.state.selectedAppointmentRequest.start_timestamp, moment.ISO_8601).toDate()}
    onChange={(value) => this.update(moment(value).toISOString(), 'start_timestamp')}
    />
    </Form.Field>
    <Form.Field>
    <label>End&#42;</label>
    <ClickableDateTimePicker
    dropUp={true}
    value={moment(this.state.selectedAppointmentRequest.end_timestamp, moment.ISO_8601).toDate()}
    onChange={(value) => this.update(moment(value).toISOString(), 'end_timestamp')}
    />
    </Form.Field>
    </Form.Group>
</Grid.Column>
</Grid>
  </Form>
                    </Modal.Description>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button color='grey' onClick={() => this.props.close()}>
                      Cancel
                    </Button>
                    <Button content="Decline Appointment" color='red' icon="cancel" labelPosition='right' onClick={() => {if(window.confirm('Do you really want to reject this appointment request?'))this.props.handleRejectAppointmentRequest(this.state.selectedAppointmentRequest)}} />  
                    <Button positive icon='arrow right' labelPosition='right' content="Next" onClick={() => this.editAppointment()} />
                  </Modal.Actions>
                </Modal>
        )
    }
}

