import React, {Component} from 'react';
import { possibleColors } from 'utils/layoutUtils';
import 'styles/colorPicker.css';



export class ColorPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div style={{display:'flex',flexWrap:'wrap',width:'180px'}}>
                {possibleColors.map(color => {
                    return (
                        <div 
                        key={color} 
                        className={className(color, this.props.selectedColor)+' colorPicker_'+color} 
                        onClick={() => this.props.onSelect(color)}
                        />
                    )
                })}
            </div>
        )
    }
}

const className = (color, selectedColor) => {
    if(color === selectedColor) {
        return "colorPickerSelected"
    }
    return "colorPicker"
}

