import React from 'react'
import { Header, Tab } from 'semantic-ui-react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Footer } from 'components/Footer/Footer'

export const aboutTab = () => {
  return (
    <Tab.Pane>
      <Header as='h3'>
        About
            </Header>
      <Footer />
    </Tab.Pane>
  )
}