import {LOGIN, LOGIN_FULLFILLED, CHANGE_PASSWORD, CHANGE_USERNAME, SET_LOGIN_ERROR_MESSAGE, CHANGE_SCOPE, RESTART_TOUR, END_TOUR, DISMISS_ERRORS, HANDLE_ERROR, PING, PING_FULLFILLED, UNSAVED_CHANGES} from 'actions/SessionActions'
import { SET_SAVE_TAG_ERRORS } from 'actions/TagActions';
import parseDomain from 'parse-domain'
import { SEND_INSTANT_MESSAGE, SEND_INSTANT_MESSAGE_FULLFILLED } from 'actions/InstantMessageActions';
import { CHANGE_PROFESSIONAL, LOGIN_WITH_TOKEN } from '../actions/SessionActions';

function subdomain() {
    if(window
        &&window.location){
            let domain = parseDomain(window.location.href, { customTlds:/localhost|\.local/ })
            if(domain) {
                let subdomain = domain.subdomain
                if(subdomain&&subdomain.includes('.dev')) {
                    return subdomain.substring(0,subdomain.indexOf('.dev'));
                }
                if(subdomain&&subdomain.includes('.beta')) {
                    return subdomain.substring(0,subdomain.indexOf('.beta'));
                }
                return domain.subdomain;
            }
        }
        return '';
}

const SessionReducer = (state = {
    username:'',
    password:'',
    errors:[],
    defined_scope:subdomain().length>0,
    at: window.sessionStorage.getItem('at'),
    company: window.sessionStorage.getItem('company'),
    show_tour: JSON.parse(window.sessionStorage.getItem('show_tour')),
    offices: window.sessionStorage.getItem('offices') ? window.sessionStorage.getItem('offices').split(',') : [],
    professional: window.sessionStorage.getItem('professional'),
    possible_professionals: window.sessionStorage.getItem('possible_professionals')?JSON.parse(window.sessionStorage.getItem('possible_professionals')):[],
    scope: window.sessionStorage.getItem('scope') || subdomain(),
    user_id: window.sessionStorage.getItem('user_id'),
    features: JSON.parse(window.sessionStorage.getItem('features')||'[]')
}, action) => {
    console.log('in session reducer')
    let {payload, type} = action;
    switch (type) {
        case LOGIN:
            return  {...state,username:'',password:'', loading: true}
        case LOGIN_WITH_TOKEN:
            window.sessionStorage.setItem('at', payload.at)
            return  {...state,...payload, loading: true}
        case LOGIN_FULLFILLED:
            window.sessionStorage.setItem('at', payload.at)
            window.sessionStorage.setItem('company', payload.company)
            window.sessionStorage.setItem('show_tour', !payload.last_login_date)
            window.sessionStorage.setItem('offices', payload.offices)
            window.sessionStorage.setItem('professional', payload.professional)
            window.sessionStorage.setItem('possible_professionals', JSON.stringify(payload.possible_professionals))
            window.sessionStorage.setItem('scope', payload.scope)
            window.sessionStorage.setItem('user_id', payload.user_id)
            window.sessionStorage.setItem('features', JSON.stringify(payload.features))
            return {...payload, 
                errors:[],
                loginErrorMessage:'', 
                loading:false,
                show_tour: !payload.last_login_date
            }
        case CHANGE_PASSWORD:
            return {...state,password:payload.password,loading:false}
        case CHANGE_USERNAME:
            return {...state,username:payload.username,loading:false}
        case CHANGE_SCOPE:
            return {...state,scope:payload.scope,loading:false}
        case SET_LOGIN_ERROR_MESSAGE:
            return {...state, defined_scope:false, loginErrorMessage:payload.message,loading:false}
        case SET_SAVE_TAG_ERRORS:
            return {...state}
        case SEND_INSTANT_MESSAGE:
            return {...state, sendingEmail : true}
        case SEND_INSTANT_MESSAGE_FULLFILLED:
            return {...state, sendingEmail : false}
        case END_TOUR:
            return {...state, show_tour: false}
        case RESTART_TOUR: 
            return {...state, show_tour: true}
        case HANDLE_ERROR:
            return {...state, loading: false, errors:[...state.errors, payload.error]}
        case DISMISS_ERRORS:
            return {...state, errors:[]}
        case UNSAVED_CHANGES: 
            return {...state,hasUnsavedChanges:payload.hasUnsavedChanges}
        case PING:
            return {...state}
        case PING_FULLFILLED:
            window.sessionStorage.setItem('at', payload.at)
            return {...state, at:payload.at}
        case CHANGE_PROFESSIONAL:
            window.sessionStorage.setItem('professional', payload.professional)
            return {...state, professional:payload.professional}
        default:
            return state
    }
}

export default SessionReducer