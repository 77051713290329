import { connect } from 'react-redux';
import {SmartTags} from 'components/SmartTags/SmartTags';
import { deleteTag, fetchTags, selectTag, fetchSelectedTag } from 'actions/TagActions'
import { fetchCategories, selectCategory, saveSmartTag, fetchClientsForCategory } from '../../actions/SmartTagActions';
import { fetchSettings } from '../../actions/SettingsActions';

const mapStateToProps = (state, ownProps) => {
    return {
        categories: state.smartTags.categories,
        selectedCategory: state.smartTags.selectedCategory,
        professional_object: state.settings.professionals[0],
        office: state.settings.offices[0],
        tags: state.tags.tags,
        loading: state.tags.loading,
        professional: state.session.professional,
        selectedTag: state.tags.selectedTag,
        saveTagErrors: state.tags.saveTagErrors,
        loading_selected_smart_tag: state.smartTags.loading_selected_smart_tag,
        communicationMessageTemplates:state.metaData.communicationMessageTemplates,
        communicationTypes:state.metaData.communicationTypes,
        occasions:state.metaData.occasions
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleSelectSmartTag: (category) => dispatch(selectCategory(category)),
        handleFetchSettings: () => dispatch(fetchSettings()),
        handleSaveChanges: (smartTag) => dispatch(saveSmartTag(smartTag)),
        handleSelectTag: (tag) => dispatch(tag.uuid?fetchSelectedTag(tag):selectTag(tag)),
        fetchTags:(professional) => dispatch(fetchTags(professional)),
        handleDeleteTag: (tag) => dispatch(deleteTag(tag)),
        fetchCategories: (professional) => dispatch(fetchCategories(professional)),
        handleFetchClients: (professionalId, category, page, length) => dispatch(fetchClientsForCategory(professionalId, category, page, length)),
    }
}

const SmartTagsContainer = connect(mapStateToProps, mapDispatchToProps)(SmartTags);


export {SmartTagsContainer}