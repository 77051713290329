import {Observable} from 'rxjs'
import { RELOAD_INTEGRATION_SETTINGS } from '../actions/IntegrationSettingsAction';
import { reloadIntegrationSettingsFullfilled } from '../actions/IntegrationSettingsAction';
import { requestForEndpointAndPayload } from '../App';

export function fetchIntegrationSettingsEpic(action$, store) {

    return action$.ofType(RELOAD_INTEGRATION_SETTINGS)
    .switchMap(({payload}) => {
    return Observable.ajax(requestForEndpointAndPayload('query',{
        google_sync_settings:  [
                    {
                        and : [
                            ["professional","=",payload.professional_id]
                        ]
                    }
                ],
       /* google_calendar_syncs:  [
                        {
                            and : [
                                ["professional","=",payload.professional_id]
                            ]
                        }
                ],*/
                microsoft_sync_settings:  [
                    {
                        and : [
                            ["professional","=",payload.professional_id]
                        ]
                    }
                ],
                zoom_settings:  [
                    {
                        and : [
                            ["professional","=",payload.professional_id]
                        ]
                    }
                ],
       /* microsoft_calendar_syncs:  [
                        {
                            and : [
                                ["professional","=",payload.professional_id]
                            ]
                        }]*/
                    })).map(({response}) => {
    return reloadIntegrationSettingsFullfilled(response.google_sync_settings[0],response.microsoft_sync_settings[0],response.zoom_settings[0]);
})
});
}
