import { connect } from 'react-redux';
import {ClientsSelection} from 'widgets/ClientsSelection';



const mapDispatchToProps = (dispatch, ownProps) => {
    return {}
}

const mapStateToProps = (state, ownProps) => {
    return {
        professional: state.session.professional,
    }
}

const ClientsSelectionContainer = connect(mapStateToProps, mapDispatchToProps)(ClientsSelection);

export {ClientsSelectionContainer}