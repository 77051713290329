

export const DATE_REMINDER = 'DATE_REMINDER'; 
export const DATE_NOTIFICATION = 'DATE_NOTIFICATION';
export const DATE_CHANGE_NOTIFICATION = 'DATE_CHANGE_NOTIFICATION';
export const DATE_CANCELLATION_NOTIFICATION = 'DATE_CANCELLATION_NOTIFICATION';
export const BIRTHDAY = 'BIRTHDAY';
export const TAG_CAMPAIGN = 'TAG_CAMPAIGN';
export const CAMPAIGN = 'CAMPAIGN';

export function templatePersonalizationForTemplate(professional, templateId) {

}

export function settingsForOccasion(object, occasion) {
    return object.communication_preferences.filter(pref =>  pref.occasion === occasion)
}

export function settingForOccasion(object, occasion) {
    return settingsForOccasion(object, occasion)[0];
}

const templatesForOccasion = (templates, occasion)  => templates.filter(template => template.occasion === occasion);
export {templatesForOccasion};

const communicationTypesForOccasion = (communicationTypes, occasions, occasionName) =>{
    let filteredOccasion = occasions
    .filter(occasion => occasion.name === occasionName)[0];
   return communicationTypes.filter(communicationType => filteredOccasion
    .communication_types.includes(communicationType.name))
}
 
export {communicationTypesForOccasion}

export function sortByUUID(array, key) {
   return array.sort((a1,b1) => {
        const a = key?a1[key]:a1;
        const b = key?b1[key]:b1;
        let aIndex = a.uuid?a.uuid:a.tuuid;
        let bIndex = b.uuid?b.uuid:b.tuuid;
        return aIndex.localeCompare(bIndex);
    });
}