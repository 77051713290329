export const parsePhonenumber = (fnumber = '') => fnumber.replace(/\D/g,'');

export function formatPhonenumber(rawNumber = '') {
    if(rawNumber.startsWith('+1')) {
      rawNumber = rawNumber.substring(2)   
    }
    const input = parsePhonenumber(rawNumber); // First ten digits of input only
    const zip = input.substring(0,3);
    const middle = input.substring(3,6);
    const last = input.substring(6,10);
    const extension = input.substring(10)

    if(input.length > 10){return `(${zip}) ${middle}-${last} ext. ${extension}`;}
    else if(input.length > 6){return `(${zip}) ${middle}-${last}`;}
    else if(input.length > 3){return `(${zip}) ${middle}`;}
    else if(input.length > 0){return `${zip}`;}
    else {return '';}
}