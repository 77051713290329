import React, { Component } from 'react'
import { Form, Button, Icon, Grid, Segment, Label, Tab, Checkbox, Message} from 'semantic-ui-react'
import {TagDetail} from 'components/TagDetail/TagDetail'
import './Tags.css';
import PropTypes from 'prop-types';
import {randomColor} from 'utils/layoutUtils';
import Wrapper from 'widgets/Wrapper';
import ComponentWrapper from 'widgets/ComponentWrapper/ComponentWrapper'
import DateTable from 'widgets/DateTable';
import ClientTable from 'widgets/ClientTable';
import LoadingIndicator from 'widgets/LoadingIndicator';
import moment from 'moment';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, 
    ContentState, 
    bold, 
    italic,
    underline, 
    superscript, 
    subscript, 
    fontSize,
    unordered,
    ordered,
    indent,
    outdent,
    left,
    center,
    right,
    color,
    link,
    unlink,
    image,
    eraser,
    undo,
    redo,
    justify,
    strikethrough,
    monospace
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EMAIL } from 'constants/CommunicationTypes';
import { PreviewButton } from 'widgets/PreviewButton';

export class Tags extends Component {
    constructor(props) {
        super(props)
        this.state ={};
        this.bound_initializeData = this.initializeData.bind(this);
    }

   

    show(dimmer) {
         this.setState({...this.state, dimmer})
    }

    close() {
        this.setState({...this.state,selectedTag:null})
    }

    initializeData() {
      this.props.fetchTags(this.props.professional);
    }
    
    componentDidMount() {
      document.addEventListener('professionalChanged',  this.bound_initializeData, false);
        this.props.fetchTags(this.props.professional);
    }


    componentWillUnmount() {
      document.removeEventListener('professionalChanged', this.bound_initializeData);
    }

    handleSaveTag(savedTag) {
            this.props.handleSaveChanges(savedTag)
    }

    handleDeleteTag(deleteTag) {
            this.props.handleDeleteTag(deleteTag)
    }

    selectedTag() {
        if(this.props.selectedTag) {
            return this.props.selectedTag;
        }
        else {
            return {professional:this.props.professional,
                color:randomColor(),
                name:'',
                description:''}
        }
    }

    reminderCommunicationTypes() {
        let reminderOccasion = this.props.occasions
        .filter(occasion => occasion.name === 'TAG_CAMPAIGN')[0];
       return this.props.communicationTypes.filter(communicationType => {
            
            return reminderOccasion.communication_types.includes(communicationType.name)
       })
    }

    templatesForOccasion() {
        return this.props.communicationMessageTemplates
        .filter(template => template.occasion === 'TAG_CAMPAIGN')
     }

    render () {
        const selectedTag = this.selectedTag();
        return (<ComponentWrapper>
            <Wrapper icon="tags" loading={this.props.loading}>    
            <Grid>
        <Grid.Column width={4}>
        <Segment.Group>
        <Segment color={this.selectedTag().uuid?null:'black'} inverted={!this.selectedTag().uuid} onClick={() => this.props.handleSelectTag({professional:this.props.professional,color:randomColor(),name:'',description:''})}>
                        
                        <Label color={this.selectedTag().uuid?'grey':'black'} inverted={this.selectedTag().uuid}>Add Tag... <Icon name='tag' /></Label>
        </Segment>
          {this.props.tags.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map((tag) => {
              const selected = this.props.selectedTag && tag.uuid===this.props.selectedTag.uuid
              return (
            <Segment 
                key={tag.uuid} 
                color={selected?tag.color:null} 
                inverted={selected} 
                onClick={() => this.props.handleSelectTag(tag)} style={{cursor:'pointer'}}>
                <Label color={tag.color}>{tag.name}</Label>
            </Segment>
        )}
        )}
          </Segment.Group>
        </Grid.Column>

        <Grid.Column stretched width={12}>
          <Segment><LoadingIndicator loading={this.props.loading_selected_tag}>
          {selectedTag.uuid&&(<div><Tab activeIndex={this.state.tagTabIndex} onTabChange={(event, data) => this.setState({...this.state,tagTabIndex:data.activeIndex})} panes={ [
                            { menuItem: 'Clients', render: () => <Tab.Pane>

                            <ClientTable clients={this.selectedTag().clients} pageSize={20} />
                            </Tab.Pane> },
                        { menuItem: 'Appointments', render: () => <Tab.Pane>
                            <DateTable dates={this.selectedTag().dates} pageSize={20}/>
                            </Tab.Pane> },
                       { menuItem: 'Communication', render: () => <Tab.Pane>
                           <SendCommunication communicationMessageTemplates={this.templatesForOccasion()} communicationTypes={this.reminderCommunicationTypes()} tag={this.selectedTag()} sendToTag={(tag) => this.handleSaveTag(tag)} professional={this.props.professional} buttonLabel="Send"/>
                      
                       </Tab.Pane> },
                       { menuItem: 'Tag', render: () => (<Tab.Pane>
                            <TagDetail 
                            saveTagErrors={this.props.saveTagErrors}
                            selectedTag={this.selectedTag()} 
                            close={() => this.props.handleSelectTag(null)} 
                            onSaveTag={(tag) => this.handleSaveTag(tag)}
                            onDeleteTag={(tag) => this.handleDeleteTag(tag)}
                        /></Tab.Pane>) },
  
          ]} /></div>)}
{!selectedTag.uuid&&(<div><h1>Create Tag</h1>
    <TagDetail 
    saveTagErrors={this.props.saveTagErrors}
    selectedTag={this.selectedTag()} 
    close={() => this.props.handleSelectTag(null)} 
    onSaveTag={(tag) => this.handleSaveTag(tag)}
    onDeleteTag={(tag) => this.handleDeleteTag(tag)}
/></div>)
}</LoadingIndicator>
          </Segment>
        </Grid.Column>
      </Grid> 
      </Wrapper>

      </ComponentWrapper>
        )
    }
}

Tags.propTypes = {
    tags:PropTypes.array,
    handleSaveChanges:PropTypes.func,
    handleDeleteTag:PropTypes.func
}

export class SendCommunication extends Component{
    constructor(props) {
        super(props);
        this.state = {
            selectedTag : {...props.tag}
        };
    }

    updateTag(key, value) {
        let selectedTag = {...this.state.selectedTag};
        selectedTag[key] = value;
        this.setState({...this.state,selectedTag})
    }

    render() {
    let displayedUI;
    if(this.state.selectedTag.status !== 'Active') {
        displayedUI =  <Form>
<Form.Field>
      <Checkbox 
        label={`Send to ${this.props.tag.clients.length} tagged  client${this.props.tag.clients.length>1?'s':''}`} 
        checked={this.state.selectedTag.send_to_clients}
        onChange={(event, {checked}) => this.updateTag('send_to_clients', checked)}
      />
    </Form.Field>
<Form.Field>
      <Checkbox 
        label={`Send to clients with ${this.props.tag.dates.length} tagged appointment${this.props.tag.dates.length>1?'s':''}`} 
        checked={this.state.selectedTag.send_to_date_clients}
        onChange={(event, {checked}) => this.updateTag('send_to_date_clients', checked)}    
    />
      </Form.Field>
{/*
        <Form.Group>
        <Form.Field width="6">
        <Dropdown 
        disabled={this.state.selectedTag.status === 'Active'}
        placeholder='Communication Type' 
        value={this.state.selectedTag.communication_type}
        fluid 
        selection 
        options={this.props.communicationTypes.map(type => { return {text:type.beautified_name,value:type.name}})}
        onChange={(event, {value}) => this.updateTag('communication_type', value)}/>
</Form.Field>
<Form.Field width="6">
        <Dropdown 
        disabled={this.state.selectedTag.status === 'Active'}
        placeholder='Message Template' 
        value={this.state.selectedTag.communication_message_template}
        fluid 
        selection 
        options={this.props.communicationMessageTemplates.map(template => {return {text:template.name,value:template.uuid}})} 
        onChange={(event, {value}) => this.updateTag('communication_message_template', value)}
        />
        </Form.Field>

        <Form.Field width="2">
        <Button icon="eye" onClick={() => alert('Currently not supported')}/>
        </Form.Field>
        <Form.Field width="2">
        {this.state.selectedTag.status !== 'Active'&&<Button 
            color={this.state.selectedTag.color} 
            onClick={() => this.props.sendToTag({...this.state.selectedTag, status:'Active'})}
            disabled={this.state.selectedTag.status === 'Active'}
            >{this.props.buttonLabel}</Button>}
        {this.state.selectedTag.status === 'Active'&&<Label icon="truck" fitted color="blue" inverted/>}
        </Form.Field>
        </Form.Group>
        */}
        {this.state.selectedTag.last_processing_date&&<div>Last Sent: {moment(this.state.selectedTag.last_processing_date).format('llll')}</div>}

<Form.Input 
    label='Subject' 
    type='text' 
    placeholder="Your email subject here..."
    onChange={(event, {value}) => this.updateTag('personal_note_email_subject', value)}
    value={this.state.selectedTag.personal_note_email_subject||''}
/>  
<div style={{height:'400px'}}>
        <Editor
  wrapperClassName="wrapper-class"
  editorClassName="editor-class"
  toolbarClassName="toolbar-class"
  initialContentState={convertToRaw(ContentState.createFromBlockArray(htmlToDraft(this.state.selectedTag.personal_note || '')))}
  toolbar={editorToolbar}
  //initialEditorState={editorState}
  /*wrapperStyle={<wrapperStyleObject>}
  editorStyle={<editorStyleObject>}
  toolbarStyle={<toolbarStyleObject>}*/
  onChange={(contentState) => this.updateTag('personal_note', draftToHtml(contentState))}
/>
</div>
<div style={{marginTop:'10px'}}>
<Button.Group><PreviewButton templatePersonalization={this.state.selectedTag.personal_note} sender={this.props.professional} communicationMessageTemplate={this.props.communicationMessageTemplates[0].uuid} communicationType='EMAIL'/>
<Button 
            color='green'
            onClick={() => this.props.sendToTag(
                {...this.state.selectedTag, 
                    communication_message_template:this.props.communicationMessageTemplates[0].uuid,
                    communication_type: EMAIL
                })}
                ><Icon name='save'/> Save</Button>
<Button 
            color={this.state.selectedTag.color}  
            onClick={() => {if(window.confirm('Are you sure?')){
                this.props.sendToTag(
                {...this.state.selectedTag, 
                    status:'Active',
                    communication_message_template:this.props.communicationMessageTemplates[0].uuid,
                    communication_type: EMAIL
                }
            )
        }}}
                ><Icon name='send'/> {this.props.buttonLabel}</Button></Button.Group></div>
            </Form>
    } else {
        displayedUI = <Message icon>
        <Icon name='circle notched' loading />
        <Message.Content>
          <Message.Header>Processing...</Message.Header>
          We are busy sending your emails.
        </Message.Content>
      </Message>
    }
    return (
        
            <Segment><h5>Quickly and easily send a message to clients with the <Label color={this.state.selectedTag.color} content={this.state.selectedTag.name} /> tag.</h5>
                {displayedUI}
        </Segment>
    )
}
}


const editorToolbar = {
    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', /*'embedded', 'emoji', */'image', 'remove', 'history'],
    inline: {
      inDropdown: true,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
      bold: { icon: bold, className: undefined },
      italic: { icon: italic, className: undefined },
      underline: { icon: underline, className: undefined },
      strikethrough: { icon: strikethrough, className: undefined },
      monospace: { icon: monospace, className: undefined }, 
      superscript: { icon: superscript, className: undefined },
      subscript: { icon: subscript, className: undefined },
    },
    blockType: {
      inDropdown: true,
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    fontSize: {
      icon: fontSize,
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    fontFamily: {
      options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    list: {
      inDropdown: true,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['unordered', 'ordered', 'indent', 'outdent'],
      unordered: { icon: unordered, className: undefined },
      ordered: { icon: ordered, className: undefined },
      indent: { icon: indent, className: undefined },
      outdent: { icon: outdent, className: undefined },
    },
    textAlign: {
      inDropdown: true,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['left', 'center', 'right', 'justify'],
      left: { icon: left, className: undefined },
      center: { icon: center, className: undefined },
      right: { icon: right, className: undefined },
      justify: { icon: justify, className: undefined },
    },
    colorPicker: {
      icon: color,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
        'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
        'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
        'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
        'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
        'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
    },
    link: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      dropdownClassName: undefined,
      showOpenOptionOnHover: true,
      defaultTargetOption: '_self',
      options: ['link', 'unlink'],
      link: { icon: link, className: undefined },
      unlink: { icon: unlink, className: undefined },
    },/*
    emoji: {
      icon: emoji,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      emojis: [
        '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
        '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
        '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
        '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
        '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
        '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
        '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
        '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
        '✅', '❎', '💯',
      ],
    },
    embedded: {
      icon: embedded,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      defaultSize: {
        height: 'auto',
        width: 'auto',
      },
    },*/
    image: {
      icon: image,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: true,
      uploadCallback: undefined,
      previewImage: false,
      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: 'auto',
        width: 'auto',
      },
    },
    remove: { icon: eraser, className: undefined, component: undefined },
    history: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['undo', 'redo'],
      undo: { icon: undo, className: undefined },
      redo: { icon: redo, className: undefined },
    },
  };

