import { SAVE_TAG, DELETE_TAG, FETCH_TAGS, FETCH_TAGS_FULLFILLED, FETCH_SELECTED_TAG, FETCH_SELECTED_TAG_FULLFILLED, CLEAR_SELECTED_TAG, SAVE_TAG_FULLFILLED, SELECT_TAG, SET_SAVE_TAG_ERRORS, DELETE_TAG_FULLFILLED } from 'actions/TagActions';

const TagReducer = (state = {tags:[]}, action) => {
    let {payload, type} = action
    let {tags} = state;
    switch(type) {
        case SAVE_TAG:
            return { ...state,
                loading_selected_tag: true}
        case SAVE_TAG_FULLFILLED:
            return {tags: filterOutTag(tags,payload.tag.uuid)
                .concat(payload.tag), selectedTag:payload.tag, loading_selected_tag:false}
        case SELECT_TAG:
            return {...state, selectedTag: payload.tag, saveTagErrors:null}
        case SET_SAVE_TAG_ERRORS:
            return {...state, saveTagErrors: payload.errors}
        case DELETE_TAG:
            return { ...state,
                loading: true}
        case DELETE_TAG_FULLFILLED:
            return { ...state,
                selectedTag:null,
                 tags: filterOutTag(tags,payload),
                loading: false}
        case FETCH_TAGS:
            return { professional:payload.professional,
                tags:[],
                /* loading: true */}
        case FETCH_TAGS_FULLFILLED:
            return { tags: payload.tags.tags,
                /* loading: false */}
        case FETCH_SELECTED_TAG:
            return {...state,
                selectedTag: payload.selectedTag,
                loading_selected_tag: true}
        case CLEAR_SELECTED_TAG:
            return {...state,
                selectedTag:null,
                loading: false}
        case FETCH_SELECTED_TAG_FULLFILLED:
            return {...state,
                selectedTag: payload.selectedTag,
                loading_selected_tag: false}
        default: 
            return state;
    }
}

export default TagReducer;

const filterOutTag = (tags, tag_uuid) => 
    tags.filter(currentTag => currentTag.uuid !== tag_uuid);