import React from 'react'
import { arrayToSentence } from 'utils/stringUtils';
import _ from 'lodash';
import { Observable } from 'rxjs'
import { timer } from 'rxjs/observable/timer';
import {
  Label,
  Icon
} from 'semantic-ui-react';
import { StackedIconGroup } from 'components/StackedIconGroup/StackedIconGroup'

import './MICIntegration.css';
import { IS_DEV } from '../../App';

class MICButton extends React.Component {

  constructor(props) {
    super(props);
    this.receiverForThreadMessage = null;
    this.baseURL = props.baseURL;
    if (!this.baseURL) {
      this.baseURL = `https://messagecenter.${IS_DEV?'dev.':''}mojo.accountants/cgi-bin/WebObjects/bullhornBackend.woa/wa/ClientAPI/`
      //this.baseURL = 'http://127.0.0.1:54321/cgi-bin/WebObjects/bullhornBackend.woa/wa/ClientAPI/'
    }
    this.state = { unread_message_count: 0 }
  }

  handleIncomingMessage(e) {
    /* 
                let isInvitation = false;
                try {
                    isInvitation = JSON.parse(e.data).mic_status === "loaded"
                }catch(e){}
                
                if ((e.origin === "https://dev.practicemojo.com"
                || e.origin === "https://www.practicemojo.com"
                || e.origin === "https://backend.mojo.accountants"
                || e.origin === "https://messagecenter.mojo.accountants") && isInvitation){
                    window.setTimeout(function() {
                    //this.openMIC()
                    if (this.props.receiverForThreadMessage) {
                        this.postMessageToMIC(this.props.receiverForThreadMessage);
                        this.props.setReceiverForThreadMessage(null);
                    } else {
                        this.addReceiversToMic([], this.props.tab)
                    }
                }.bind(this),200);
                }; */
  }

  addReceiversToMic(_receivers, tab, label) {

    this.props.openMIC();
    let receivers;
    if (_receivers) {
      receivers = this.props.receivers.concat(_receivers);
      this.props.setReceivers(_receivers);
    }
    if (tab) {
      this.props.setTab(tab);
    }
    if (this.props.showMic) {
      try {
        this.postMessageToMIC({ receivers, tab, label });
        this.props.setReceivers(null);
      } catch (e) {
        console.error(e);
      }
    }

  }

  componentDidMount() {
    window.addEventListener("message", (event) => this.handleIncomingMessage(event));
    if (this.props.openOnStart) {
      this.props.openMIC();
    } else {
      this.props.closeMIC();
    }
    this.refreshInterval = timer(1000, 10 * 1000)/* interval(10*1000)*/.switchMap((val) => {
      return this.updateObservable();
    }
    ).catch(e => console.error(e) ).subscribe(result => this.updateUnreadBatch(result));
  }

  componentWillUnmount() {
    this.refreshInterval.unsubscribe();
  }

  updateObservable() {
    if (this.props.token) {
      return Observable.ajax(
        {
          url: `${this.baseURL}getUnreadThreadPreviews`,
          method: 'POST',
          responseType: 'json',
          body: `t=${encodeURIComponent(this.props.token)}`
        }).switchMap(result => {
          return Observable.of(result.response);
        }
        )
    } else {
      return Observable.of([]);
    }
  }

  updateUnreadBatch(result) {
    if (this.props.showMIC) {
      this.postMessageToMIC('update_data');
    }
    this.setState({ ...this.state, unread_message_count: result.length })
    if (result.length > 0) {

      var newThreads = result;
      if (result.length < 2) {

        this.receiverForThreadMessage = JSON.stringify({ threadForReceiver: newThreads[0].receiver.receiver_id, tab: '/inbox/' + newThreads[0].receiver.receiver_id });
        this.notify('You have a new message from '
          + newThreads[0].receiver.first_name + ' ' + newThreads[0].receiver.last_name,
          _.truncate(
            newThreads[0].last_message.message_body,
            { 'length': 24, 'separator': /,? +/ }),
          newThreads[0].last_message.message_id
        );
      } else {
        this.receiverForThreadMessage = null;
        const names = newThreads.map(function (currentThread) { return currentThread.receiver.first_name + ' ' + currentThread.receiver.last_name });
        this.notify(`You have new ${newThreads.length} messages from ${arrayToSentence(names, 3)}`,
          '',
          newThreads.reduce(
            function (currentString, currentThread) {
              return currentString + ':' + currentThread.last_message.message_id
            },
            ''
          )
        )
      }
    }
  }

  render() {
    return (
      <div
        onClick={() => this.props.openMIC()}
        id="mic_wrapper_closed"
        style={{ marginBottom: this.props.showMIC ? -80 : 0}}
      >
        <div id="mic_wrapper_icon">
          <StackedIconGroup>
            <Icon name="mobile alternate" size="big"/>
            <Icon name="comment" className="right"/>
            <Icon name="commenting" className="right" style={{ color: '#2c3e50'}}/>
          </StackedIconGroup>
          {
            this.state.unread_message_count > 0 && <Label color="red" floating>{this.state.unread_message_count}</Label>
          }
        </div>
        <span>
          Instant Message
        </span>
      </div> 
    )

  }


  notify(title, body, tag) {
    var currentMicTag = window.sessionStorage.getItem("mic_notifications")
    if (_.isNil(currentMicTag)) {
      currentMicTag = '';
    }
    if (Notification
      && !currentMicTag.includes(tag)) {
      window.sessionStorage.setItem("mic_notifications", currentMicTag + ':' + tag);
      if (Notification.permission === "granted") {
        var notification = new Notification(title,
          {
            body: body,
            tag: tag,
            //icon:'https://<webobject name = "Domain"/><WebObject name="notificationIcon" />'
          });
        notification.onclick = function () {
          //window.parent.focus();
          window.focus();
          this.props.openMIC()
          //this.receiverForThreadMessage!=null?this.showMessageThreadInMic():this.addReceiversToMic([], 'inbox');
        }
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission(function (permission) {
          if (permission === "granted") {
            var notification = new Notification(
              title,
              {
                body: body,
                tag: tag,
                icon: `https://mojo.accountants${import('assets/logo.png')}`
              });

            notification.onclick = () => {
              //window.parent.focus();
              window.focus();
              this.receiverForThreadMessage != null ? this.showMessageThreadInMic() : this.addReceiversToMic([], 'inbox');
            }
          }
        });
      }
    }
  }

  showMessageThreadInMic() {
    this.openMIC();
    if (this.props.showMic) {
      try {
        this.postMessageToMIC(this.receiverForThreadMessage);
        this.receiverForThreadMessage = null;
      } catch (e) {
        console.error(e);
      }
    }

  }


  postMessageToMIC(message) {
    var messageContent = typeof (message) === 'string' ? message : JSON.stringify(message);
    document.getElementById('mic_integration').contentWindow.postMessage(messageContent, '*');
  }
}

export default MICButton;

/*
const mic_label_closed_style = {
    fontSize:'20px',
    transform:'rotate(270deg)',
    height:'200px',
    width:'200px'
}

const mic_close_icon_style = {
    fontSize:'36px',
    color:'#FFF'
}

const mic_integration_button_style = {
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    width:'40px',
    height:'220px',
    position:'fixed',
    right:'0px',
    top:'150px',
    zIndex: '999',
    padding:'10px',
    textAlign: 'center',
    cursor: 'pointer',
    color:'#FFF',
    borderBottomLeftRadius: '20px',
    borderTopLeftRadius: '20px',
    fontWeight: 'bold',
    backgroundColor:'rgb(0, 123, 195)'
}

const mic_integration_style = {
    width: '100%',
    height: '100%',
    border: 'none'
}

const mic_integration_container_style = {
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    height: '80%',
    width: '405px',
    zIndex: '9999',
    right: '0px',
    top: '121px',
    position: 'fixed',
    borderLeft: '1px solid #007bc3',
    backgroundColor:'rgb(0, 123, 195)'
}
*/
// const unreadThreadsBadgeStyle = {
//   borderRadius: '20px 20px 20px 20px',
//   backgroundColor: '#f00',
//   height: '24px',
//   width: '24px',
//   fontSize: '14px',
//   float: 'left',
//   position: 'relative',
//   paddingTop: '2px',
//   marginRight: '0px',
//   //visibility: 'hidden'
// }



/* var MIC_IS_ACTIVE = false;
var mic_patients = [];
var mic_tab = 'inbox'; */
/* window.addEventListener("message", function (e) {
	var isInvitation = false;
	try {
		isInvitation = JSON.parse(e.data).mic_status === "loaded"
	}catch(e){}
	
	if ((e.origin === "https://dev.practicemojo.com"
	|| e.origin === "https://www.practicemojo.com") && isInvitation){
    	window.setTimeout(function() {
    	MIC_IS_ACTIVE = true;
    	if (this.receiverForThreadMessage) {
    		postMessageToMIC(receiverForThreadMessage);
    		receiverForThreadMessage = null;
    	} else {
    		addReceiversToMic([], mic_tab)
    	}
    }.bind(this),200);
    };
}.bind(this)
); */


//var receiverForThreadMessage = null;

/* function addReceiversToMic(receivers, tab, label) {

    showMic();
    if(receivers) {
        mic_patients = mic_patients.concat(receivers);
    }
    if (tab) {
        mic_tab = tab;
    }
    if(MIC_IS_ACTIVE) {
        try{
            postMessageToMIC({receivers:mic_patients,tab:tab, label:label});
        	mic_patients = [];
        } catch(e) {
        console.error(e);
        }
    } 
    
} */

