import React from 'react';
import { Icon } from 'semantic-ui-react';
import './EventDetailsWrapper.css'

const EventDetailsWrapper = ({event, title}) => {
  //const _microsoft = false;
  return (
    <div className="event-details-wrapper">
      {event.type === 'GOOGLE_EVENT' && <Icon name="google" style={{ fontSize: '1rem', height: '1rem', lineHeight: '1rem'}} />}
      {event.type === 'MICROSOFT_EVENT' && <Icon name="windows" style={{ fontSize: '1rem', height: '1rem', lineHeight: '1rem'}} />}
        <span className="event-title">{title}</span>
    </div>
  )
}

export default EventDetailsWrapper