import React from 'react'
import {Message, Icon} from 'semantic-ui-react'

export default function LoadingIndicator(props) {

    if(props.loading) {
        return (<Message icon>
            <Icon name='circle notched' loading />
                <Message.Content>
                    <Message.Header>
                        Just one second
                    </Message.Header>
                    We're fetching that content for you.
                </Message.Content>
        </Message>)
    }
    if(props.children&&props.children.length>0) {
        return props.children;
    }
    return null;
}