import { FETCH_ALL_CAMPAIGNS, FETCH_ALL_CAMPAIGNS_FULLFILLED, SELECT_CAMPAIGN, SAVE_SELECTED_CAMPAIGN, SAVE_SELECTED_CAMPAIGN_FULLFILLED, DELETE_SELECTED_CAMPAIGN, DELETE_SELECTED_CAMPAIGN_FULLFILLED } from '../actions/CampaignActions';

const CampaignReducer = (state = {campaigns:[], categories:[], holiday_records:[], tags:[]}, action) => {
    let {payload, type} = action
    switch(type) {
        case FETCH_ALL_CAMPAIGNS:
            return {...state, fetching:true};
        case FETCH_ALL_CAMPAIGNS_FULLFILLED:
            return {...state, fetching:false,campaigns:payload.campaigns,tags:payload.tags, categories:payload.categories, holiday_records:payload.holiday_records,template:payload.template}
        case SELECT_CAMPAIGN:
            return {...state, selectedCampaign:payload.selectedCampaign}
        case SAVE_SELECTED_CAMPAIGN:
                    return {...state, saving:true}
        case SAVE_SELECTED_CAMPAIGN_FULLFILLED:
                return {...state, 
                        selectedCampaign:payload.selectedCampaign, 
                        campaigns:state.campaigns
                            .filter(campaign => campaign.uuid !== payload.selectedCampaign.uuid).concat(payload.selectedCampaign),
                        saving:false}
        case DELETE_SELECTED_CAMPAIGN:
                    return {...state, deleting:true}
        case DELETE_SELECTED_CAMPAIGN_FULLFILLED:
                return {...state, 
                    selectedCampaign:null,
                        campaigns:state.campaigns
                            .filter(campaign => campaign.uuid !== payload.deletedCampaign),
                        deleting:false
                    }
        default:
            return state;
    }

}

export default CampaignReducer;
