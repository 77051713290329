
// @flow
import React, { Component } from 'react';
import { MainContainer } from './containers/';



import {createStore, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';

import 'semantic-ui-css/semantic.min.css';

import {rootEpic} from './epics'
import createHistory from 'history/createBrowserHistory'
import { routerMiddleware } from 'react-router-redux'
import { composeWithDevTools } from 'redux-devtools-extension';

import {createEpicMiddleware} from 'redux-observable'
import RootReducer from './reducers/RootReducer';

import './App.css';


const epicMiddleware = createEpicMiddleware(rootEpic);

// Create a history of your choosing (we're using a browser history in this case)
const history = createHistory()

// Build the middleware for intercepting and dispatching navigation actions
const routerMiddleWare = routerMiddleware(history)


const store = createStore(RootReducer,
          composeWithDevTools(
              applyMiddleware(routerMiddleWare,epicMiddleware)
            )
  )
export {store}

export function getToken() {
  return "at="+encodeURIComponent(store.getState().session.at);
}

export const IS_DEV = window.location.host.includes('.dev.');
export const BASE_URL = `https://backend.${IS_DEV?'dev.':''}mojo.accountants/cgi-bin/WebObjects/AthenaBackend.woa`;
//export const BASE_URL = "http://127.0.0.1:7777/cgi-bin/WebObjects/AthenaBackend.woa";
export const IS_BETA = window.location.host.includes('.beta.');

export function requestForEndpointAndPayload(endpoint:string, payload:object ) {
  return {
    url: BASE_URL+`/ep/${store.getState().session.scope}/${endpoint}`,
    method:'POST',
    responseType:'json',
    body:`at=${store.getState().session.at?encodeURIComponent(store.getState().session.at):''}&pl=${encodeURIComponent(JSON.stringify(payload))}`
    }
}

export function requestForAnyWhereMeeting(aptId) {
  return {
      url: BASE_URL+`/amtg/${store.getState().session.scope}/provideMeeting/${aptId}`,
      method:'POST',
      responseType:'json',
      body:`at=${encodeURIComponent(store.getState().session.at)}`
  }
}


export function requestForRecordURL(aptId) {
  return {
      url: BASE_URL+`/amtg/${store.getState().session.scope}/meetingRecord/${aptId}`,
      method:'POST',
      responseType:'json',
      body:`at=${encodeURIComponent(store.getState().session.at)}`
  }
}

export function scope() {
  return store.getState().session.scope;
}


class App extends Component {
  render() {
    return (
      <Provider store={store}>
         <MainContainer history={history} />
      </Provider>
    );
  }
}

export default App;
