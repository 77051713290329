// @flow
import moment from 'moment'
import { DateTime } from 'luxon';


export function timespanForDateAndView(date, view) {
    switch(view){
        case 'week':
            return timespanForWeek(date);
        case 'work_week':
            return timespanForWorkWeek(date);
        case 'day':
            return timespanForDay(date);
        case 'agenda':
            return timespanForAgenda(date);
        case 'month':
        default:
            return timespanForMonth(date);
      }
}

export function timespanForWeek(date:Date) {
return {
            start: dateObject(moment(date).startOf('week')),
            end: dateObject(moment(date).endOf('week'))
        }
}

function timespanForWorkWeek(date) {
    return {
        start: dateObject(moment(date).startOf('week').add(1, 'day')),
        end: dateObject(moment(date).endOf('week').add(-1, 'day'))
    }
}

function timespanForDay(date) {
    return {
        start: dateObject(moment(date).startOf('day')),
        end: dateObject(moment(date).endOf('day'))
    }
}



function timespanForAgenda(date) {
    return {
        start: dateObject(moment(date)),
        end: dateObject(moment(date).add(1,'month'))
    }
}

function timespanForMonth(date) {
    return {
        start: dateObject(moment(date).startOf('month').add(-6,'week')),
        end: dateObject(moment(date).endOf('month').add(6,'week'))
    }
}

export function dateObject(date:moment) {
    return date.toDate()
}

export function convertToJSONString(date:Date):string {
    return moment(date).toISOString(true);
}

export function transferTimezone(date, hours, minutes) {
    const expDate = DateTime.fromJSDate(date).toUTC();
     return new Date(expDate.year,
                    expDate.month-1,
                    expDate.day,
                    hours,
                    minutes,
                    0,
                    0)
}
const utcOffset = moment().utcOffset();

export function normalizedLocalDate(momentObject) {
    if(momentObject.hours()>0||momentObject.minutes()>0) {
        momentObject.add(-1*utcOffset,'minutes').toDate();
    }
    return new Date(momentObject.years(),momentObject.months(),momentObject.date(),0,0,0,0);

}