import { connect } from 'react-redux';
import { dismissErrors } from 'actions/SessionActions';
import { ErrorDisplay } from 'components/ErrorDisplay/ErrorDisplay';




const mapDispatchToProps = (dispatch, ownProps) => {
       return { handleDismissErrors: () => dispatch(dismissErrors())}
}

const mapStateToProps = (state, ownProps) => {
    return {
        errors: state.session.errors
    }
}

const ErrorDisplayContainer = connect(mapStateToProps, mapDispatchToProps)(ErrorDisplay);

export {ErrorDisplayContainer}