export const stringOrdering = (a, b, key) => {
    if(!a&&b) {
        return -1;
    }
    if(a&&!b) {
        return 1;
    }
    if(!a&&!b) {
        return 0;
    }
    if(key) {
        let compA = a[key]?a[key].toLowerCase():''
        let compB = b[key]?b[key].toLowerCase():''
        return compA.localeCompare(compB) 
    }
    return a.toLowerCase().localeCompare(b.toLowerCase())
};

export const sortArrayWithKey = (anArray, aKey) => {
    return anArray.sort((a,b) => stringOrdering(a,b,aKey));
}