import React, { Component } from 'react'
import { Icon, Grid, Segment, Label, Header } from 'semantic-ui-react'
import moment from 'moment-timezone'

import './Campaigns.css';
import PropTypes from 'prop-types';

import Wrapper from 'widgets/Wrapper';
//import {SendCommunication} from 'components/Tags'
import ComponentWrapper from 'widgets/ComponentWrapper/ComponentWrapper'


import { CampaignDetail } from '../CampaignDetail/CampaignDetail';
import { sortArrayWithKey } from '../../utils/sortUtils';


export class Campaigns extends Component {
    constructor(props) {
        super(props)
        this.state ={
          selectedCampaign:{
            professional:props.professional,
            office:props.office,
            categorie_names:[]
          },
          errors:[]
        };
        this.bound_initializeData = this.initializeData.bind(this); 
    }



  initializeData() {
    this.setState({
      ...this.state,
      selectedCampaign:{
        professional:this.props.professional,
        office:this.props.office
      },
      errors:[]
    });
      this.props.fetchAllCampaigns(this.props.professional);
      this.props.selectCampaign({});
  }

  componentWillUnmount() {
    document.removeEventListener('professionalChanged', this.bound_initializeData);
  }


    show(dimmer) {
         this.setState({...this.state, dimmer})
    }

    close() {
        this.setState({...this.state,selectedTag:null})
    }

    componentDidMount() {
      document.addEventListener('professionalChanged',  this.bound_initializeData, false);
      //this.props.fetchAllCampaigns(this.props.professional);
        //this.props.fetchTags(this.props.professional);
        this.props.fetchAllCampaigns(this.props.professional);
        //this.props.selectCampaign({});
    }

    handleSaveCampaign(savedCampaign) {
            this.props.handleSaveChanges(savedCampaign)
    }

    handleDeleteCampaign(deletedCampaign) {
            this.props.handleDeleteCampaign(deletedCampaign)
    }

    selectedCampaign() {
        if(this.props.selectedCampaign) {
            return this.props.selectedCampaign;
        }
        else {
            return {
              professional:this.props.professional,  
              office:this.props.office
            }
        }
    }

    reminderCommunicationTypes() {
        let reminderOccasion = this.props.occasions
        .filter(occasion => occasion.name === 'TAG_CAMPAIGN')[0];
       return this.props.communicationTypes.filter(communicationType => {
            
            return reminderOccasion.communication_types.includes(communicationType.name)
       })
    }

    handlePagination(activePage) {
      this._handleFetchClients({ page: activePage })
  }

  handlePageSize(page_size) {
      this._handleFetchClients({ page: 1, page_size })
  }

  updateCampaign(key, value) {
    if(arguments.length === 1) {
      let selectedCampaign = {...this.state.selectedCampaign,...key}||{...this.props.selectedCampaign,...key};
      selectedCampaign[key] = value;
      this.setState({...this.state,selectedCampaign})
    } else {
        let selectedCampaign = {...this.state.selectedCampaign}||{...this.props.selectedCampaign};
        selectedCampaign[key] = value;
        this.setState({...this.state,selectedCampaign})
    }
}

setCampaignSchedule(schedule) {   
    if(schedule==='CUSTOM'&&!this.state.selectedCampaign.recurrence_rules) {
      const today = moment().startOf('day').utc().format('YYYYMMDD[T]HHmmss[Z]');
      const inOneYear = moment().startOf('day').add(1,'year').utc().format('YYYYMMDD[T]HHmmss[Z]');
      this.updateCampaign(
                            {
                              recurrence_rules:`DTSTART:${today}\nRRULE:FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1;UNTIL=${inOneYear};WKST=SU`,
                              holiday:schedule
                            }
                          );   
    } else {
      
    this.updateCampaign('holiday',schedule);
    }
}


validateSave() {
  const campaignToBeSaved = this.campaignToBeSaved();
  const errors = [];
  if (!campaignToBeSaved.name) {
    errors.push('a campaign needs a name');
  }
  if ( (!campaignToBeSaved.tags||campaignToBeSaved.tags.length < 1)
    && (!campaignToBeSaved.categories || campaignToBeSaved.categories.length < 1)
    ) {
      errors.push('you need to define an audience');
  }
  if (!campaignToBeSaved.holiday
    && (campaignToBeSaved.holiday !== 'CUSTOM' 
      || !campaignToBeSaved.recurrence_rules)
    ) {
      errors.push('a need to define a schedule');
  }
  if (!campaignToBeSaved.email_content
    || !campaignToBeSaved.email_subject
    ) {
      errors.push('you need to create content (subject and body)');
  }
  if(errors.length>0) {
    this.setState({...this.state,errors});
    return false;
  }
  this.setState({...this.state,errors});
  return true;
}

getCampaignSchedule() {
  if(this.state.selectedCampaign.holiday) {
    return this.state.selectedCampaign.holiday;
  }
  if(this.state.selectedCampaign.recurrence_rules) {
    return 'CUSTOM';
  }
  return null;
}

  _handleFetchClients({
      professional=this.props.professional,
      page=this.props.selectedCategory.client_query.clients_pagination_info.page,
      page_size=this.props.selectedCategory.client_query.clients_pagination_info.page_size,
      category=this.props.selectedCategory
  }) {
      this.props.handleFetchClients(professional, category, page, page_size);
  }
  

  campaignToBeSaved() {
    const campaignToBeSaved = {...this.state.selectedCampaign};
    if(campaignToBeSaved.holiday==='CUSTOM') {
      campaignToBeSaved.holiday = null;
    }
    if(campaignToBeSaved.status === 'RUNNING') {
      campaignToBeSaved.status = undefined;
    }
    return campaignToBeSaved;
  }

  saveSelectedCampaign() {
    if(this.validateSave()) {
      this.props.saveSelectedCampaign(this.campaignToBeSaved());
    }
  }

    templatesForOccasion() {
        return this.props.communicationMessageTemplates
        .filter(template => template.occasion === 'TAG_CAMPAIGN')
     }

    render () {
      
        return (<ComponentWrapper>
            <Wrapper icon="bullhorn" loading={this.props.fetchingCampaigns}>    

            <Grid>
        <Grid.Column width={4}>
        <Segment.Group>
        <Segment style={{cursor:'pointer'}} color={this.selectedCampaign().uuid?null:'black'} inverted={!this.selectedCampaign().uuid} onClick={() => this.props.selectCampaign({office:this.props.office,professional:this.props.professional})}>
                        
                        <Label color={this.selectedCampaign().uuid?'grey':'black'} inverted={this.selectedCampaign().uuid}>Add Campaign... <Icon name='bullhorn' /></Label>
        </Segment>
          {sortArrayWithKey(this.props.campaigns,'name').map((campaign) => {
              const selected = this.props.selectedCampaign && campaign.uuid===this.props.selectedCampaign.uuid
              return (
            <Segment 
                key={campaign.uuid} 
                color={selected?'green':null} 
                inverted={selected} 
                onClick={() => this.props.selectCampaign(campaign)} style={{cursor:'pointer'}}>
                <Header as="h4">{campaign.name}</Header>
                {campaign.note}
            </Segment>
        )}
        )}
          </Segment.Group>
        </Grid.Column>

        <Grid.Column stretched width={12}>
          <CampaignDetail 
          saving = {this.props.saving}
           categories={this.props.categories}
           campaigns={this.props.campaigns}
           tags={this.props.tags}
           professional={this.props.professional}
           office={this.props.office}
           selectedCampaign={this.props.selectedCampaign}
           holidayRecords={this.props.holidayRecords}
           fetchingCampaigns={this.props.fetchingCampaigns}
           saveSelectedCampaign={this.props.saveSelectedCampaign}
           deleteSelectedCampaign={this.props.deleteSelectedCampaign}
           template={this.props.template}
          />
        </Grid.Column>
      </Grid> 
      </Wrapper>

      </ComponentWrapper>
        )
    }
}

Campaigns.propTypes = {
    tags:PropTypes.array,
    handleSaveChanges:PropTypes.func,
    handleDeleteTag:PropTypes.func
}
