import React, { Component } from 'react'
import {Message, Modal, Button, Form, Input, TextArea, Grid, Dropdown, Tab, Divider, Checkbox } from 'semantic-ui-react'
import TagsSelection from 'widgets/TagsSelection'
import US_STATE_LIST from 'utils/usStateList'
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment'
import 'react-widgets/dist/css/react-widgets.css';
import {TextFormField} from 'widgets/FormWidgets'
import _ from 'lodash';
import ClickableDateTimePicker from 'widgets/ClickableDateTimePicker';
import modalInlineStyle from 'utils/modalCSSFix';
import PhoneNumberSection from 'widgets/PhoneNumberSection';
import { ConfirmationStatusIcon } from 'widgets/ConfirmationStatusIcon';
import { ConfirmationStatus } from 'constants/ConfirmationStatus';
import { DataTable } from 'widgets/DataTable';
import validateEmail from 'utils/emailValidation';


moment.locale('en')
momentLocalizer()

export class ClientDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedClient : props.selectedClient
        }
    }
    saveChanges(){
/*         if(this.state.selectedClient.birthday){
            this.state.selectedClient
        } */
        if(this.state.selectedClient.email&&!validateEmail(this.state.selectedClient.email)) {
            alert('Invalid email address.')
            return;
        }
        if(this.state.selectedClient.family_name) {
            this.props.onSaveChanges(this.state.selectedClient)
        } else {
            alert('Client needs a Last Name');
        }
    }
    update(value, key) {
        let updated = {...this.state.selectedClient};
        updated[key] = value;
        this.setState(
            {...this.state, 
                selectedClient:updated
            }
        )
    }
    render () {
        return (
            <Modal
                dimmer={this.dimmer}                
                closeOnDimmerClick={false}
                open={this.props.selectedClient!=null}
                style={modalInlineStyle}
                onClose={() => this.props.close()}
                closeIcon
            >
                  <Modal.Header>{(this.state.selectedClient.uuid?'Edit':'Create')+' Client'}</Modal.Header>

                  <Modal.Content>
                    <Modal.Description>
                    <Form size='tiny'>
                    <Grid>
        <Grid.Column width={6} textAlign="left">  
        <PersonalDataSection  selectedClient={this.state.selectedClient} update={(value, key) => this.update(value, key)} />
        <Form.Group widths='equal'>
        <TextFormField 
            valueKey='email'
            error={this.state.selectedClient.email&&!validateEmail(this.state.selectedClient.email)}
            control={Input} 
            label='Email' 
            object={this.state.selectedClient}
            update={(value, key) => this.update(value, key)}            
        />
        </Form.Group>
        <Form.Field>
                <label>Opt out of receiving Emails</label>
                <Checkbox 
                        toggle 
                        checked={!this.state.selectedClient.can_email}
                        onChange={(event, {checked}) => this.update(!checked, 'can_email')}
            />
           </Form.Field>{/*
           <Form.Field>
        <label>Seasonal Holiday <Popup trigger={<Icon name='help circle' />} content='We use this information to send seasonal greeting cards.' /></label>
        <Dropdown 
            options={[
                {text:'Christmas',value:'Christmas'},
                {text:'Chinese New Year',value:'Chinese New Year'},
                {text:'Hanukkah (Jewish)',value:'Hanukkah'},
                {text:'Eid al-Adha (Islam)',value:'Eid al-Adha'},
                {text:'Orthodox Christmas',value:'Orthodox Christmas'},
                {text:'Independence Day',value:'Independence Day'},
                {text:'Thanksgiving',value:'Thanksgiving'},
                {text:'Oshogatsu (Shinto/Japanese)',value:'Oshogatsu'},
                {text:'Diwali (Hindu/India)',value:'Diwali'},
            ]}
            scrolling
            search
            value={this.state.selectedClient.seasonal_holiday}
            onChange={(event,{value}) => {this.update(value, 'seasonal_holiday')}}
            selection
            />
        </Form.Field>*/}
    <Form.Field>
       <label>Tags</label>
    <TagsSelection 
        selectedTags={this.state.selectedClient.tags} 
        onChange={(tags) => this.update(tags,'tags')}
        availableTags={this.props.availableTags}/>
    </Form.Field>
    </Grid.Column>
    <Grid.Column width={10}><Grid>
        <Grid.Column width={10} textAlign="left"> 
        
        <AddressSection selectedClient={this.state.selectedClient} update={(value, key) => this.update(value, key)}/>
        </Grid.Column>
        <Grid.Column width={6} textAlign="left"> 
        <Divider horizontal>Phones</Divider>
        <PhoneNumberSection 
                    phones={this.state.selectedClient.client_phones} 
                    phoneKey='client_phones'
                    update={(value, key) => this.update(value,key)}
                    smsCallerId={this.state.selectedClient.used_sms_caller_id}
                    />

        </Grid.Column>
    </Grid>
    <Tab panes={
        [   { menuItem: 'Appointments', 
            render: () => appointmentTab(this.state.selectedClient.dates) },
            { menuItem: 'Communication History', 
            render: () => transactionTab(this.state.selectedClient.transactions) },
            { menuItem: 'Notes', render: () => (<Tab.Pane>    
                <TextFormField 
                    valueKey='notes'
                    control={TextArea} 
                    label='Notes' 
                    object={this.state.selectedClient}
                    update={(value, key) => this.update(value, key)}
                />
                </Tab.Pane>) }
        ]
    } />
    </Grid.Column>
    </Grid>
  </Form>
                    </Modal.Description>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button color='black' onClick={() => this.props.close()}>
                      Cancel
                    </Button>
                    {
                    this.state.selectedClient.uuid&&(
                            <Button content="Delete" color='red' icon="trash" labelPosition='right' onClick={() => {if(window.confirm('Do you really want to delete this Client?'))this.props.onDeleteClient(this.state.selectedClient)}} />
                        )
                    } 
                    <Button positive icon='checkmark' labelPosition='right' content="Save" onClick={() => this.saveChanges()} />
                  </Modal.Actions>
                </Modal>
        )
    }
}


const appointmentTab = (dates= []) => {
    if (dates.length===0) {
            return  <Tab.Pane><Message color="blue">
                        <Message.Header>No appointments</Message.Header>
                        Once you start making appointments with this client they will show up here.
                    </Message></Tab.Pane>
    }
    let dateOrdering = (a, b) => moment(b.start_timestamp).valueOf() - moment(a.start_timestamp).valueOf();
    return <Tab.Pane><DataTable 
    maxPageCount={5}
                id="appointments"
                columnDescription={[
                    {
                        key:'start_timestamp',
                        header:'Date',
                        format:(value) => moment(value).format('lll'),
                        sorting:dateOrdering
                    },
                    {
                        key:'title',
                        header:'Title',
                        sorting:(a, b) => {
                            return a.title.toLowerCase().localeCompare(b.title.toLowerCase())
                        }
                    },
                    {
                        key:'status',
                        header:'Status',
                        format:(status) => <ConfirmationStatusIcon status={status}/>,
                        sorting:(a, b) => {
                            if(a.status === b.status) {
                                return 0;
                            }
                            if(a.status === ConfirmationStatus.CONFIRMED
                                || (a.status === ConfirmationStatus.PENDING
                                && b.status === ConfirmationStatus.REJECTED)) {
                                return 1;
                            }
                            return -1;
                        }
                    }
                ]}
                initialSorting= {{
                    sorting: dateOrdering,
                    asc:true
                }}
                dataRows={dates}
                pageSize={3}
            /></Tab.Pane>
}

const transactionTab = (transactions=[]) => {

    if (transactions.length === 0) {
        return <Tab.Pane><Message color="blue"><Message.Header>No previous transactions</Message.Header>
        Once you start sending communication to this client they will show up here.
        </Message></Tab.Pane>
    }
    let dateOrdering = (a, b) => moment(b.timestamp).valueOf() - moment(a.timestamp).valueOf();
    return <Tab.Pane><DataTable
            maxPageCount={5}
            id="transactions"
            dataRows={transactions}
            columnDescription={[
                { 
                    header : 'Date',
                    key: 'timestamp',
                    sorting: dateOrdering,
                    format:(value) => moment(value).format('lll'),
                },
                { 
                    header : 'Type',
                    key: 'communication_type',
                    format: (type) => type&&type!=='SMS'?_.capitalize(type):type,
                    sorting: (a,b) => a.communication_type?a.communication_type.localeCompare(b.communication_type):-1
                },
                { 
                    header : 'Status',
                    key: 'delivery_status',
                    format: (status) => status?_.capitalize(status):null,
                    sorting: (a,b) => a.delivery_status?a.delivery_status.localeCompare(b.communication_type):-1
                },
                { 
                    header : 'Error',
                    key: 'delivery_error',
                    sorting: (a,b) => a.delivery_error?a.delivery_error.localeCompare(b.communication_type):-1
                },

            ]}
            initialSorting={{
                sorting: dateOrdering,
                asc:false
            }}
            pageSize={3}
            /></Tab.Pane>
}



const PersonalDataSection = (props) => {
    //let birthday = props.selectedClient.birthday?moment(props.selectedClient.birthday):moment().subtract(20,'years');

    function setBirthday(newBirthday) {
        let birthday = newBirthday;
        if(birthday) {
            birthday = `${birthday.getFullYear()}-${_.padStart(birthday.getMonth()+1,2,'0')}-${_.padStart(birthday.getDate(),2,'0')}`;
        }    
        props.update(birthday, 'birthday')
    }

    return (
        <div>
        <Form.Group widths='equal'>    
      
       <Form.Field>
       <label>Type</label>
        <Dropdown placeholder='Type'  
        inline
        selection 
        options={clientTypes} 
        value={props.selectedClient.client_type}
        onChange={(event,{value}) => props.update(value, 'client_type')}
        />
        </Form.Field>           
        </Form.Group>
    <Form.Group widths='equal'>
        <TextFormField 
            valueKey='company_name'
            control={Input} 
            label='Company' 
            object={props.selectedClient}
            update={(value, key) => props.update(value, key)}            
        />
    </Form.Group>
    <Form.Group widths='equal'>
        <TextFormField 
            valueKey='given_name'
            control={Input} 
            label='First Name' 
            object={props.selectedClient}
            update={(value, key) => props.update(value, key)}            
        />
    </Form.Group>
    {/* <Form.Group widths='equal'>
      <TextFormField 
            valueKey='middle_name'
            control={Input} 
            label='Middle Name' 
            object={props.selectedClient}
            update={(value, key) => props.update(value, key)}
        />
    </Form.Group>  */}
    <Form.Group widths='equal'>
    <TextFormField 
            valueKey='family_name'
            control={Input} 
            label='Last Name' 
            object={props.selectedClient}
            update={(value, key) => props.update(value, key)}
        />
    </Form.Group>
    <Form.Group widths='equal'>
    <Form.Field>
    <label>Birthday</label>
    <ClickableDateTimePicker
    value={props.selectedClient.birthday?moment(props.selectedClient.birthday.substr(0, 10), moment.ISO_8601).toDate():null}
    onChange={(newBirthday) => setBirthday(newBirthday)}
    time={false}
    initialView='decade'
    />
    </Form.Field>
    </Form.Group>
    <Form.Group widths='equal'>    
    <Form.Field>
    <label>Gender</label>
    <Dropdown placeholder='Type'  
    inline
    selection 
    options={[{text:'Female',value:'f'},{text:'Male',value:'m'}]} 
    value={props.selectedClient.gender}
    onChange={(event,{value}) => {props.update(value, 'gender')}}
    />
    </Form.Field>           
    </Form.Group>
    </div>
    )

}

const AddressSection = (props) => {
    let selectedClient = props.selectedClient;
    return (
        <div><Divider horizontal>Address</Divider>
        <Form.Group widths='equal'>
        <TextFormField 
            placeholder='Street Address, P.O Box' 
            valueKey='street_address'
            control={Input} 
            label='Address Line 1' 
            object={selectedClient}
            update={(value, key) => props.update(value, key)}            
        />
        </Form.Group>
            <Form.Group widths='equal'>
        <TextFormField 
            placeholder='Unit, suite, building, floor etc'
            valueKey='extended_address'
            control={Input} 
            label='Address Line 1' 
            object={selectedClient}
            update={(value, key) => props.update(value, key)}            
        />
        </Form.Group>
        <Form.Group widths='equal'>
        <TextFormField 
            valueKey='city'
            control={Input} 
            label='City' 
            object={selectedClient}
            update={(value, key) => props.update(value, key)}            
        />
        </Form.Group>
        <Form.Group>
        <Form.Field>
        <label>State</label>
        <Dropdown 
            options={[{text:'N/A', value:'N/A'}].concat(US_STATE_LIST)}
            scrolling
            search
            value={selectedClient.state || 'N/A'}
            onChange={(event,{value}) => {value==='N/A'?props.update(null, 'state'):props.update(value, 'state')}}
            selection
            />
        </Form.Field>
       <TextFormField 
            valueKey='postal_code'
            control={Input} 
            label='Zip' 
            object={selectedClient}
            update={(value, key) => props.update(value, key)}   
            width={6}         
        />
        
        
        </Form.Group></div>
    )
}


const clientTypes = [
                    {text:'Person',value:'Person',key:'Person'},
                    {text:'Business',value:'Business',key:'Business'}
                    ];


