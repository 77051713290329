
import React from 'react'
import { DataTable } from './DataTable';
import { ConfirmationStatusIcon } from './ConfirmationStatusIcon';
import { ConfirmationStatus } from 'constants/ConfirmationStatus';
import moment from 'moment';

const DateTable = (props) => {
    let dateOrdering = (a, b) => moment(b.start_timestamp).valueOf() - moment(a.start_timestamp).valueOf();
    return <DataTable 
                id="appointments"
                columnDescription={[
                    {
                        key:'start_timestamp',
                        header:'Date',
                        format:(value) => moment(value).format('lll'),
                        sorting:dateOrdering
                    },
                    {
                        key:'title',
                        header:'Title',
                        sorting:(a, b) => {
                            return a.title.toLowerCase().localeCompare(b.title.toLowerCase())
                        }
                    },
                    {
                        key:'client_names',
                        header:'Client(s)',
                        sorting:(a, b) => {
                            return a.client_names.toLowerCase().localeCompare(b.client_names.toLowerCase())
                        }
                    },
                    {
                        key:'status',
                        header:'Status',
                        format:(status) => <ConfirmationStatusIcon status={status}/>,
                        sorting:(a, b) => {
                            if(a.status === b.status) {
                                return 0;
                            }
                            if(a.status === ConfirmationStatus.CONFIRMED
                                || (a.status === ConfirmationStatus.PENDING
                                && b.status === ConfirmationStatus.REJECTED)) {
                                return 1;
                            }
                            return -1;
                        }
                    }
                ]}
                initialSorting= {{
                    sorting: dateOrdering,
                    asc:true
                }}
                dataRows={props.dates}
                pageSize={props.pageSize||5}
            />
}

export default DateTable