// @flow
import {Observable} from 'rxjs'
import { FETCH_SETTINGS, fetchSettingsFullfilled, saveSettingsFullfilled, IMPORT_CLIENTS, uploadClientsFullfilled, RELOAD_SYNC_SETTINGS, reloadSyncSettingsFullfilled } from 'actions/SettingsActions';
import { requestForEndpointAndPayload, BASE_URL } from 'App';
import { SAVE_SETTINGS } from 'actions/SettingsActions';
import { handleAjaxError } from './sessionEpic';
import { TOGGLE_VISIBILITY_OF_MICROSOFT_CALENDAR, reloadSyncSettings, TOGGLE_VISIBILITY_OF_GOOGLE_CALENDAR } from '../actions/SettingsActions';

export function fetchSettingsEpic(action$, store) {

    return action$.ofType(FETCH_SETTINGS)
    .switchMap(({payload}) => {
        let {session} = store.getState();
        return Observable.ajax(requestForEndpointAndPayload('get',{
            office_ids : session.offices,
            professional_ids : [session.professional],
            company_ids : [session.company],
            user_ids : [session.user_id],
            communication_message_template_ids : '*',
            communication_type_ids : '*',
            reminder_offset_ids : '*',
            occasion_ids : '*',
            office_properties : ['name',
                                'city',
                                'phone_number',
                                'area_code',
                                'state',
                                'extension',
                                'street_address',
                                'extended_address',
                                'postal_code',
                                'timezone_string',
                                'website',
                                'email',
                                'uuid',
                                'review_link_url',
                                'booking_api_keys'],
            company_properties : ['name',
                                'uuid',
                                'email_confirmation_notification',
                                'allow_marketing_emails',
                                'cpas_site_id'
                            ],
            professional_properties : ['family_name',
                                'given_name',
                                'office',
                                'html_signature',
                                'plain_text_signature',
                                'email',
                                'uuid',
                                'professional_phones',
                                'template_personalizations',
                                'sms_signature',
                                'online_booking_date_length',
                                'online_booking_end_time',
                                'online_booking_start_time',
                                'online_booking_weekdays',
                                'communication_preferences',
                                //'google_sync_settings'
                            ],
            user_properties : ['user_name',
                                'uuid']            
        })
            )
        .switchMap((result) => appendProfessionalPhonesObservable(result.response))
        .switchMap(response => appendCompanyCommunicationPreferences(response))
        .switchMap(response => appendTemplatePersonalizations(response))
        .switchMap(response => appendImports(response))
        .switchMap(response => appendSyncSettings(response))
        .switchMap(response => appendBookingAPIKeyObservable(response))
       // .switchMap(response => appendMicrosoftSyncSettings(response))
        .map(response => {
            return fetchSettingsFullfilled(response);
        }).catch((e) => {
            console.log("error from server", e)
            return Observable.concat([handleAjaxError(e),fetchSettingsFullfilled({})]);
        })
    });
}

const appendProfessionalPhonesObservable = response => {
    return Observable.ajax(
        requestForEndpointAndPayload('get',{
                            professional_phone_ids: response.professionals[0].professional_phones 
                            })

            ).flatMap(phone_result => {
                let professional = response.professionals[0];
                professional.professional_phones = phone_result.response.professional_phones;
    return Observable.of({...response, professionals:[professional]});
})}

const appendBookingAPIKeyObservable = response => {
    return Observable.ajax(
        requestForEndpointAndPayload('get',{
                            booking_api_key_ids: response.offices[0].booking_api_keys 
                            })

            ).flatMap(booking_api_key_result => {
                let office_booking_api_key = booking_api_key_result.response.booking_api_keys[0];
    return Observable.of({...response, office_booking_api_key});
})}

export const appendCompanyCommunicationPreferences = response => {
    return Observable.ajax(
        requestForEndpointAndPayload('get',{
                    communication_preference_ids: response.professionals[0].communication_preferences 
                            })

            ).flatMap(preference_result => {
                let professional = response.professionals[0];
                professional.communication_preferences = preference_result.response.communication_preferences;
    return Observable.of({...response, professionals:[professional]});
})
}

export const appendTemplatePersonalizations = response => {
    return Observable.ajax(
        requestForEndpointAndPayload('get',{
            template_personalization_ids: response.professionals[0].template_personalizations 
                            })

            ).flatMap(templateResult => {
                let professional = {...response.professionals[0], template_personalizations:templateResult.response.template_personalizations}
    return Observable.of({...response, professionals:[professional]});
})
}

export const appendSyncSettings = response => {
    return Observable.ajax(requestForEndpointAndPayload('query',{
        google_sync_settings:  [
                    {
                        and : [
                            ["professional","=",response.professionals[0].uuid]
                        ]
                    }
                ],
        google_calendar_syncs:  [
                        {
                            and : [
                                ["professional","=",response.professionals[0].uuid]
                            ]
                        }
                ],
                microsoft_sync_settings:  [
                    {
                        and : [
                            ["professional","=",response.professionals[0].uuid]
                        ]
                    }
                ],
                zoom_settings:  [
                    {
                        and : [
                            ["professional","=",response.professionals[0].uuid]
                        ]
                    }
                ],
        microsoft_calendar_syncs:  [
                        {
                            and : [
                                ["professional","=",response.professionals[0].uuid]
                            ]
                        }]
                    })).flatMap(sync_settingsResult => {
    return Observable.of({...response, 
        google_sync_settings:sync_settingsResult.response.google_sync_settings,
        google_calendar_syncs:sync_settingsResult.response.google_calendar_syncs,
        microsoft_sync_settings:sync_settingsResult.response.microsoft_sync_settings,
        microsoft_calendar_syncs:sync_settingsResult.response.microsoft_calendar_syncs,
        zoom_settings:sync_settingsResult.response.zoom_settings
    });
})
}



export const appendImports = response => {
    return Observable.ajax(requestForEndpointAndPayload('query',{
        data_imports:  [
                    {
                        and : [
                            ["professional","=",response.professionals[0].uuid]
                        ]
                    }
                ]})).flatMap(importsResult => {
    return Observable.of({...response, data_imports:importsResult.response.data_imports});
})
}


export function saveSeetingsEpic(action$, store) {

    return action$.ofType(SAVE_SETTINGS)
    .switchMap(({payload}) => {
        let professional = payload.professional;
        professional.data_imports = undefined;
        return Observable.ajax(requestForEndpointAndPayload('put',{
            offices : [payload.office],
            professionals : [professional],
            companies : [payload.company],
            users : [payload.user]          
        })
            )
        .switchMap((result) => appendProfessionalPhonesObservable(result.response))
        .switchMap(response => appendCompanyCommunicationPreferences(response))
                .switchMap(response => appendTemplatePersonalizations(response))
                
        .map(result => {
            return saveSettingsFullfilled(result);
        }).catch((e) => {
            console.log("error from server", e)
            return Observable.concat([handleAjaxError(e),saveSettingsFullfilled({})]);
        })
    });
}

export function toggleVisibilityOfMicrosoftCalendarEpic(action$) {

    return action$.ofType(TOGGLE_VISIBILITY_OF_MICROSOFT_CALENDAR)
    .switchMap(({payload}) => {
        return Observable.ajax(requestForEndpointAndPayload('put',{
            microsoft_calendar_syncs:[payload.calendar]      
        })
            )
            .map(result => {
            return reloadSyncSettings();
        }).catch((e) => {
            console.log("error from server", e)
            return handleAjaxError(e);
        })
    });
}

export function toggleVisibilityOfGoogleCalendarEpic(action$) {

    return action$.ofType(TOGGLE_VISIBILITY_OF_GOOGLE_CALENDAR)
    .switchMap(({payload}) => {
        return Observable.ajax(requestForEndpointAndPayload('put',{
            google_calendar_syncs:[payload.calendar]      
        })
            )
            .map(result => {
            return reloadSyncSettings();
        }).catch((e) => {
            console.log("error from server", e)
            return handleAjaxError(e);
        })
    });
}

export function uploadDataEpic(action$, store) {



    return action$.ofType(IMPORT_CLIENTS)
    .switchMap(({payload}) => {
        return Observable.ajax(
            {
                url: BASE_URL+`/ep/${store.getState().session.scope}/import`,
                method:'POST',
                responseType:'json',
                body:`at=${encodeURIComponent(store.getState().session.at)}&professional=${encodeURIComponent(payload.professional)}&file=${encodeURIComponent(payload.csvDataURL)}`
            }

        )
        .switchMap(() => Observable.ajax(requestForEndpointAndPayload('query',{
            data_imports:  [
                        {
                            and : [
                                ["professional","=",store.getState().session.professional]
                            ]
                        }
                    ]})))
        .map(({response}) => uploadClientsFullfilled(response.data_imports)).catch((e) => {
            console.log("error from server", e)
            return Observable.concat([handleAjaxError(e),uploadClientsFullfilled([])]);
        })
    });
}

export function reloadSyncSettingsEpic(action$, store) {
    return action$.ofType(RELOAD_SYNC_SETTINGS)
   // .switchMap(() => appendGoogleSyncSettings({professionals:store.getState().settings.professionals})
    .switchMap(() => appendSyncSettings({professionals:store.getState().settings.professionals}))
    .map(payload => {
        return reloadSyncSettingsFullfilled(payload)
    })
    .catch(e => handleAjaxError(e));
}