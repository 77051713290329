// @flow

import React from 'react'
import { Table, Menu, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';


class DataTable extends React.Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            index:0,
            asc:true,
            ordering:null,
            id:props.id
        }
        if(props.initialSorting) {
            this.state.ordering = props.initialSorting.sorting;
            this.state.asc = props.initialSorting.asc;
            this.state.key = props.initialSorting.key;
        }

    }

    setOrdering(ordering) {
        
        if(this.state.ordering===ordering) {
            this.setState({...this.state, asc:!this.state.asc})   
        } else {
            this.setState({...this.state, ordering})
        }
    }

    componentWillReceiveProps() {
        let newState = {
            index:0,
            asc:true,
            ordering:null,
        }
        if(this.props.initialSorting) {
            newState.ordering = this.props.initialSorting.sorting;
            newState.asc = this.props.initialSorting.asc;
        }
        this.setState(newState);
    }

    render() {
        let dataRows = this.props.dataRows;

        if(this.state.ordering) {
            let ascIndex = this.state.asc?1:-1;
            dataRows = this.props.dataRows.sort((a,b)=>ascIndex * this.state.ordering(a,b,this.state.key));
        }
        let pageRows = dataRows;
        if(this.props.pageSize) {
            pageRows = dataRows.slice(
                this.state.index*this.props.pageSize, 
                (this.state.index+1)*this.props.pageSize)
            
        }
        let rows = pageRows.map((row, index) => (
            <Table.Row key={row.uuid||index} onClick={this.props.onSelectRow?() => this.props.onSelectRow(row):null} style={this.props.onSelectRow?{cursor:'pointer'}:null}>
                {
                    this.props.columnDescription.map(header => {
                        let child = row[header.key];
                        if(header.format){
                            child = header.format(child, row, header);
                        }
                    return <Table.Cell key={`${row.uuid}_${header.key}`}>{child}</Table.Cell>
                    })
                }
          </Table.Row>
        
        ))

        return (
            <Table celled striped>
        <Table.Header>
          <Table.Row>
          {
                    this.props.columnDescription.map(header => <Table.HeaderCell key={header.header} onClick={() => this.setOrdering(header.sorting)}>
                        <Icon 
                            name={this.state.asc?'sort content ascending':'sort content descending'} 
                            circular 
                            inverted={this.state.ordering===header.sorting} 
                            color='blue' 
                            bordered
                            />
                        {' '}
                        {header.header}
                        </Table.HeaderCell>)
          }

          </Table.Row>
        </Table.Header>
    
        <Table.Body>
        {
            rows
        }
        </Table.Body>
        {this.pager()}
      </Table>
        )
    }

    pager() {
        let pageSize = this.props.pageSize
        let maxPageCount = this.props.maxPageCount
        if(!pageSize || (this.props.dataRows.length/pageSize)<1) {
            return null;
        }
        let count = Math.ceil(this.props.dataRows.length/pageSize);
        let indexes = [];
        let displayedPageCount = count>maxPageCount?maxPageCount:count;
        let startIndex = this.state.index>=displayedPageCount?displayedPageCount*Math.floor(this.state.index/displayedPageCount):0;
        if (this.state.index+1 > maxPageCount && displayedPageCount > 1) {
            indexes.push( <Menu.Item key='_start' as='span' disabled={true}>...</Menu.Item>);
        }
        for (let i=startIndex;i<startIndex+displayedPageCount && i<count;i++){
            indexes.push( <Menu.Item key={i} as='a' active={this.state.index===i} onClick={() => this.setState({...this.state,index:i})}>{i+1}</Menu.Item>);
        }
        if (this.state.index<count-1) {
            indexes.push( <Menu.Item key='_end' as='span'  disabled={true}>...</Menu.Item>);
        }
        return (
            <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={this.props.columnDescription.length}>
              <Menu floated='right' pagination>
                <Menu.Item as='a' icon disabled={this.state.index<=0} onClick={() => this.setState({...this.state, index:this.state.index-1})}>
                  <Icon name='chevron left' />
                </Menu.Item>
                {indexes}
                <Menu.Item as='a' icon  disabled={this.state.index>=count-1} onClick={() => this.setState({...this.state, index:this.state.index+1})}>
                  <Icon name='chevron right' />
                </Menu.Item>
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
        )
    }


}

DataTable.protoTypes = {
    initialSorting : PropTypes.object,
    columnDescription: PropTypes.array.isRequired,
    dataRows:PropTypes.array.isRequired,
    pageSize:PropTypes.number

}

export {DataTable}

