import {Observable} from 'rxjs'
import { FETCH_APPOINTMENTS, SAVE_APPOINTMENT, DELETE_APPOINTMENT, saveAppointmentFullfilled, fetchAppointmentsFullfilled, selectAppointmentFullfilled, SELECT_APPOINTMENT, deleteAppointmentFullfilled, FETCH_SELECT_APPOINTMENT, setCommunicationPreferences, FETCH_APPOINTMENT_REQUESTS, fetchAppointmentRequestsFullfilled, APPEND_APPOINTMENT_TO_APPT_REQUEST, appendAppointmentToApptRequestFullfilled, SAVE_APPOINTMENT_REQUEST, saveAppointmentRequestFullfilled, fetchAppointments, fetchAppointmentRequests, REJECT_APPOINTMENT_REQUEST, rejectAppointmentRequestFullfilled, FETCH_GOOGLE_EVENTS, fetchGoogleEventsFullfilled } from 'actions/AppointmentActions';
import { requestForEndpointAndPayload } from 'App';
import { convertToJSONString, timespanForDateAndView } from 'utils/calendarUtils';
import { setCommunicationMessageTemplates, setReminderOffsets, setCommunicationTypes, setOccasions } from 'actions/MetaDataActions';
import { appendCompanyCommunicationPreferences } from './settingsEpic';
import { handleAjaxError } from './sessionEpic';
import { fetchClients } from 'actions/ClientActions';
import { rrulestr } from 'rrule'
import { dateObject, transferTimezone } from '../utils/calendarUtils';
import moment  from 'moment';
import { FETCH_MICROSOFT_EVENTS, fetchMicrosoftEventsFullfilled, FETCH_HOLIDAY_EVENTS, fetchHolidayEventsFullfilled, FETCH_BIRTHDAYS, fetchBirthdaysFullfilled } from '../actions/AppointmentActions';
import { completeName } from '../utils/clientUtils';
import { GOOGLE_EVENT } from 'utils/appointmentUtils';
import { MICROSOFT_EVENT, ZOOM_MEETING_EVENT } from '../utils/appointmentUtils';
import { setDateLocations } from '../actions/MetaDataActions';



export function fetchAppointmentsEpic(action$) {
    return action$.ofType(FETCH_APPOINTMENTS)
    .switchMap(({payload: {professional, date, view, tags = []}}) => {
        let {start,end} = timespanForDateAndView(date, view)
        return Observable.ajax(requestForEndpointAndPayload('query',{
            dates:  [
                        {
                            and : [
                                ["professionals","=",professional],
                                { or : [
                                        { 
                                            and :   [
                                                        ["start_timestamp",">=",convertToJSONString(start)],
                                                        ["start_timestamp","<=",convertToJSONString(end)]
                                                    ]
                                        },
                                        { 
                                            and :   [
                                                        ["end_timestamp",">=",convertToJSONString(start)],
                                                        ["end_timestamp","<=",convertToJSONString(end)]
                                                    ]
                                        },
                                        { 
                                            and :   [
                                                        ["start_timestamp","<=",convertToJSONString(start)],
                                                        ["end_timestamp",">=",convertToJSONString(end)]
                                                    ]
                                        }
                                    ]
                                },
                                {
                                    or: tags.map(tag => ["tags", "=", tag])
                                }    
                            ]
                        }
                    ],
                  /*  zoom_event_meetings:  [
                        {
                            and : [
                                ["professional","=",professional],
                                { or : [
                                        { 
                                            and :   [
                                                        ["start_timestamp",">=",convertToJSONString(start)],
                                                        ["start_timestamp","<=",convertToJSONString(end)]
                                                    ]
                                        },
                                        { 
                                            and :   [
                                                        ["end_timestamp",">=",convertToJSONString(start)],
                                                        ["end_timestamp","<=",convertToJSONString(end)]
                                                    ]
                                        },
                                        { 
                                            and :   [
                                                        ["start_timestamp","<=",convertToJSONString(start)],
                                                        ["end_timestamp",">=",convertToJSONString(end)]
                                                    ]
                                        }
                                    ]
                                }  
                            ]
                        }
                    ], */
                    zoom_settings:  [
                        {
                            and : [
                                ["professional","=",professional]
                            ]
                        }
                    ],
                    microsoft_sync_settings:  [
                        {
                            and : [
                                ["professional","=",professional]
                            ]
                        }
                    ],
                    google_sync_settings:  [
                        {
                            and : [
                                ["professional","=",professional]
                            ]
                        }
                    ]
            //client_properties:['given_name','family_name','tags','uuid', 'email']
        })
            )
        .map(result => {
            return fetchAppointmentsFullfilled(result.response.dates);
        }).catch((e) => {
            console.log("error from server", e)
            return Observable.concat([fetchAppointmentsFullfilled([]), handleAjaxError(e)])
            //return Observable.of(setLoginErrorMessage('Could not find user with username and/or password!'))
        })
    });
}

export function fetchAppointmentRequestsEpic(action$) {
    return action$.ofType(FETCH_APPOINTMENT_REQUESTS)
    .switchMap(({payload}) => {
        let {start,end} = timespanForDateAndView(payload.date, payload.view)
        return Observable.ajax(requestForEndpointAndPayload('query',{
            date_requests:  [
                        {
                            and : [
                                ["professional","=",payload.professional],
                                ["status","=","ACTIVE"],
                                { or : [
                                        { 
                                            and :   [
                                                        ["start_timestamp",">=",convertToJSONString(start)],
                                                        ["start_timestamp","<=",convertToJSONString(end)]
                                                    ]
                                        },
                                        { 
                                            and :   [
                                                        ["end_timestamp",">=",convertToJSONString(start)],
                                                        ["end_timestamp","<=",convertToJSONString(end)]
                                                    ]
                                        },
                                        { 
                                            and :   [
                                                        ["start_timestamp","<=",convertToJSONString(start)],
                                                        ["end_timestamp",">=",convertToJSONString(end)]
                                                    ]
                                        }
                                    ]
                                }    
                            ]
                        }
                    ],
            //client_properties:['given_name','family_name','tags','uuid', 'email']
        })
            )
        .map(result => {
            return fetchAppointmentRequestsFullfilled({
                dateRequests:result.response.date_requests, 
                selectedAppointmentRequest:payload.selectedAppointmentRequest});
        }).catch((e) => {
            console.log("error from server", e)
            return Observable.concat([fetchAppointmentRequestsFullfilled({
                dateRequests:[]}), handleAjaxError(e)])
            //return Observable.of(setLoginErrorMessage('Could not find user with username and/or password!'))
        })
    });
}

export function fetchHolidayEventsEpic(action$) {
    return action$.ofType(FETCH_HOLIDAY_EVENTS)
    .switchMap(({payload}) => {
        let {start,end} = timespanForDateAndView(payload.date, payload.view)
        return Observable.ajax(requestForEndpointAndPayload('query',{
            holiday_events:  [
                        {
                             or : [
                                        { 
                                            and :   [
                                                        ["start_timestamp",">=",convertToJSONString(start)],
                                                        ["start_timestamp","<=",convertToJSONString(end)]
                                                    ]
                                        },
                                        { 
                                            and :   [
                                                        ["end_timestamp",">=",convertToJSONString(start)],
                                                        ["end_timestamp","<=",convertToJSONString(end)]
                                                    ]
                                        },
                                        { 
                                            and :   [
                                                        ["start_timestamp","<=",convertToJSONString(start)],
                                                        ["end_timestamp",">=",convertToJSONString(end)]
                                                    ]
                                        }
                            ]
                        } 
                    ]
            //client_properties:['given_name','family_name','tags','uuid', 'email']
        })
            )
        .map(result => {
            return fetchHolidayEventsFullfilled({
                holidayEvents:result.response.holiday_events.map(holiday => {return {...holiday,all_day:true, name:"🎀 "+holiday.name}})});
        }).catch((e) => {
            console.log("error from server", e)
            return Observable.concat([fetchHolidayEventsFullfilled({
                holidayEvents:[]}), handleAjaxError(e)])
            //return Observable.of(setLoginErrorMessage('Could not find user with username and/or password!'))
        })
    });
}


export function fetchBirthdaysEpic(action$) {
    return action$.ofType(FETCH_BIRTHDAYS)
    .switchMap(({payload}) => {
        let {start,end} = timespanForDateAndView(payload.date, payload.view)
        let and = [];
        and.push(["professional","=",payload.professional]);
        if(start.getFullYear()===end.getFullYear()) {
            and.push(["birthday_DOY",">=",moment(start).dayOfYear()]);
            and.push(["birthday_DOY","<=",moment(end).dayOfYear()]);    
        } else {
            and.push({or:[["birthday_DOY",">=",moment(start).dayOfYear()],["birthday_DOY","<=",moment(end).dayOfYear()]]})
        }

        return Observable.ajax(requestForEndpointAndPayload('query',{
            clients:  [
                        {
                             and
                                       
                        } 
                    ],
            client_properties:['given_name','family_name','birthday','uuid']
        })
            )
        .map(result => {
            let birthdays = [];
            result.response.clients.forEach(client => {
                let birthdayMoment = moment(client.birthday.replace("Z",""), moment.HTML5_FMT.DATETIME_LOCAL_SECONDS).year(start.getFullYear());
                birthdays.push({uuid:client.uuid,birthdayEvent:birthdayMoment.toDate(),all_day:true, name:"🎂 "+completeName(client)});
                if(start.getFullYear()!==end.getFullYear()) {
                    birthdays.push({uuid:client.uuid,birthdayEvent:birthdayMoment.year(end.getFullYear()).toDate(),all_day:true, name:"🎂 "+completeName(client)}); 
                }
            })
            return fetchBirthdaysFullfilled({
                birthdays});
        }).catch((e) => {
            console.log("error from server", e)
            return Observable.concat([fetchBirthdaysFullfilled({
                birthdays:[]}), handleAjaxError(e)])
            //return Observable.of(setLoginErrorMessage('Could not find user with username and/or password!'))
        })
    });
}

export function fetchGoogleEventsEpic(action$) {
    return action$.ofType(FETCH_GOOGLE_EVENTS)
    .switchMap(({payload}) => {
        let {start,end} = timespanForDateAndView(payload.date, payload.view)
        return Observable.ajax(requestForEndpointAndPayload('query',{
            google_events:  [
                        {
                            and : [
                                ["recurring_event_id","=",null],
                                ["professional","=",payload.professional],
                                ["status","=","ACTIVE"],
                                {or : [["sync_status","=","DOES_SYNC_IS_VISIBLE"],["sync_status","=","DOES_NOT_SYNC_IS_VISIBLE"]]},
                                { or : [
                                        { 
                                            and :   [
                                                        ["start_timestamp",">=",convertToJSONString(start)],
                                                        ["start_timestamp","<=",convertToJSONString(end)],
                                                    ]
                                        },
                                        { 
                                            and :   [
                                                        ["end_timestamp",">=",convertToJSONString(start)],
                                                        ["end_timestamp","<=",convertToJSONString(end)]
                                                    ]
                                        },
                                        { 
                                            and :   [
                                                        ["start_timestamp","<=",convertToJSONString(start)],
                                                        ["end_timestamp",">=",convertToJSONString(end)]
                                                    ]
                                        },
                                        ["is_recurring","=",true]
                                    ]
                                }    
                            ]
                        }
                    ],
        })
            )
        .map(result => {
            const googleEvents = result.response.google_events;
            result.response.google_events
                .filter(gEvent => gEvent.is_recurring)
                .forEach(gEvent => {
                    const startDate = dateObject(moment(gEvent.start_timestamp, moment.ISO_8601));
                        //const endDate = dateObject(moment(gEvent.end_timestamp, moment.ISO_8601));
                        const ruleSet = rrulestr(`DTSTART;TZID=GMT:${moment(gEvent.start_timestamp, moment.ISO_8601).format('YYYYMMDD[T]HHmmss')};\n`+gEvent.recurrence_rules);
                        start.setTime(start.getTime()-(24*60*60*1000))
                        end.setTime(end.getTime()+(24*60*60*1000))
                        const newStartArray =ruleSet.between(start,end).map(date =>{
                            const newDate = transferTimezone(date,
                            startDate.getHours(),
                            startDate.getMinutes())  
                            return newDate;
                        }
                        )
                        /*
                        const options = RRule.parseString(gEvent.recurrence_rules);
                        
                        
                       
                        options.dtstart = endDate;
                        const rrule = new RRule(options);
                        const newStartArray = rrule
                            .between(start,end).map(date =>{
                                    const newDate = transferTimezone(date,
                                    startDate.getHours(),
                                    startDate.getMinutes())  
                                    return newDate;
                                }
                                )
                        

*/
                            newStartArray.forEach((newStart,index) => {
                                
                                    
                                    const endDate = dateObject(moment(gEvent.end_timestamp, moment.ISO_8601));
                                    if(newStart.getTime()>=endDate.getTime()) {
                                        const recurringCopy = {...gEvent};
                                        const distance = gEvent.all_day?1000:endDate.getTime()-startDate.getTime();
                                        recurringCopy.uuid = `${gEvent.uuid}#${index}`
                                        recurringCopy.start_timestamp = convertToJSONString(newStart.getTime());
                                        recurringCopy.end_timestamp = convertToJSONString(new Date(newStart.getTime()+distance));

                                        googleEvents.push(recurringCopy);
                                    }
                                }
                            )
                            }       
                            
                )
            return fetchGoogleEventsFullfilled({
                googleEvents});
        }).catch((e) => {
            console.log("error from server", e)
            return Observable.concat([fetchGoogleEventsFullfilled({googleEvents:[]}), handleAjaxError(e)])
            //return Observable.of(setLoginErrorMessage('Could not find user with username and/or password!'))
        })
    });
}



export function fetchMicrosoftEventsEpic(action$) {
    return action$.ofType(FETCH_MICROSOFT_EVENTS)
    .switchMap(({payload}) => {
        let {start,end} = timespanForDateAndView(payload.date, payload.view)
        return Observable.ajax(requestForEndpointAndPayload('query',{
            microsoft_events:  [
                        {
                            and : [
                                ["recurring_event_id","=",null],
                                ["professional","=",payload.professional],
                                ["status","=","ACTIVE"],
                                {or : [["sync_status","=","DOES_SYNC_IS_VISIBLE"],["sync_status","=","DOES_NOT_SYNC_IS_VISIBLE"]]},
                                { or : [
                                        { 
                                            and :   [
                                                        ["start_timestamp",">=",convertToJSONString(start)],
                                                        ["start_timestamp","<=",convertToJSONString(end)],
                                                    ]
                                        },
                                        { 
                                            and :   [
                                                        ["end_timestamp",">=",convertToJSONString(start)],
                                                        ["end_timestamp","<=",convertToJSONString(end)]
                                                    ]
                                        },
                                        { 
                                            and :   [
                                                        ["start_timestamp","<=",convertToJSONString(start)],
                                                        ["end_timestamp",">=",convertToJSONString(end)]
                                                    ]
                                        },
                                        ["is_recurring","=",true]
                                    ]
                                }    
                            ]
                        }
                    ],
        })
            )
        .map(result => {
            const microsoftEvents = result.response.microsoft_events;
            start.setTime(start.getTime()-(24*60*60*1000))
            end.setTime(end.getTime()+(24*60*60*1000))
            result.response.microsoft_events
                .filter(mEvent => mEvent.is_recurring)
                .forEach(mEvent => {
                    mEvent.recurrence_rules = mEvent.recurrence_rules.replace('UNTIL=00010101',`UNTIL=${moment(end).format('YYYYMMDD')}`)
                    const startDate = dateObject(moment(mEvent.start_timestamp, moment.ISO_8601));
                        //const endDate = dateObject(moment(gEvent.end_timestamp, moment.ISO_8601));
                        const dtstartString = mEvent.recurrence_rules.toLowerCase().includes('dtstart')?'':`DTSTART:${moment(startDate).format('YYYYMMDD[T]HHmmss')};\n`;
                        const ruleSet = rrulestr(dtstartString+mEvent.recurrence_rules);

                        const newStartArray =ruleSet.between(start,end).map(date =>{
                            const newDate = transferTimezone(date,
                            startDate.getHours(),
                            startDate.getMinutes())  
                            return newDate;
                        }
                        )
                       
                            newStartArray.forEach((newStart,index) => {
                                
                                    
                                    const endDate = dateObject(moment(mEvent.end_timestamp, moment.ISO_8601));
                                    if(newStart.getTime()>=endDate.getTime()) {
                                        const recurringCopy = {...mEvent};
                                        const distance = endDate.getTime()-startDate.getTime();
                                        recurringCopy.uuid = `${mEvent.uuid}#${index}`
                                        recurringCopy.start_timestamp = convertToJSONString(newStart.getTime());
                                        recurringCopy.end_timestamp = convertToJSONString(new Date(newStart.getTime()+distance));

                                        microsoftEvents.push(recurringCopy);
                                    }
                                }
                            )
                            }       
                            
                )
            return fetchMicrosoftEventsFullfilled({
                microsoftEvents});
        }).catch((e) => {
            console.log("error from server", e)
            return Observable.concat([fetchMicrosoftEventsFullfilled({microsoftEvents:[]}), handleAjaxError(e)])
            //return Observable.of(setLoginErrorMessage('Could not find user with username and/or password!'))
        })
    });
}


export function fetchSelectAppointmentEpic(action$, store) {
    return action$.ofType(FETCH_SELECT_APPOINTMENT)
    .switchMap(({payload}) => {
        return Observable.ajax(requestForEndpointAndPayload('get',{
            date_ids:[payload],
            professional_ids:[store.getState().session.professional]
        }
    )).switchMap(result => appendCompanyCommunicationPreferences(result.response))
    .switchMap(result => appendGoogleEvents(result.response))
    .switchMap(result => appendMicrosoftEvents(result.response))
    .flatMap(response => {
        return Observable.concat(
        Observable.of(setCommunicationPreferences(response.professionals[0].communication_preferences)),
        Observable.of(selectAppointmentFullfilled(response.dates[0])))
    })

    }).catch(e => {
        console.log(e);
        return Observable.concat([selectAppointmentFullfilled(null), handleAjaxError(e)])
    })
}

export function selectAppointmentEpic(action$, store) {
    let selectedAppointment;
    return action$.ofType(SELECT_APPOINTMENT)
    .switchMap(({payload}) => {
        selectedAppointment = payload
        return Observable.ajax(requestForEndpointAndPayload('get',{
            appt_reminder_schedule_ids:  selectedAppointment.appt_reminders,
            communication_message_template_ids:"*",
            communication_type_ids:"*",
            reminder_offset_ids:"*",
            occasion_ids:"*",
            date_location_ids:"*",
            company_ids:[selectedAppointment.company_id],
            professional_ids:[selectedAppointment.owning_professional]
        })
            )
            .switchMap(result => appendGoogleEvents({...result.response, dates:[selectedAppointment]}))
            .switchMap(response => appendMicrosoftEvents({...response}))
            .switchMap(response => appendZoomMeetingEvents({...response}))
        .switchMap(response => {return appendCompanyCommunicationPreferences(response)})
        .flatMap(response => {
            return Observable.concat(
                Observable.of(setCommunicationPreferences(response.professionals[0].communication_preferences)),
                Observable.of(setCommunicationMessageTemplates(response.communication_message_templates)),
                Observable.of(setReminderOffsets(response.reminder_offsets)),
                Observable.of(setCommunicationTypes(response.communication_types)),
                Observable.of(setOccasions(response.occasions)),
                Observable.of(setDateLocations(response.date_locations)),
                Observable.of(selectAppointmentFullfilled({...response.dates[0],appt_reminders:response.appt_reminder_schedules}))
              )             
        }).catch((e) => {
            console.log("error from server", e)
            return Observable.concat([selectAppointmentFullfilled(null), handleAjaxError(e)])
        })
    });
}

export function appendAppointmentToApptRequestEpic(action$, store) {

    return action$.ofType(APPEND_APPOINTMENT_TO_APPT_REQUEST)
    .switchMap(({payload}) => {
        const {selectedAppointmentRequest, selectedClients} = payload;
        return Observable.ajax(requestForEndpointAndPayload('get',{
            communication_message_template_ids:"*",
            communication_type_ids:"*",
            reminder_offset_ids:"*",
            occasion_ids:"*",
            date_location_ids:"*",
            company_ids:[selectedAppointmentRequest.company_id],
            professional_ids:[selectedAppointmentRequest.professional]
        })
            )
        .switchMap(result => appendCompanyCommunicationPreferences(result.response))
        .flatMap(response => {
            return Observable.concat(
                Observable.of(setCommunicationPreferences(response.professionals[0].communication_preferences)),
                Observable.of(setCommunicationMessageTemplates(response.communication_message_templates)),
                Observable.of(setReminderOffsets(response.reminder_offsets)),
                Observable.of(setCommunicationTypes(response.communication_types)),
                Observable.of(setOccasions(response.occasions)),
                Observable.of(setDateLocations(response.date_locations)),
                Observable.of(appendAppointmentToApptRequestFullfilled({...selectedAppointmentRequest,date:{
                    start_timestamp:selectedAppointmentRequest.start_timestamp,
                    end_timestamp:selectedAppointmentRequest.end_timestamp,
                    professionals:[selectedAppointmentRequest.professional],
                    owning_professional:selectedAppointmentRequest.professional,
                    appt_reminders:[],
                    clients:selectedClients,
                    notes:selectedAppointmentRequest.message,
                    confirmed_clients:[],
                    microsoft_events:[],
                    google_events:[],
                    notify_clients : true
                }}))
              )             
        }).catch((e) => {
            console.log("error from server", e)
            return Observable.concat([selectAppointmentFullfilled(null), handleAjaxError(e)])
        })
    });
}



export function rejectAppointmentRequestEpic(action$, store) {

    return action$.ofType(REJECT_APPOINTMENT_REQUEST)
    .switchMap(({payload}) => {
        return Observable.ajax(requestForEndpointAndPayload('put',{
            date_requests:[{...payload, status:'REJECTED'}]
        })
            )
        .map(result => rejectAppointmentRequestFullfilled(result.response.date_requests[0])).catch((e) => {
            console.log("error from server", e)
            return Observable.concat([saveAppointmentRequestFullfilled(null), handleAjaxError(e)])
        })
    });
}

export function saveAppointmentEpic(action$) {
    return action$.ofType(SAVE_APPOINTMENT)
    .switchMap(({payload}) => saveAppointmentObservable(payload))
}

export function saveAppointmentObservable(dateToSave) {
    
        return Observable.ajax(requestForEndpointAndPayload('put',{
            dates:  [dateToSave]
        }))
        .map(result => {
            return saveAppointmentFullfilled(result.response.dates[0]);
        }).catch((e) => {
            console.error("error from server", e)
            return Observable.concat([saveAppointmentFullfilled(null), handleAjaxError(e)])
        })
}

export function saveAppointmentRequestEpic(action$, store) {
    return action$.ofType(SAVE_APPOINTMENT_REQUEST)
    .switchMap(({payload}) => {
        return Observable.ajax(requestForEndpointAndPayload('put',{
            date_requests:  [payload]
        }))
        .flatMap(result => {
            const professional = store.getState().session.professional;
            const {view, date} = store.getState().appointments;
            return Observable.concat(
                Observable.of(fetchAppointments(date, view, professional)),
                Observable.of(fetchAppointmentRequests(date, view, professional)),
                Observable.of(fetchClients(professional)),
                Observable.of(saveAppointmentRequestFullfilled(result.response.date_requests[0]))
            );
        }).catch((e) => {
            console.log("error from server", e)
            return Observable.concat([saveAppointmentRequestFullfilled(null), handleAjaxError(e)])
           // return Observable.of(setSaveTagErrors(e.response.details))
        })
    })
}

export function deleteAppointmentEpic(action$) {
    return action$.ofType(DELETE_APPOINTMENT)
    .switchMap(({payload}) => {
        return Observable.ajax(requestForEndpointAndPayload('delete',{
            date_ids:  [payload.uuid]
        }))
        .map(result => {
            return deleteAppointmentFullfilled(result.response.date_ids[0]);
        }).catch((e) => {
            console.log("error from server", e)
            return Observable.concat([deleteAppointmentFullfilled(null), handleAjaxError(e)])
           // return Observable.of(setSaveTagErrors(e.response.details))
        })
    })
}

export const appendGoogleEvents = response => {
    return Observable.ajax(
        requestForEndpointAndPayload('get',{
            google_event_ids: response.dates.reduce((eventIds, date) => {
                if(date.google_events&&date.google_events.length>0) {
                    return eventIds.concat(date.google_events);
                }   
                    return eventIds;
            },[]) 
                            })

            ).flatMap(googleEventResponse => {
                const googleEvents = googleEventResponse.response.google_events;
                let dates = response.dates.map(date => {
                   return {...date, google_events : googleEvents.filter(googleEvent => date.google_events.includes(googleEvent.uuid)).map(gEvent => {return {...gEvent,type:GOOGLE_EVENT}})};
                })
return Observable.of({...response, dates});
})}

export const appendMicrosoftEvents = response => {
    return Observable.ajax(
        requestForEndpointAndPayload('get',{
            microsoft_event_ids: response.dates.reduce((eventIds, date) => {
                if(date.microsoft_events&&date.microsoft_events.length>0) {
                    return eventIds.concat(date.microsoft_events);
                }   
                    return eventIds;
            },[]) 
                            })

            ).flatMap(microsoftEventResponse => {
                const microsoft_events = microsoftEventResponse.response.microsoft_events;
                let dates = response.dates.map(date => {
                   return {...date, microsoft_events : microsoft_events.filter(microsoft_event => date.microsoft_events.includes(microsoft_event.uuid)).map(mEvent => {return {...mEvent,type:MICROSOFT_EVENT}})};
                })
return Observable.of({...response, dates});
})}

export const appendZoomMeetingEvents = response => {
    return Observable.ajax(
        requestForEndpointAndPayload('get',{
            zoom_meeting_event_ids: response.dates.reduce((eventIds, date) => {
                if(date.zoom_meeting_events&&date.zoom_meeting_events.length>0) {
                    return eventIds.concat(date.zoom_meeting_events);
                }   
                    return eventIds;
            },[]) 
                            })

            ).flatMap(zoomMeetingEventResponse => {
                const zoom_meeting_events = zoomMeetingEventResponse.response.zoom_meeting_events;
                let dates = response.dates.map(date => {
                   return {...date, zoom_meeting_events : zoom_meeting_events.filter(zoom_meeting_event => date.zoom_meeting_events.includes(zoom_meeting_event.uuid)).map(mEvent => {return {...mEvent,type:ZOOM_MEETING_EVENT}})};
                })
return Observable.of({...response, dates});
})}