import {Observable} from 'rxjs'
import { FETCH_CLIENTS, SAVE_CLIENT, DELETE_CLIENT, fetchClientsFullfilled, FETCH_SELECTED_CLIENT, fetchSelectedClientFullfilled, saveClientFullfilled, deleteClientFullfilled, SAVE_CLIENTS_APPOINTMENT, fetchClients } from 'actions/ClientActions';
import { requestForEndpointAndPayload } from 'App';
import { ConfirmationStatus } from 'constants/ConfirmationStatus';
import moment from 'moment'
import { handleAjaxError } from './sessionEpic';
import { saveAppointmentObservable } from './appointmentsEpic';

export function fetchClientsEpic(action$, store) {
    return action$.ofType(FETCH_CLIENTS)
    .switchMap(({payload: {professional, page=1, length=10, searchTerm = '', tags = []}}) => {
        return Observable.ajax(requestForEndpointAndPayload('query',{
            clients:  [
                        {
                            and : [
                                ["professional","=",professional],
                                {
                                    or: [
                                        ["given_name", "like", `*${searchTerm}*`],
                                        ["family_name", "like", `*${searchTerm}*`],
                                        ["middle_name", "like", `*${searchTerm}*`],
                                        ["email", "like", `*${searchTerm}*`],
                                        ["company_name", "like", `*${searchTerm}*`],
                                    ]
                                },
                                {
                                    or: tags.map(tag => ["tags", "=", tag])
                                }
                            ],
                            page,
                            page_size: length
                        }
                    ],
            client_properties:['given_name','family_name','company_name','tags','uuid', 'email', 'future_active_dates']
        }))
    })
    .switchMap((result) => {
        return appendDateProxyObservable(result.response)
    })
    .map(({clients, clients_pagination_info}) => {
        return fetchClientsFullfilled(clients, clients_pagination_info)
    })
    .catch((e) => {
    console.log("error from server", e)
        return Observable.concat([handleAjaxError(e), fetchClientsFullfilled([], {
            page: 0,
            page_size: 10,
            total_item_count: 0,
            total_page_counter: 0
        })]);
})
}

export function fetchSelectedClientEpic(action$) {
    return action$.ofType(FETCH_SELECTED_CLIENT)
    .switchMap(({payload}) => {
        return Observable.ajax(requestForEndpointAndPayload('query',{
            clients:  [
                        {
                            and : [["uuid","=",payload]]
                        }
                    ],
            client_phones: [
                {
                    and : [["client","=",payload]]
                }
            ]
        })
            )
        .switchMap(result => {
            let client = result.response.clients[0];
            client.client_phones = result.response.client_phones;
            return Observable.of(client)
        })
        .switchMap((client) => appendDatesProxyObservable([client], 'dates', ['uuid','start_timestamp','title']))
        .switchMap(clients => appendTransactionsProxyObservable(clients))
        .map(clients => {
           return fetchSelectedClientFullfilled(clients[0])
       })
        .catch((e) => {
            console.log("error from server", e)
            return Observable.concat([handleAjaxError(e), fetchSelectedClientFullfilled(null)]);
           //return Observable.of(setLoginErrorMessage('Could not find user with username and/or password!'))
        })
    });
}

export function saveClientEpic(action$, store) {
    return action$.ofType(SAVE_CLIENT)
    .switchMap(({payload}) => {
        return Observable.ajax(requestForEndpointAndPayload('put',{
            clients:  [payload.client]
        }))})
        .switchMap((result) => appendDateProxyObservable({
            ...result.response,
            clients_pagination_info:[store.getState().clients.clients_pagination_info]
        }))
        .map(({clients}) => {
            return saveClientFullfilled(clients[0]);
        }).catch((e) => {
            console.log("error from server", e)
            return Observable.concat([handleAjaxError(e), saveClientFullfilled(null)]);
           // return Observable.of(setSaveTagErrors(e.response.details))
        })
    
}

export function saveClientsAppointmentEpic(action$) {
    return action$.ofType(SAVE_CLIENTS_APPOINTMENT)
    .switchMap(({payload}) => Observable.concat(
            saveAppointmentObservable(payload.appointment),
            Observable.of(fetchClients(payload.appointment.owning_professional, payload.page, payload.length, payload.searchTerm, payload.tags))
            )
    )
}

export function deleteClientEpic(action$) {
    return action$.ofType(DELETE_CLIENT)
    .switchMap(({payload}) => {
        return Observable.ajax(requestForEndpointAndPayload('delete',{
            client_ids:  [payload.uuid]
        }))
        .map(result => {
            return deleteClientFullfilled(result.response.client_ids[0]);
        }).catch((e) => {
            console.log("error from server", e)
            return Observable.concat([handleAjaxError(e), deleteClientFullfilled(null)]);
           // return Observable.of(setSaveTagErrors(e.response.details))
        })
    })
}

export const appendDateProxyObservable = response => {
    let dateIds = response.clients.filter(client => client.future_active_dates.length > 0).map(client => {
        return client.future_active_dates[0];
    })
    return Observable.ajax(requestForEndpointAndPayload('get',{
    date_ids: dateIds ,
    dates_properties:['start_timestamp','uuid']
})).flatMap(date_result => {
    let dates = date_result.response.dates;
    let _clients = response.clients.map(client => {
        let next_date = null;
        if(client.future_active_dates.length > 0) {
            next_date = dates.filter(date => date.uuid === client.future_active_dates[0])[0];
        }
        return {...client, next_date};
    })
    return Observable.of({ clients_pagination_info: response.clients_pagination_info[0], clients: _clients});
})}

const appendDatesProxyObservable = (clients, key='dates', properties = ['start_timestamp','uuid']) => {
    let dateIds = []
   clients.filter(client => client[key].length > 0).forEach(client => {
        dateIds = dateIds.concat(client[key]);
    })
   /*  if(dateIds.length===0) {
        return Observable.of([]);
    } */
    return Observable.ajax(requestForEndpointAndPayload('get',{
    date_ids: dateIds ,
    dates_properties:properties
})).flatMap(date_result => {
    let dates = date_result.response.dates;
    let _clients = clients.map(client => {
        let newClient = {...client}
        let newDates = dates.map(date => {
            return {...date, status : client.confirmed_dates.includes(date.uuid)
                ?ConfirmationStatus.CONFIRMED
                :moment(date.start_timestamp).isAfter()?ConfirmationStatus.PENDING:ConfirmationStatus.EXPIRED};

        })
        if(client[key].length > 0) {
            newClient[key] = newDates;
        } else {
            newClient[key] = [];
        }
        
        return newClient;
    })
    return Observable.of(_clients);
})}


const appendTransactionsProxyObservable = clients => {

    let transactionIds = []
   clients.filter(client => client.transactions.length > 0).forEach(client => {
    transactionIds = transactionIds.concat(client.transactions);
    })
   /*  if(dateIds.length===0) {
        return Observable.of([]);
    } */
    return Observable.ajax(requestForEndpointAndPayload('get',{
    transaction_ids: transactionIds
})).flatMap(transactions_result => {
    let transactions = transactions_result.response.transactions;
    let _clients = clients.map(client => {
        let newClient = {...client}
        if(client.transactions.length > 0) {
            newClient.transactions = transactions;
        } else {
            newClient.transactions = [];
        }
        
        return newClient;
    })
    return Observable.of(_clients);
})}
