import { connect } from 'react-redux';
import {Main} from 'components/Main/Main';
import { showMIC } from 'actions/MICActions';
import { endTour, login } from 'actions/SessionActions';
import { reloadIntegrationSettings } from '../../actions/IntegrationSettingsAction';
import { loginWithToken } from '../../actions/SessionActions';


function mapStateToProps(state) {
    return {
        isAuthorized:true&&state.session.user_id,
        showMIC:state.mic.showMIC,
        mic_token:state.mic.account_token,
        show_tour: state.session.show_tour
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
            openMIC: () => dispatch(showMIC(true)),
            closeMIC: () => dispatch(showMIC(false)),
            endTour: () => dispatch(endTour()),
            onSSOLogin: (token) => dispatch(loginWithToken(token))
    }
}

const MainContainer = connect(mapStateToProps, mapDispatchToProps)(Main)

export {MainContainer}