import { combineEpics } from 'redux-observable'
import { fetchGoogleEventsEpic, fetchAppointmentsEpic, 
        saveAppointmentEpic,saveAppointmentRequestEpic,deleteAppointmentEpic, selectAppointmentEpic, fetchSelectAppointmentEpic, fetchAppointmentRequestsEpic, appendAppointmentToApptRequestEpic, rejectAppointmentRequestEpic, fetchMicrosoftEventsEpic, fetchHolidayEventsEpic, fetchBirthdaysEpic} from './appointmentsEpic';
import {fetchClientsEpic,
        deleteClientEpic,
        saveClientEpic,
        fetchSelectedClientEpic,
        saveClientsAppointmentEpic} from './clientsEpic';
import { fetchTagsEpic,
    saveTagEpic,
deleteTagEpic,
fetchSelectedTagEpic} from './tagsEpic';
import { loginEpic, pingEpic, changeProfessionalEpic, loginWithTokenEpic } from './sessionEpic';
import { fetchSettingsEpic, saveSeetingsEpic, uploadDataEpic, reloadSyncSettingsEpic, toggleVisibilityOfMicrosoftCalendarEpic, toggleVisibilityOfGoogleCalendarEpic } from './settingsEpic';
import { showMICEpic, resetMICTokenEpic } from './MICEpic';
import { sendInstantMessageEpic } from './instantMessagesEpic';
import { setupEpic, validateScopeEpic } from './setupEpic';
import { resetPasswordEpic } from './passwordsEpics';
import { fetchCategoriesEpic, saveSmartTagEpic, fetchClientsForCategoryEpic } from './smartTagsEpic';
import { fetchAllCampaignsEpic, saveSelectedCampaignsEpic, deleteSelectedCampaignsEpic } from './campaignsEpic';
import { fetchIntegrationSettingsEpic } from './integrationSettingsEpic';








export const rootEpic = combineEpics(fetchAppointmentsEpic, 
        saveAppointmentEpic, 
        saveAppointmentRequestEpic,
        selectAppointmentEpic,
        deleteAppointmentEpic,
        fetchTagsEpic,
        saveTagEpic,
        fetchSelectedTagEpic,
        deleteTagEpic,
        fetchSelectAppointmentEpic,
        fetchClientsEpic,
        saveClientEpic,
        saveClientsAppointmentEpic,
        deleteClientEpic,
        fetchSelectedClientEpic,  
        loginEpic,
        loginWithTokenEpic,
        pingEpic,
        fetchSettingsEpic,
        saveSeetingsEpic,
        showMICEpic,
        resetMICTokenEpic,
        sendInstantMessageEpic,
        setupEpic,
        fetchAppointmentRequestsEpic,
        appendAppointmentToApptRequestEpic,
        rejectAppointmentRequestEpic,
        fetchGoogleEventsEpic,
        fetchMicrosoftEventsEpic,
        validateScopeEpic,
        uploadDataEpic,
        resetPasswordEpic,
        reloadSyncSettingsEpic,
        fetchCategoriesEpic,
        saveSmartTagEpic,
        fetchClientsForCategoryEpic,
        fetchHolidayEventsEpic,
        fetchBirthdaysEpic,
        toggleVisibilityOfMicrosoftCalendarEpic,
        toggleVisibilityOfGoogleCalendarEpic,
        fetchAllCampaignsEpic,
        saveSelectedCampaignsEpic,
        deleteSelectedCampaignsEpic,
        changeProfessionalEpic,
        fetchIntegrationSettingsEpic
);
