import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import './HelpLink.css'



export const HelpLink = ({topic, popup = 'Find out more.'}) => (
   <span className="link-help">
      <Popup
         trigger={
            <Icon
               color="blue"
               fitted
               circular
               onClick={()=> window.open(`https://assets.mojo.accountants/shared/help/${topic}help.html`,'HELP','width=600,height=600')}
               name="help"
            />
         }
         content={popup}
         position="right"
         size="mini"
         basic
      />
   </span>
)
