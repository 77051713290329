import { connect } from 'react-redux';
import {Signup} from 'components/Signup/Signup'
import {login, changePassword, changeUsername, setLoginErrorMessage, changeScope} from 'actions/SessionActions';
import { setup, validateScope } from 'actions/SetupActions';


const mapStateToProps = (state, ownProps) => {

    return {
        username:state.session.username,
        password:state.session.password,
        message:state.session.loginErrorMessage,
        //loading:state.session.loading,
        defined_scope:state.session.defined_scope,
        signup_completed: state.setup.completed,
        scope: state.setup.scope,
        loading:state.setup.loading
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onLogin: (username, password) => {
            if(!username || !password) {
                dispatch(setLoginErrorMessage("Username or Password must not be empty!"))
            } else {
                dispatch(login(username, password))
            }
        },
        validateScope: (scope) => dispatch(validateScope(scope)),
        onChangeScope: (scope) => dispatch(changeScope(scope)),
        onChangeUsername: (username) => dispatch(changeUsername(username)),
        onChangePassword: (password) => dispatch(changePassword(password)),
        onSetup: (company_name, user_name, first_name, last_name, email, password, scope) => dispatch(setup(company_name, user_name, first_name, last_name, email, password, scope))
    }
}


const SignupContainer = connect(mapStateToProps,mapDispatchToProps)(Signup);

export {SignupContainer};