import React from 'react'
import { Dropdown } from 'semantic-ui-react'

const options = (tags) => tags.map((tag) => {
    return { key: tag.uuid, text: tag.name, value: tag.uuid, label: { color: tag.color, empty: true, circular: true }}
})

const TagsSelection = ({onChange, selectedTags = [], availableTags = [], ...props}) => (
  <Dropdown 

  placeholder='Tags' 
  value={selectedTags} 
  multiple
  selection 
  options={options(availableTags)} 
  onChange={(event, {value}) => onChange?onChange(value):console.log('onChange',value)}
  renderLabel={renderLabel}
  {...props}
  />
)

const renderLabel = (option, index, labelProps ) => {
  return {color:option.label.color,content:option.text}};

export default TagsSelection