export const RELOAD_INTEGRATION_SETTINGS = "RELOAD_INTEGRATION_SETTINGS";
export const RELOAD_INTEGRATION_SETTINGS_FULLFILLED = "RELOAD_INTEGRATION_SETTINGS_FULLFILLED";


export function reloadIntegrationSettings(professional_id) {
    return {
        type : RELOAD_INTEGRATION_SETTINGS,
        payload : {
            professional_id
        }
    }
}

export function reloadIntegrationSettingsFullfilled(googleSyncSetting, microsoftSyncSetting, zoomSetting) {
    return {
        type : RELOAD_INTEGRATION_SETTINGS_FULLFILLED,
        payload : {
            googleSyncSetting,
            microsoftSyncSetting,
            zoomSetting
        }
    }
}