export const FETCH_CLIENTS = "FETCH_CLIENTS";
export const FETCH_CLIENTS_FULLFILLED = "FETCH_CLIENTS_FULLFILLED";
export const FETCH_SELECTED_CLIENT = "FETCH_SELECTED_CLIENT";
export const FETCH_SELECTED_CLIENT_FULLFILLED = "FETCH_SELECTED_CLIENT_FULLFILLED";
export const SAVE_CLIENT = "SAVE_CLIENT";
export const SAVE_CLIENT_FULLFILLED = "SAVE_CLIENT_FULLFILLED";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const DELETE_CLIENT_FULLFILLED = "DELETE_CLIENT_FULLFILLED";
export const CLEAR_SELECTED_CLIENT = "CLEAR_SELECTED_CLIENT";
export const ADD_CLIENT = "ADD_CLIENT";
export const SAVE_CLIENTS_APPOINTMENT = "SAVE_CLIENTS_APPOINTMENT";


const saveClientsAppointment = (appointment, page, length, searchTerm, tags) => {
    return {
        type:SAVE_CLIENTS_APPOINTMENT,
        payload:{appointment, page, length, searchTerm, tags}
    }
}

const addClient = (client) => {
    return {
        type: ADD_CLIENT,
        payload: client       
    }
}


const fetchClients = (professional, page, length, searchTerm, tags) =>  { 
    return {
        type:FETCH_CLIENTS,
        payload: {
            professional,
            page,
            length,
            searchTerm,
            tags
        }
    }
}

const fetchClientsFullfilled = (clients, clients_pagination_info) =>  { 
    return {
        type:FETCH_CLIENTS_FULLFILLED,
        payload: {
            clients,
            clients_pagination_info
        }
    }
}

const clearSelectedClient = () => {
    return {type: CLEAR_SELECTED_CLIENT}
}

const saveClient = (client) =>  { 
            return {
                type:SAVE_CLIENT,
                payload: {client}
            }
        }

const saveClientFullfilled = (client) =>  { 
            return {
                type:SAVE_CLIENT_FULLFILLED,
                payload: {client}
            }
        }

const fetchSelectedClient = (clientId) =>  { 
        return {
                type:FETCH_SELECTED_CLIENT,
                payload: clientId
        }
    }

const fetchSelectedClientFullfilled = (client) =>  { 
            return {
                type:FETCH_SELECTED_CLIENT_FULLFILLED,
                payload: client
            }
        }


const deleteClient = (client) => { 
            return {
                type:DELETE_CLIENT,
                payload: client
            }
        }

const deleteClientFullfilled = (client_id) => { 
            return {
                type:DELETE_CLIENT_FULLFILLED,
                payload: client_id
            }
        }

export {addClient, clearSelectedClient, fetchSelectedClient, fetchSelectedClientFullfilled, fetchClients, fetchClientsFullfilled, saveClient, saveClientFullfilled, deleteClient, deleteClientFullfilled, saveClientsAppointment}
