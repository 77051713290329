export const SEND_INSTANT_MESSAGE = 'SEND_INSTANT_MESSAGE';
export const SEND_INSTANT_MESSAGE_FULLFILLED = 'SEND_INSTANT_MESSAGE_FULLFILLED';

export function sendInstantMessage(communication_type, receivers, sender, message, email_subject) {
    return {
        type : SEND_INSTANT_MESSAGE,
        payload: {
            communication_type, 
            receivers, 
            sender, 
            message, 
            email_subject
        }
    }
}

export function sendInstantMessageFullfilled() {
    return {
        type : SEND_INSTANT_MESSAGE_FULLFILLED
    }
}