import React from 'react'
import  { Dimmer, Loader, Button, Form, Grid, Header, Image, Message, Segment, Label } from 'semantic-ui-react'
import logo from 'assets/logo.png'
import { Footer } from 'components/Footer/Footer';
import { IS_BETA } from '../../App';

const Login = (props) => (

  <div className='login-form'>
        <Dimmer active={props.loading}>
                    <Loader />
                </Dimmer>
    <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}</style>
    <Grid
      style={{ height: '100%' }}
      verticalAlign='middle'
      centered={true}
    >
      <Grid.Column style={{ maxWidth: 450 }}>

        <Header as='h1' color='grey' textAlign='center'>
          <Image src={logo} style={{width:'100px'}}/>
        </Header>
        <Form size='large'>
          <Segment >
          {IS_BETA&&<Label as='a' color='red' ribbon icon="key" content="Beta" />}
            <Form.Group>
            <Form.Input
              label="Username"
              width={props.defined_scope?'16':'8'}  
              icon='at'
              iconPosition='left'
              placeholder='Username'
              value={props.username}
              onInput={(e) => props.onChangeUsername(e.target.value)}
            />{!props.defined_scope&&(<React.Fragment>
              
              <Form.Input
              label="Company Id"
              iconPosition='left'
              icon="hashtag"
              width="8"
              placeholder='Company Id'
              value={props.scope}
              onInput={(e) => props.onChangeScope(e.target.value)}
            /></React.Fragment>)
            }
            </Form.Group>
            <Form.Input
              label="Password"
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Password'
              type='password'
              value={props.password}
              onChange={(e) => props.onChangePassword(e.target.value)}
            />


            <Button color='green' fluid size='large' onClick={() => props.onLogin(props.username,props.password)}>Login</Button>
            
            {props.message&&<Message negative={true}>{props.message}</Message>}
          </Segment>
         {/* <div style={{marginBottom:'10px', textAlign: 'center'}}><span onClick={() => alert('Please contact customer support to reset your password.')} style={{color:'blue',textDecoration:'underline',cursor:'pointer'}}>Forgot Password</span> | <span onClick={() => props.history.push('/signup')} style={{color:'blue',textDecoration:'underline',cursor:'pointer'}}>Sign Up</span></div> */}
        </Form>
        <Footer />
      </Grid.Column>
    </Grid>
   
  </div>
)

export {Login}