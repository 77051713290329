import React, { Component } from 'react'
import { Form, Button, Icon, Grid, Segment, Label, Tab, Message, Header} from 'semantic-ui-react'

import './SmartTags.css';
import PropTypes from 'prop-types';
import {randomColor} from 'utils/layoutUtils';
import Wrapper from 'widgets/Wrapper';
//import {SendCommunication} from 'components/Tags'
import ComponentWrapper from 'widgets/ComponentWrapper/ComponentWrapper'

import LoadingIndicator from 'widgets/LoadingIndicator';
import moment from 'moment';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, 
    ContentState, 
    bold, 
    italic,
    underline, 
    superscript, 
    subscript, 
    fontSize,
    unordered,
    ordered,
    indent,
    outdent,
    left,
    center,
    right,
    color,
    link,
    unlink,
    image,
    eraser,
    undo,
    redo,
    justify,
    strikethrough,
    monospace
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EMAIL } from 'constants/CommunicationTypes';
import { PreviewButton } from 'widgets/PreviewButton';
import { DataTable } from 'widgets/DataTable';
import { InstantEmail } from 'widgets/InstantEmail';
import { PaginationFooter } from 'widgets/PaginationFooter/PaginationFooter';

export class SmartTags extends Component {
    constructor(props) {
        super(props)
        this.state ={};
        this.bound_initializeData = this.initializeData.bind(this);
    }

    initializeData() {
      this.props.handleSelectSmartTag(null);
      this.props.handleFetchSettings();
        this.props.fetchTags(this.props.professional);
        this.props.fetchCategories(this.props.professional);
    }
  
    show(dimmer) {
         this.setState({...this.state, dimmer})
    }

    close() {
        this.setState({...this.state,selectedTag:null})
    }

    componentWillUnmount() {
      document.removeEventListener('professionalChanged', this.bound_initializeData);
    }

    componentDidMount() {
      document.addEventListener('professionalChanged',  this.bound_initializeData, false);
      this.props.handleFetchSettings();
        this.props.fetchTags(this.props.professional);
        this.props.fetchCategories(this.props.professional);
    }

    handleSaveSmartTag(savedTag) {
            this.props.handleSaveChanges(savedTag)
    }

    handleDeleteTag(deleteTag) {
            this.props.handleDeleteTag(deleteTag)
    }

    selectedTag() {
        if(this.props.selectedTag) {
            return this.props.selectedTag;
        }
        else {
            return {professional:this.props.professional,
                color:randomColor(),
                name:'',
                description:''}
        }
    }

    reminderCommunicationTypes() {
        let reminderOccasion = this.props.occasions
        .filter(occasion => occasion.name === 'TAG_CAMPAIGN')[0];
       return this.props.communicationTypes.filter(communicationType => {
            
            return reminderOccasion.communication_types.includes(communicationType.name)
       })
    }

    handlePagination(activePage) {
      this._handleFetchClients({ page: activePage })
  }

  handlePageSize(page_size) {
      this._handleFetchClients({ page: 1, page_size })
  }

  _handleFetchClients({
      professional=this.props.professional,
      page=this.props.selectedCategory.client_query.clients_pagination_info.page,
      page_size=this.props.selectedCategory.client_query.clients_pagination_info.page_size,
      category=this.props.selectedCategory
  }) {
      this.props.handleFetchClients(professional, category, page, page_size);
  }
  
    templatesForOccasion() {
        return this.props.communicationMessageTemplates
        .filter(template => template.occasion === 'TAG_CAMPAIGN')
     }

    render () {
      const stringOrdering = (a, b, key) => {
        if(!a&&b) {
            return -1;
        }
        if(a&&!b) {
            return 1;
        }
        if(!a&&!b) {
            return 0;
        }
        if(key) {
            let compA = a[key]?a[key].toLowerCase():''
            let compB = b[key]?b[key].toLowerCase():''
            return compA.localeCompare(compB) 
        }
        return a.toLowerCase().localeCompare(b.toLowerCase())
    };
        return (<ComponentWrapper>
            <Wrapper icon="tags" loading={this.props.loading}>    
            <Grid>
        <Grid.Column width={4}>
        <Segment.Group>
          {this.props.categories.sort((a, b) => a.sort_order > b.sort_number).map((category) => {
              const selected = this.props.selectedCategory && category.uuid===this.props.selectedCategory.uuid
              return (
            <Segment 
                key={category.uuid} 
                color={selected?'green':null} 
                inverted={selected} 
                onClick={() => this.props.handleSelectSmartTag(category)} style={{cursor:'pointer'}}>
                <Header as="h4">{`${category.beautified_name} (${category.client_query.clients_pagination_info.total_item_count})`}</Header>
                {`${category.category_description}`}
            </Segment>
        )}
        )}
          </Segment.Group>
        </Grid.Column>

        <Grid.Column stretched width={12}>
          <Segment>
            <LoadingIndicator loading={this.props.loading_selected_smart_tag} />
            {this.props.selectedCategory&&<Tab activeIndex={this.state.tagTabIndex} onTabChange={(event, data) => this.setState({...this.state,tagTabIndex:data.activeIndex})} panes={ [
                { menuItem: 'Clients', render: () => 
                    <Tab.Pane>
                      {
                        /*
                        <ClientTable 
                            pageSize={10} 
                                clients={this.props.selectedCategory.client_query.clients.map(client => {
                                    return {...client,complete_name:completeName(client)}
                                    })
                                }/>*/
                              }



<DataTable 
                        id={`clientsInSmartTag_${this.props.selectedCategory.uuid}`}
                        columnDescription={[
                            {
                                key:'given_name',
                                header:'First Name',
                                sorting:(a,b) => stringOrdering(a, b, 'given_name')
                            },
                            {
                                key:'family_name',
                                header:'Last Name',
                                sorting:(a,b) => stringOrdering(a, b, 'family_name')
                            },
                            {
                                key:'company_name',
                                header:'Company',
                                sorting:(a,b) => stringOrdering(a, b, 'company_name')
                            },
                            {
                                key:'email',
                                header:'Email',
                                format:(email, client) => email?<InstantEmail client={client} office={this.props.office} professional={this.props.professional_object} html_signature={this.props.professional_object?this.props.professional_object.html_signature:''}/>:null,
                                sorting:(a,b) => stringOrdering(a, b, 'email')
                            }
                        ]}
                        /*onSelectRow={(client) => this.props.handleSelectClient(client)}*/
                        initialSorting= {{
                            sorting: (a,b) => stringOrdering(a, b, 'family_name'),
                            asc:true,
                            key:'family_name'
                        }}
                        dataRows={this.props.selectedCategory.client_query.clients}
                        pageSize={50}
                        maxPageCount={10}
                    />
                    <PaginationFooter 
                        {...this.props.selectedCategory.client_query.clients_pagination_info}
                        handlePageSizeChange={(e,d) => this.handlePageSize(e,d)}
                        handlePaginationChange={(e,d) => this.handlePagination(e,d)}
                    />







                    </Tab.Pane> 
                },
                { menuItem: 'Communication', render: () => 
                    <Tab.Pane>
                        <SendCommunication
                            communicationMessageTemplates={this.templatesForOccasion()} 
                            communicationTypes={this.reminderCommunicationTypes()} 
                            category={this.props.selectedCategory}
                            smartTag={this.props.selectedCategory.smartTag
                              ||{category:this.props.selectedCategory.name,
                              professional:this.props.professional}} 
                            sendToSmartTag={(tag) => this.handleSaveSmartTag(tag)} 
                            professional={this.props.professional} 
                            buttonLabel="Send"/>  
                    </Tab.Pane> }

            ]} />} 
          </Segment>
        </Grid.Column>
      </Grid> 
      </Wrapper>

      </ComponentWrapper>
        )
    }
}

SmartTags.propTypes = {
    tags:PropTypes.array,
    handleSaveChanges:PropTypes.func,
    handleDeleteTag:PropTypes.func
}

class SendCommunication extends Component{
    constructor(props) {
        super(props);
        this.state = {
            smartTag : {...props.smartTag},
            bodyContentState:convertToRaw(ContentState.createFromBlockArray(htmlToDraft(props.smartTag.personal_note || '')))
        };
        console.log(this.state)
    }

    updateSmartTag(key, value) {
        let smartTag = {...this.state.smartTag};
        smartTag[key] = value;
        this.setState({...this.state,smartTag})
    }


    static getDerivedStateFromProps(props, state) {

      if (state.smartTag.category !== props.smartTag.category) {
          return {...state,
            smartTag:props.smartTag,
            bodyContentState:convertToRaw(ContentState.createFromBlockArray(htmlToDraft(props.smartTag.personal_note || '')))
          }
      }
      return state;
  }

    render() {
    let displayedUI;
    if(this.props.smartTag.status !== 'Active') {
        displayedUI =  <Form>
        {this.state.smartTag.last_processing_date&&<div>Last Sent: {moment(this.state.smartTag.last_processing_date).format('llll')}</div>}

<Form.Input 
    label='Subject' 
    type='text' 
    placeholder="Your email subject here..."
    onChange={(event, {value}) => this.updateSmartTag('personal_note_email_subject', value)}
    value={this.state.smartTag.personal_note_email_subject||''}
/>  
<div style={{height:'400px'}}>
        <Editor
  wrapperClassName="wrapper-class"
  editorClassName="editor-class"
  toolbarClassName="toolbar-class"
  //initialContentState={}
  contentState={this.state.bodyContentState}
  toolbar={editorToolbar}
  //initialEditorState={editorState}
  /*wrapperStyle={<wrapperStyleObject>}
  editorStyle={<editorStyleObject>}
  toolbarStyle={<toolbarStyleObject>}*/
  onChange={(contentState) => this.updateSmartTag('personal_note', draftToHtml(contentState))}
/>
</div>
<div style={{marginTop:'10px'}}>
<Button.Group><PreviewButton templatePersonalization={this.state.smartTag.personal_note} sender={this.props.professional} communicationMessageTemplate={this.props.communicationMessageTemplates[0].uuid} communicationType='EMAIL'/>
<Button 
            color='green'
            onClick={() => this.props.sendToSmartTag(
                {...this.state.smartTag, 
                    communication_message_template:this.props.communicationMessageTemplates[0].uuid,
                    communication_type: EMAIL
                })}
                ><Icon name='save'/> Save</Button>
<Button 
            color='green' 
            onClick={() => {if(window.confirm('Are you sure?')){
                this.props.sendToSmartTag(
                {...this.state.smartTag, 
                    status:'Active',
                    communication_message_template:this.props.communicationMessageTemplates[0].uuid,
                    communication_type: EMAIL
                }
            )
        }}}
                ><Icon name='send'/> {this.props.buttonLabel}</Button></Button.Group></div>
            </Form>
    } else {
        displayedUI = <Message icon>
        <Icon name='circle notched' loading />
        <Message.Content>
          <Message.Header>Processing...</Message.Header>
          We are busy sending your emails.
        </Message.Content>
      </Message>
    }
    return (
        
            <Segment><h5>Quickly and easily send a message to clients with the <Label color='green' content={this.props.category.beautified_name} /> SmartTag.</h5>
                {displayedUI}
        </Segment>
    )
}
}


const editorToolbar = {
    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', /*'embedded', 'emoji', */'image', 'remove', 'history'],
    inline: {
      inDropdown: true,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
      bold: { icon: bold, className: undefined },
      italic: { icon: italic, className: undefined },
      underline: { icon: underline, className: undefined },
      strikethrough: { icon: strikethrough, className: undefined },
      monospace: { icon: monospace, className: undefined }, 
      superscript: { icon: superscript, className: undefined },
      subscript: { icon: subscript, className: undefined },
    },
    blockType: {
      inDropdown: true,
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    fontSize: {
      icon: fontSize,
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    fontFamily: {
      options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    list: {
      inDropdown: true,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['unordered', 'ordered', 'indent', 'outdent'],
      unordered: { icon: unordered, className: undefined },
      ordered: { icon: ordered, className: undefined },
      indent: { icon: indent, className: undefined },
      outdent: { icon: outdent, className: undefined },
    },
    textAlign: {
      inDropdown: true,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['left', 'center', 'right', 'justify'],
      left: { icon: left, className: undefined },
      center: { icon: center, className: undefined },
      right: { icon: right, className: undefined },
      justify: { icon: justify, className: undefined },
    },
    colorPicker: {
      icon: color,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
        'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
        'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
        'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
        'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
        'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
    },
    link: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      dropdownClassName: undefined,
      showOpenOptionOnHover: true,
      defaultTargetOption: '_self',
      options: ['link', 'unlink'],
      link: { icon: link, className: undefined },
      unlink: { icon: unlink, className: undefined },
    },/*
    emoji: {
      icon: emoji,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      emojis: [
        '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
        '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
        '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
        '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
        '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
        '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
        '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
        '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
        '✅', '❎', '💯',
      ],
    },
    embedded: {
      icon: embedded,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      defaultSize: {
        height: 'auto',
        width: 'auto',
      },
    },*/
    image: {
      icon: image,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: true,
      uploadCallback: undefined,
      previewImage: false,
      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: 'auto',
        width: 'auto',
      },
    },
    remove: { icon: eraser, className: undefined, component: undefined },
    history: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['undo', 'redo'],
      undo: { icon: undo, className: undefined },
      redo: { icon: redo, className: undefined },
    },
  };
