import React from 'react'
import { Header, Table, Label, Icon, Form, Tab, Button } from 'semantic-ui-react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import moment from 'moment'
import { HelpLink } from 'widgets/HelpLink/HelpLink';

export const importTab = (professional,onImport, props) => {
    let fileInputRef = React.createRef();
    function prepareOnInput(ref) {
        const uploadFile = fileInputRef.current.files[0];
        if(uploadFile) {
            const reader = new FileReader();
           reader.readAsDataURL(uploadFile);
           reader.onload = event => {
                if(onImport){
                     onImport(professional, event.target.result)
                } else {    
                    console.log("upload:", event.target.result)
                }
                if(fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            }
        } else {
            alert('No file selected to upload');
        }
    }
    return (
        <Tab.Pane>
            <Form>
                <Header as='h3'>
                    Initial Import
                </Header>
                {
                    props.uploadingImport
                    ?
                        <Icon name="spinner" loading size="big"/>
                    :
                    <React.Fragment>
                        <Form.Field>
                            <label>
                                Select CSV file exported out of Contacts (<a href='https://assets.mojo.accountants/shared/help/OfficeMojoImportTemplate.csv' target='_blank' rel='noopener noreferrer'><Icon name='download' /> Import Format Template</a>) <HelpLink topic="import" />
                            </label>
                            <input type="file" ref={fileInputRef} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                        </Form.Field>
                        <Button type='submit' color="green" onClick={() => prepareOnInput()}>
                            Import
                        </Button>
                    </React.Fragment>
                }
            </Form>
            {
                props.dataImports&&props.dataImports[0]&&<React.Fragment>
                    <Header as="h3">Previous Imports</Header>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Date</Table.HeaderCell>
                                <Table.HeaderCell>ID</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                props.dataImports.sort((a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf())
                                .map(data_import => <Table.Row key={data_import.uuid}>
                                    <Table.Cell>
                                        {data_import.status==='PROCESSED'?<Label ribbon color='green'>processed</Label>:data_import.status.toLowerCase()}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {moment(data_import.timestamp).format('lll')}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {data_import.uuid}
                                    </Table.Cell>
                                </Table.Row>
                                )
                            }
                        </Table.Body>
                    </Table>
                </React.Fragment>
            }
        </Tab.Pane>
    )
}