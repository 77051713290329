import { connect } from 'react-redux';
import {Calendar} from 'components/Calendar/Calendar';
import {eventFromAppointment, eventFromAppointmentRequest, eventFromGoogleEvent} from 'utils/appointmentUtils'
import {saveAppointment, deleteAppointment, fetchAppointments, selectAppointment, clearSelectedAppointment, fetchAppointmentRequests, rejectAppointmentRequest, selectAppointmentRequest, clearSelectedAppointmentRequest, appendAppointmentToApptRequest, saveAppointmentRequest, fetchGoogleEvents} from 'actions/AppointmentActions';
import { fetchTags } from 'actions/TagActions';
import { DATE_REMINDER, DATE_NOTIFICATION } from 'utils/communicationSettingsUtils';
import { fetchClients } from 'actions/ClientActions';
import { fetchMicrosoftEvents, fetchHolidayEvents, fetchBirthdays } from '../../actions/AppointmentActions';
import { eventFromMicrosoftEvent, eventFromHolidayEvent, eventFromBirthday } from '../../utils/appointmentUtils';

const mapStateToProps = (state, ownProps) => {
    return {
        events: events(state.appointments.appointments)
        .concat(eventsFromRequests(state.appointments.appointmentRequests))
        .concat(googleEventsCalendarEvents(state.appointments.googleEvents.filter(googleEvent => !googleEvent.date)))
        .concat(microsoftEventsCalendarEvents(state.appointments.microsoftEvents.filter(microsoftEvent => !microsoftEvent.date)))
        .concat(eventsFromHolidayEvents(state.appointments.holidayEvents))
        .concat(eventsFromBirthdays(state.appointments.birthdays)),
        professional: state.session.professional,
        availableClients:state.clients.clients,
        date:state.appointments.date,
        loading:state.appointments.loading||state.clients.loading,
        view:state.appointments.view,
        selectedAppointment:state.appointments.selectedAppointment,
        selectedAppointmentRequest:state.appointments.selectedAppointmentRequest,
        communicationMessageTemplates:state.metaData.communicationMessageTemplates,
        reminderOffsets:state.metaData.reminderOffsets,
        occasions:state.metaData.occasions,
        communicationTypes:state.metaData.communicationTypes,
        dateLocations:state.metaData.dateLocations,
        availableTags:state.tags.tags,
        notifyClients: true&&state.appointments.communicationPreferences.filter(({occasion, status}) => occasion === DATE_NOTIFICATION && status === "Active")[0],
        communicationPreferences:state.appointments.communicationPreferences.filter(pref => pref.occasion === DATE_REMINDER),
        zoomSetting:state.integrationSettings.zoomSetting,
        microsoftSyncSetting:state.integrationSettings.microsoftSyncSetting,
        googleSyncSetting:state.integrationSettings.googleSyncSetting,
        features:state.session.features,
        scope:state.session.scope
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onFetchDates: (date, view, professional, tags) => {
            dispatch(fetchAppointments(date, view, professional, tags))
        },
        onFetchTags: (professional, page, length ) => {
            dispatch(fetchTags(professional, page, length))
        },
        onSaveAppointment: (appt) => {
            dispatch(saveAppointment(appt))
        },
        onSaveAppointmentRequest: (apptRequest) => {
            dispatch(saveAppointmentRequest(apptRequest))
        },
        handleDeleteAppointment: (appt) => {
            dispatch(deleteAppointment(appt))
        },
        handleFetchAppointment: (start, end, professional) => {
            dispatch(fetchAppointments(start, end, professional))
        },
        onSelectAppointment: (selectedAppointment) => dispatch(selectAppointment(selectedAppointment)),
        onClearSelectedAppointment: (selectedAppointment) => dispatch(clearSelectedAppointment()),
        onClearSelectedAppointmentRequest: (selectedAppointment) => dispatch(clearSelectedAppointmentRequest()),
        onFetchDateRequests: (start, end, professional,appointmentRequest) => {
            dispatch(fetchAppointmentRequests(start, end, professional, appointmentRequest))
        },
        onFetchGoogleEvents: (start, end, professional) => {
            dispatch(fetchGoogleEvents(start, end, professional))
        },
        onFetchMicrosoftEvents: (start, end, professional) => {
            dispatch(fetchMicrosoftEvents(start, end, professional))
        },
        onSelectApptRequest:(selectedAppointmentRequest) => dispatch(selectAppointmentRequest(selectedAppointmentRequest)),
        handleRejectAppointmentRequest:(appointmentRequest) => dispatch(rejectAppointmentRequest(appointmentRequest)),
        appendAppointmentToApptRequest:(appointmentRequest, clients) => {
            dispatch(appendAppointmentToApptRequest(appointmentRequest, clients));
            dispatch(fetchClients(appointmentRequest.professional))
        },
        onFetchHolidayEvents: (start, end) => {
            dispatch(fetchHolidayEvents(start, end))
        },
        onFetchBirthdays: (start, end, professional) => {
            dispatch(fetchBirthdays(start, end, professional))
        }


    }
}

const CalendarContainer = connect(mapStateToProps, mapDispatchToProps)(Calendar);


export {CalendarContainer} ;

const events = (appointments) => {

    return appointments.map((appointment) => eventFromAppointment(appointment));
    
    /*[
         {
            start:new Date('January 31, 2018 16:00:00'),
            end:new Date('January 31, 2018 18:00:00'),
            title:'Here I am',
            id:"1",
            allDay:false,
            editable:true,
            color:'#E00'
        } 
    ]*/
}

const eventsFromRequests = (appointmentRequests) => {
    return appointmentRequests.map((appointmentRequest) => eventFromAppointmentRequest(appointmentRequest));
}

const eventsFromHolidayEvents = (holidays) => {
    return holidays.map(holiday => eventFromHolidayEvent(holiday))
}

const eventsFromBirthdays = (birthdays) => {
    return birthdays.map(birthday => eventFromBirthday(birthday))
}

const googleEventsCalendarEvents = (calendarEvents) => {
    return calendarEvents.map((event) => eventFromGoogleEvent(event));
}

const microsoftEventsCalendarEvents = (calendarEvents) => {
    return calendarEvents.map((event) => eventFromMicrosoftEvent(event));
}