import React, {Component} from 'react';
import { connect } from 'react-redux';


function mapStateToProps(state) {
    const props = {
        features:state.session.features
    };
    return props;
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
}

function FeatureConditional(props) {
    const {features, requiredFeatureName} = props;
    if (hasRequiredFeature(features, requiredFeatureName)) {
        return props.children
    }
    return null;
}

export function hasRequiredFeature( features = [], requiredFeatureName) {
    for(let i = 0; i < features.length; i++) {
        if (features[i].name === requiredFeatureName) {
            return true
        }
    }   
    return false; 
}


const FeatureConditionalContainer = connect(mapStateToProps, mapDispatchToProps)(FeatureConditional);

export {FeatureConditionalContainer}
