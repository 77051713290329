import {Observable} from 'rxjs'
import { changeScope } from 'actions/SessionActions'
import { BASE_URL } from 'App';
import { SETUP, VALIDATE_SCOPE, setupFullfilled, setupFailed, validateScopeFullfilled } from 'actions/SetupActions';
import { handleAjaxError } from './sessionEpic';


export function setupEpic(action$, store) {
    return action$.ofType(SETUP)
    .switchMap(({payload}) => {
        return Observable.ajax(
            {
                url: BASE_URL+`/ep/Setup/setup`,
                method:'POST',
                responseType:'json',
                body:`pl=${encodeURIComponent(JSON.stringify(payload))}`
                }

        )
        .flatMap(result => {
            return Observable.concat(
                Observable.of(changeScope(result.response.companies[0].scope)),
                Observable.of(setupFullfilled())
            );
        }).catch((e) => {
            console.log('loginEpic.e', e)
            return Observable.concat(Observable.of(setupFailed()), Observable.of(handleAjaxError(e)))
        })
    });
}

export function validateScopeEpic(action$) {
    return action$.ofType(VALIDATE_SCOPE)
    .switchMap(({payload}) => {
        return Observable.ajax(
            {
                url: BASE_URL+`/ep/Setup/unique_scope`,
                method:'POST',
                responseType:'json',
                body:`pl=${encodeURIComponent(JSON.stringify(payload))}`
                }

        )
        .map(result => {
            return validateScopeFullfilled(result.response.unique_scope)
        }).catch((e) => {
            console.log('loginEpic.e', e)
            return Observable.of(handleAjaxError(e))
        })


    })
}
