import moment from 'moment'
import { normalizedLocalDate } from './calendarUtils';

export const APPOINTMENT = 'APPOINTMENT';
export const REQUEST = 'REQUEST';
export const GOOGLE_EVENT = 'GOOGLE_EVENT';
export const MICROSOFT_EVENT = 'MICROSOFT_EVENT';
export const ZOOM_MEETING_EVENT = 'ZOOM_MEETING_EVENT';
export const HOLIDAY_EVENT = 'HOLIDAY_EVENT';
export const BIRTHDAY = 'BIRTHDAY';



export function eventFromAppointment(appointment) {
    let start = moment(appointment.start_timestamp, moment.ISO_8601).toDate();
    let end = moment(appointment.end_timestamp, moment.ISO_8601).toDate();
    if(appointment.all_day&&appointment.microsoft_events&&appointment.microsoft_events.length>0) {
        //fix for microsoft 0 hour all_day events
        start = moment(appointment.start_timestamp.split('T')[0]).toDate()
        end = moment(appointment.end_timestamp.split('T')[0]).toDate()
    }
    return {
        start,
        end,
        id:appointment.uuid,
        editable:true,
        allDay:false,
        title:appointment.title,
        appointment,
        confirmed:appointment.confirmed_clients.length>0,
        type:APPOINTMENT
    }
}

export function eventFromAppointmentRequest(appointmentRequest) {
    return {
        start:moment(appointmentRequest.start_timestamp, moment.ISO_8601).toDate(),
        end:moment(appointmentRequest.end_timestamp, moment.ISO_8601).toDate(),
        id:appointmentRequest.uuid,
        editable:false,
        allDay:false,
        title:'Appt. Request',
        appointmentRequest,
        confirmed:false,
        type:REQUEST
    }
}

export function eventFromGoogleEvent(calendarEvent) {
    const end = moment(calendarEvent.end_timestamp, moment.ISO_8601).toDate();
    if(calendarEvent.all_day){
        /*Google has allDay from Midnight to including Midnight next day... 
        our calendar component interprets that as next day*/
        end.setTime(end.getTime()-1)
    }
    return {
        start:moment(calendarEvent.start_timestamp, moment.ISO_8601).toDate(),
        end,
        id:calendarEvent.uuid,
        editable:true,
        allDay:calendarEvent.all_day,
        title:calendarEvent.summary,
        calendarEvent,
        confirmed:true,
        type:GOOGLE_EVENT
    }
}

export function eventFromHolidayEvent(calendarEvent) {

    return {
        start:moment(calendarEvent.start_timestamp.replace("Z",""), moment.HTML5_FMT.DATETIME_LOCAL_SECONDS).toDate(),
        end:moment(calendarEvent.end_timestamp.replace("Z",""), moment.HTML5_FMT.DATETIME_LOCAL_SECONDS).toDate(),
        id:calendarEvent.uuid,
        editable:false,
        allDay:calendarEvent.all_day,
        title:calendarEvent.name,
        calendarEvent,
        confirmed:true,
        type:HOLIDAY_EVENT
    }
}

export function eventFromBirthday(calendarEvent) {
    return {
        start:calendarEvent.birthdayEvent,
        end:calendarEvent.birthdayEvent,
        id:calendarEvent.uuid,
        editable:false,
        allDay:calendarEvent.all_day,
        title:calendarEvent.name,
        calendarEvent,
        confirmed:true,
        type:BIRTHDAY
    }
}

export function openGoogleEventInGoogleCalendar(googleEvent) {
    const newLink = googleEvent.link.replace('https://www.google.com/calendar/event?eid=','https://calendar.google.com/calendar/r/eventedit/')
    window.open(newLink+'?sf=true');
}

export function eventFromMicrosoftEvent(calendarEvent) {

    let end;
    let start;
    if(calendarEvent.all_day){
        end = normalizedLocalDate(moment(calendarEvent.end_timestamp, moment.ISO_8601))
        start = normalizedLocalDate(moment(calendarEvent.start_timestamp, moment.ISO_8601))
       end.setTime(end.getTime()-1)
    } else {
        end = moment(calendarEvent.end_timestamp, moment.ISO_8601).toDate();
        start = moment(calendarEvent.start_timestamp, moment.ISO_8601).toDate();
    }
    return {
        start,
        end,
        id:calendarEvent.uuid,
        editable:true,
        allDay:calendarEvent.all_day,
        title:calendarEvent.summary,
        calendarEvent,
        confirmed:true,
        type:MICROSOFT_EVENT
    }
}

export function openMicrosoftEventInMicrosoftCalendar(microsoftEvent) {
    //const newLink = microsoftEvent.link.replace('https://www.google.com/calendar/event?eid=','https://calendar.google.com/calendar/r/eventedit/')
    window.open(microsoftEvent.link);
}
