import React, { Component } from 'react'
import { Modal, Button, Form, TextArea, Grid, Divider, Checkbox, Segment, Dimmer, Loader, Input, Tab, Select, Message, List} from 'semantic-ui-react'
import moment from 'moment'
import 'react-widgets/dist/css/react-widgets.css';
import {TextFormField} from 'widgets/FormWidgets'
import { ClientsSelectionContainer } from 'containers/ClientsSelectionContainer/ClientsSelectionContainer';
import TagsSelection from 'widgets/TagsSelection';
import ClickableDateTimePicker from 'widgets/ClickableDateTimePicker';
import modalInlineStyle from 'utils/modalCSSFix';
import { CommunicationSetup } from 'widgets/CommunicationSetup';
import { sortByOffsetTypes } from 'utils/offsetTypeUtils';
import SyncedEventAttendeeDisplay from '../../widgets/SyncedEventAttendeeDisplay/SyncedEventAttendeeDisplay';
import { ErrorBoundary } from '../../widgets/ErrorBoundary/ErrorBoundary';
import { copyToClipboard, CopyToClipboardButton } from '../../utils/clipboardUtils';
import _ from 'lodash'
import { hasRequiredFeature } from '../../widgets/FeatureConditional';
import { ZOOM_INTEGRATION, ANYWHERE_MEETING } from '../../constants/Features';
import { IS_DEV, requestForEndpointAndPayload, BASE_URL, requestForAnyWhereMeeting,requestForRecordURL, scope } from '../../App';
import { completeName } from 'utils/clientUtils';


export class AppointmentDetail extends Component {
    constructor(props) {
        super(props);
        let reminderKeys = ['first_reminder','second_reminder','third_reminder'];
        this.state = {
           selectedAppointment:props.selectedAppointment,
           endPicker_open:'date',
           downloadLink: [],
           isRecordedClicked : false,
           noRecordings : false,
           meetingexpired : false,
           selectedClients:[]
          
        }
            let sortedReminders = sortByOffsetTypes(props.reminderOffsets, props.selectedAppointment.appt_reminders);
            let sortedPreferences = sortByOffsetTypes(props.reminderOffsets, props.communicationPreferences);
            reminderKeys.forEach((reminderKey, index) => {
                let reminder;
                if (props.selectedAppointment.uuid) {
                    reminder = sortedReminders[index]
                } else {
                    if(sortedPreferences[index]) {
                        let {offset_type, communication_message_template, communication_type, status} = sortedPreferences[index]
                        reminder = {communication_type, communication_message_template, status, offset_type};
                    } 
                }
                if(!reminder) {
                    reminder = this.createNewReminder(index);
                }
                this.state[reminderKey] = reminder;
            })


    }

    componentDidMount() {
        this.fetchSelectedClients(this.state.selectedAppointment.clients);
    }

    fetchSelectedClients(clientIds) {

        if(!clientIds||clientIds.length<1) {
          this.setState({...this.state,selectedClients:[], loading:false});
          return;
        }
        const request = requestForEndpointAndPayload('get',{
          client_ids:  clientIds,
          client_properties:['given_name','family_name','company_name','uuid']
      })
    
    
      fetch(request.url,request).then(response => response.json()).then(
        data => {
          this.setState({...this.state,selectedClients:data.clients, loading:false})});
      }

    createNewReminder(index) {
            let offset = this.props.reminderOffsets.length>index?this.props.reminderOffsets[index]:this.props.reminderOffsets[this.props.reminderOffsets.length-1];
            let offsetDate = moment(this.props.selectedAppointment.start_timestamp).add(offset.offset, 'ms').endOf('day');
            return {
                communication_type:this.props.communicationTypes[0].name,
                communication_message_template:this.props.communicationMessageTemplates[0].uuid,
                status:moment().isAfter(offsetDate)?'Inactive':'Active',
                offset_type:offset.name
                };
    }

    update(value, key) {
        let updated = {...this.state.selectedAppointment};
        if( key === "end_timestamp") {
            const {start_timestamp} = updated;
            const new_end = moment(value, moment.ISO_8601);
            const start = moment(start_timestamp, moment.ISO_8601)
            if(!new_end.isAfter(start)) {
                alert('Start needs to be before End');
                return;
            }
        }
        
        if(key === "start_timestamp") {
            const {start_timestamp, end_timestamp} = updated;
            const length = moment(end_timestamp,moment.ISO_8601).toDate().getTime() - moment(start_timestamp,moment.ISO_8601).toDate().getTime();
            updated.start_timestamp = value;
            updated.end_timestamp = moment(new Date(moment(value,moment.ISO_8601).toDate().getTime()+length)).toISOString();
        } else {
            updated[key] = value;
        }

        /*if(key==='clients') {
            updated.title = concatedClientNames(value, this.props.availableClients);
        }*/
        this.setState(
            {...this.state, 
                selectedAppointment:updated
            }
        )
    }

    _endTimePicker_openClick(event) {
        if (event.target.tagName === 'INPUT') {
          this.setState({...this.state, endTimePicker_open: 'calendar'});
        }
      }

      _endTimePicker_handleToggle(view) {
        this.setState({ ...this.state, endTimePicker_open: view })
      }

      availableReminderOffsets(offsetType) {
        return this.props.reminderOffsets;
        //this.props.reminderOffsets.fo(offset => moment(offset.offset_date).isBefore(moment(this.props.selectedAppointment.start_timestamp)))
       /*  return this.props.reminderOffsets.filter(offset => {
            return ((offsetType) && offsetType.offset_type === offset.name) || moment(offset.offset_date).isBefore(moment(this.props.selectedAppointment.start_timestamp))
        } 
        )*/
      }

      reminderDisabled(reminder) {
        if (!reminder) {
            return false;
        }
        let reminderOffset = this.props.reminderOffsets.filter(offset => {
            return offset.name === reminder.offset_type
        }
        )[0];  
        if(!reminderOffset) {
            return false;
        }
        return reminder.status === 'Processed' //|| moment(reminderOffset.offset_date).is(moment(this.props.selectedAppointment.start_timestamp)))
      }

    zoomMeetings() {
        let zoomMeetings = this.state.selectedAppointment.zoom_meeting_events;
        if(this.state.selectedAppointment.location === "ONLINE_ZOOM") {
            let {owning_professional,start_timestamp,end_timestamp} = this.state.selectedAppointment;
            const protoZoomEvent = {
                professional:owning_professional,
                start_timestamp,
                end_timestamp,
                status:'ACTIVE'
                }
            if(zoomMeetings?.length === 0 ) {
                //Need to create a new ZoomEvent
                zoomMeetings = [protoZoomEvent];
            }
        }

        return zoomMeetings;
    }

    saveChanges(){
        if(this.state.selectedAppointment.location) {
           //added for validation
        } 
        else{
            alert('Please select location');
            return;           
        }
        const zoom_meeting_events = this.zoomMeetings();
        this.props.saveSelectedAppointment({...this.state.selectedAppointment,
            appt_reminders:[this.state.first_reminder,
                this.state.second_reminder,
                this.state.third_reminder],
                zoom_meeting_events
        })
    }

    templatePreviewData() {
        if(this.state.selectedAppointment) {
        return {
            date:this.state.selectedAppointment.start_timestamp,
            receiver:this.state.selectedAppointment.clients[0],
            sender:this.props.professional
            }
        } else {
            return {}
        }
    }

    truncatedCopyableString(value, length, isLink) {
       
        if(!value) {
            return <React.Fragment>tbd</React.Fragment>;
        }
        if(isLink) {
        return <React.Fragment><a href={value} target="_new">{_.truncate(value, {
            'length': length})}</a> <CopyToClipboardButton text={value} /></React.Fragment>;
        }
        return <React.Fragment>{_.truncate(value, {
            'length': length})} <CopyToClipboardButton text={value} /></React.Fragment>;
    }

    zoomDetailInformation() {
        //this.state.selectedAppointment.confirmations[0];

        const zoomMeetingEvent = this.state.selectedAppointment.zoom_meeting_events[0];
        const zoomMeetingCreated = (zoomMeetingEvent&&zoomMeetingEvent.event_id);
        const zoomAvailable = this.props.zoomSetting&&this.props.zoomSetting.status==='ACTIVE';
        let message = null;
        if(!hasRequiredFeature(this.props.features,ZOOM_INTEGRATION)) {
            message = <span>Contact Customer Support to activate Zoom&trade; Integration</span> 
        } else if(zoomAvailable) {
            
            if(zoomMeetingEvent) {

                message = <Grid columns='equal'><Grid.Column>Zoom&trade; Start Link:<br />Zoom Join Link:<br />Zoom Password: </Grid.Column>
                                <Grid.Column width={10}>
                                {this.truncatedCopyableString(zoomMeetingEvent.start_url, 40, true)}<br />
                                {this.truncatedCopyableString(zoomMeetingEvent.join_url, 40, true)}<br />
                                {this.truncatedCopyableString(zoomMeetingEvent.meeting_password, 40, false)}<br />
                                </Grid.Column>
                            </Grid>
            }
            else {
              message = <span>Zoom&trade; Meeting Details will be added after you save this Appointment</span>
            }
        } else {
            message = <span>Activate Zoom&trade; Integration in Settings to automatically create a Zoom Meeting.  OfficeMojo will then add the link to join the invitation and reminder emails.</span>
        }
        return <Message>
        <Message.Header>Zoom&trade; Information</Message.Header>
        {message}
      </Message>
    }

    createAnywhereMeeting() {
        this.setState({...this.state,waitingOnAnywhereMeeting:true});
        const request = requestForAnyWhereMeeting(this.state.selectedAppointment.uuid)
            fetch(
                request.url,
                request
                ).then(response => response.json()).then(
                    data => {
                        window.open(data.invitation_url)
                        this.setState({...this.state,waitingOnAnywhereMeeting:false});
                    }
                )
    }

    recordedMeeting() {
        this.setState({...this.state,waitingOnAnywhereMeeting:true});
        this.setState({...this.state,isRecordedClicked:false});
        this.setState({...this.state,noRecordings:false});
        this.setState({...this.state,meetingexpired:false});
        
        const request = requestForRecordURL(this.state.selectedAppointment.uuid)
            fetch(
                request.url,
                request
                ).then(response => response.json()).then(
                    data => {                       
                        
                        if(data.downloadlink === "norecordings")
                        {                            
                            this.setState({...this.state,noRecordings:true});
                        }
                        if(data.downloadlink === "meetingexpired")
                        {                            
                            this.setState({...this.state,meetingexpired:true});
                        }
                        this.setState({...this.state,downloadLink:data.downloadlink});
                        this.setState({...this.state,waitingOnAnywhereMeeting:false});
                        this.setState({...this.state,isRecordedClicked:true});
                    }
                )
    }

    anywhereMeetingInformation() {

        let anywhereMeetingStartDate = moment(this.state.selectedAppointment.start_timestamp).add(-10,"m");
        let anywhereMeetingHasEnded = moment(this.state.selectedAppointment.start_timestamp).add(1,"h").isBefore();
        const anywhereMeeting = this.state.selectedAppointment.location_information;
        const downloadlink = this.state.downloadLink;
        const isRecordedClicked = this.state.isRecordedClicked;
        const noRecordings = this.state.noRecordings;
        const meetingexpired = this.state.meetingexpired;       
        
        let message = null;
        if(!hasRequiredFeature(this.props.features,ANYWHERE_MEETING)) {
            message = <span>Contact Customer Support to activate OfficeMojo VMeet</span> 
        }else if(noRecordings)
        {
            message = <span><br/>This meeting doesn't have recordings</span> 
        }
        else if(meetingexpired)
        {
            message = <span><br/>This meeting has no recordings or the recording access period has expired</span> 
        }
        
        else if(isRecordedClicked)
        { 
            message = <Grid columns='equal'>
            <Grid.Column width={16}>
                                                           
            <List divided relaxed celled>
               {this.state.downloadLink.map(download => {                  
                    return <List.Content>
                    <List.Header>                        
                    </List.Header>
                    <List.Description>
                    {this.truncatedCopyableString(download, 60, true)}
                    </List.Description>
                </List.Content>}
                )}
            </List>
            </Grid.Column>
        </Grid>
        }
        
        else if (anywhereMeetingHasEnded) {            
            message = <Grid columns='equal'>
            <Grid.Column width={40}>
            <span>{<Button size='mini' positive onClick={() =>this.recordedMeeting()} loading={this.state.waitingOnAnywhereMeeting}>View Meeting Recordings</Button>} </span>
           </Grid.Column>
            </Grid>
        }  else {
            if(anywhereMeeting) {
                message = <Grid columns='equal'>
                                <Grid.Column width={16}>
                                    {anywhereMeetingStartDate.isAfter(moment())?`Start meeting here in ${anywhereMeetingStartDate.fromNow()}`:<Button size='mini' positive onClick={() =>this.createAnywhereMeeting()} loading={this.state.waitingOnAnywhereMeeting}>Start Meeting</Button>}<br />
                                                                         
                                <List divided relaxed celled>
                                   {this.state.selectedClients.map(client => {
                                       const meetingToken = (anywhereMeeting.participantIds||[])[client.uuid]
                                        return <List.Content>
                                        <List.Header>
                                            {completeName(client)}
                                        </List.Header>
                                        <List.Description>
                                            {meetingToken?this.truncatedCopyableString(`https://${scope()}.awm.${IS_DEV?'dev.':''}officemojo.io/#${meetingToken}`, 60, true):'tbd'}
                                        </List.Description>
                                    </List.Content>}
                                    )}
                                </List>
                                </Grid.Column>
                            </Grid>

                            
            }
            else if(isRecordedClicked)
            {
              message=<span>{this.truncatedCopyableString(downloadlink, 60, true)} </span>
            }
            else {
              message = <span>OfficeMojo VMeet Details will be added after you save this Appointment</span>
            }
        }
        return <Message>
        <Message.Header>OfficeMojo VMeet Details</Message.Header>
        {message}
      </Message>
    }

    meetingInformation() {


        const meetingInformation = this.state.selectedAppointment.location_information;
        let message = null;
            if(meetingInformation) {

                message = <Grid columns='equal'>
                                <Grid.Column width={16}>
                                <List divided relaxed celled>
                                   {this.state.selectedClients.map(client => {
                                       const clientInformation = (meetingInformation.participantIds||[])[client.uuid]
                                        return <List.Content>
                                        <List.Header>
                                            {completeName(client)}
                                        </List.Header>
                                        <List.Description>
                                            {clientInformation?this.truncatedCopyableString(`https://${scope()}.awm.${IS_DEV?'dev.':''}mojo.accountants/#${clientInformation}`, 60, true):'tbd'}
                                        </List.Description>
                                    </List.Content>}
                                    )}
                                </List>
                                </Grid.Column>
                            </Grid>
            }
            else {
              message = <span>Client Details will be added after you save this Appointment</span>
            }
        return <Message>
        <Message.Header>Client Details</Message.Header>
        {message}
      </Message>
    }

    locationDetailInformation() {

        switch(this.state.selectedAppointment.location) {
            case "OFFICE_IN_PERSON":
                //return this.meetingInformation();
                return null;
            case "PHONE_CLIENT_CALLS":
                //return this.meetingInformation();
                return null;
            case "PHONE_WILL_CALL_CLIENT":
                //return this.meetingInformation();
               return null;
            case "ONLINE_ZOOM":
                    return this.zoomDetailInformation();
            case "ANYWHERE_MEETING":
                    return this.anywhereMeetingInformation();
            default:
                return null;
                //return this.meetingInformation();
        }
    }

   renderLabel(option, index, labelProps ){
       const confirmed = this.state.selectedAppointment.confirmed_clients.indexOf(option.value)!==-1;
        return {color:confirmed?'green':null,content:option.text,icon:confirmed?'checkmark':null}
    };

    reminderCommunicationTypes() {
        let reminderOccasion = this.props.occasions
        .filter(occasion => occasion.name === 'DATE_REMINDER')[0];
       return this.props.communicationTypes.filter(communicationType => {
            
            return reminderOccasion.communication_types.includes(communicationType.name)
       })
    }

    templatesForOccasion() {
       return this.props.communicationMessageTemplates
       .filter(template => template.occasion === 'DATE_REMINDER')
    }

    updateClients(clients) {
        this.update(clients, 'clients')
        this.fetchSelectedClients(clients);
    }

    render () {
        let confirmation = null;
        return (
            <Modal 
                dimmer={this.dimmer}
                closeOnDimmerClick={false}
                open={this.state.selectedAppointment!=null}
                style={modalInlineStyle}
                onClose={() => this.props.close()}
                closeIcon
            >
                  <Modal.Header>{(this.state.selectedAppointment.uuid?'Edit':'Create')+' Appointment'}</Modal.Header>
                  <Dimmer active={this.props.loading}>
                    <Loader />
                </Dimmer>
                  <Modal.Content>
                    {   confirmation&&(
                        <Segment color='green' piled>{`Appointment was confirmed ${moment(confirmation.date).fromNow()} through ${confirmation.communication_type}`}</Segment>
                    )}
                    <Modal.Description>
                    <Form size='tiny'>
                    <Grid>
        <Grid.Column width={6} textAlign="left"> 
        <Form.Group widths='equal'>
        <TextFormField 
            valueKey='title'
            control={Input} 
            label='Title&#42;' 
            object={this.state.selectedAppointment}
            update={(value, key) => this.update(value, key)}            
        />
</Form.Group>

    <Form.Group widths='equal'>
    <Form.Field>
    <label>Start&#42;</label>
    <ClickableDateTimePicker 
    value={moment(this.state.selectedAppointment.start_timestamp, moment.ISO_8601).toDate()}
    onChange={(value) => this.update(moment(value).toISOString(), 'start_timestamp')}
    />
    </Form.Field>
    </Form.Group>
    <Form.Group widths='equal'>
    <Form.Field>
    <label>End&#42;</label>
    <ClickableDateTimePicker
    value={moment(this.state.selectedAppointment.end_timestamp, moment.ISO_8601).toDate()}
    onChange={(value) => this.update(moment(value).toISOString(), 'end_timestamp')}
    />
    </Form.Field>
    </Form.Group>
    <Form.Group widths='equal'>
    <Form.Field>
        <label>Length</label>
        {length(this.state.selectedAppointment)}
        </Form.Field> 
        </Form.Group>
</Grid.Column>
<Grid.Column width={10} textAlign="left">
<Form.Field>
    <label>Client(s)&#42;</label>
<ClientsSelectionContainer 
    availableClients={this.props.availableClients}
    selectedClients={this.state.selectedAppointment.clients} 
    onChange={(clients) => this.updateClients(clients)} 
    renderLabel={(option, index, labelProps ) => this.renderLabel(option, index, labelProps)}
    />
</Form.Field>

<Form.Field>
      <Checkbox 
        label='Notify Client(s)' 
        checked={this.state.selectedAppointment.notify_clients}
        toggle
        onChange={(event, {checked}) => this.update(checked,'notify_clients')}
        />
    </Form.Field>

    <Form.Field
                            control={Select}
                            label='Location&#42;'
                            value={this.state.selectedAppointment.location }
                            options={this.props.dateLocations.map(({uuid,description,name}) => {return {key:uuid,text:description,value:name}})}
                            placeholder='Choose Meeting Location'                            
                            onChange={(event,{value}) => this.update(value, 'location')}
                                                    />
                          {this.locationDetailInformation()}
    <SyncedEventAttendeeDisplay syncedEvent={this.state.selectedAppointment.google_events[0]||this.state.selectedAppointment.microsoft_events[0]} />
<Form.Field>
    <label>Tag(s)</label>
<ErrorBoundary><TagsSelection availableTags={this.props.availableTags} selectedTags={this.state.selectedAppointment.tags} onChange={(tags) => this.update(tags, 'tags')}/></ErrorBoundary>
</Form.Field>

</Grid.Column>
</Grid>
<Tab panes={this.tabPanes()} />
  </Form>
                    </Modal.Description>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button color='grey' onClick={() => this.props.close()}>
                      Cancel
                    </Button>
                    {
                    this.state.selectedAppointment.uuid&&(
                            <Button content="Delete" color='red' icon="trash" labelPosition='right' onClick={() => {if(window.confirm('Do you really want to delete this appointment?'))this.props.deleteAppointment(this.state.selectedAppointment)}} />
                        )
                    } 
                    
                    <Button positive icon='checkmark' labelPosition='right' content="Save" onClick={() => this.saveChanges()} />
                  </Modal.Actions>
                </Modal>
        )
    }

    tabPanes() {
        return [
            { menuItem: 'Notes', render: () => <Tab.Pane>{this.renderNotes()}</Tab.Pane> },
            { menuItem: 'Reminders', render: () => <Tab.Pane>{this.reminderSetup()}</Tab.Pane> },
            
          ]
    }

    renderNotes() {
        return <Grid>
                <Grid.Column>
                <TextFormField 
                    valueKey='notes'
                    control={TextArea} 
                    label='Notes' 
                    object={this.state.selectedAppointment}
                    update={(value, key) => this.update(value, key)}
                />
                </Grid.Column>
        </Grid>
    }
    reminderSetup() {
        return <table width="100%">
        <thead>
          <tr>
            <th>Active</th>
            <th>Lead Time</th>
            <th>Communication Type</th>
            <th>Message Template</th>
            <th>Preview</th>
          </tr>
        </thead>
    
        <tbody>
            {
                ['first_reminder', 'second_reminder', 'third_reminder'].map((reminderKey, index) => {
                    let displayedReminder = this.state[reminderKey]
                    return (
                        <CommunicationSetup 
                            disabled={(reminder) => this.reminderDisabled(reminder)} 
                            showPreview={this.state.selectedAppointment.clients.length>0} 
                            index={index} 
                            reminderOffsets={this.availableReminderOffsets(displayedReminder)} 
                            communicationTypes={this.reminderCommunicationTypes()} 
                            communicationMessageTemplates={this.templatesForOccasion()} 
                            reminder={displayedReminder} 
                            onChange={(reminder) => {let newState = {...this.state}; 
                            newState[reminderKey] = reminder; 
                            this.setState(newState)}} 
                            key={`${reminderKey}_${index}`}
                            date={this.state.selectedAppointment.start_timestamp}
                            receiver={this.state.selectedAppointment.clients[0]}
                            sender={this.props.professional}
                            //previewURL = {BASE_URL+'/prw/'+displayedReminder.communication_message_template+'/emailHTMLMessageTemplate?pl='+encodeURIComponent(JSON.stringify(this.templatePreviewData()))}
                            />  
                           
                    )
                })
            }
    </tbody>
      </table>
    }
}

function length(appt) {

    const length = moment.duration(moment(appt.end_timestamp, moment.ISO_8601).diff(moment(appt.start_timestamp, moment.ISO_8601)));
    if(length.days()>=1) {
        const daysString = length.days()>1?'days':'day';
       return `${length.days()} ${daysString}`;
    }
    if(length.hours()>=1) {
        const hoursString = length.hours()>1?'hours':'hour';
        const minutesString = length.minutes()>1?':'+length.minutes():'';
       return `${length.hours()}${minutesString} ${hoursString}`;
    }
    return `${length.minutes()} minutes`;
}
