export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_FULLFILLED = 'FETCH_CATEGORIES_FULLFILLED';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const SAVE_SMART_TAG = 'SAVE_SMART_TAG';
export const SAVE_SMART_TAG_FULLFILLED = 'SAVE_SMART_TAG_FULLFILLED';
export const FETCH_CLIENTS_OF_CATEGORY = 'FETCH_CLIENTS_OF_CATEGORY';
export const FETCH_CLIENTS_OF_CATEGORY_FULLFILLED = 'FETCH_CLIENTS_OF_CATEGORY_FULLFILLED';
export const FETCH_CLIENTS_FOR_CATEGORY = 'FETCH_CLIENTS_FOR_CATEGORY';
export const FETCH_CLIENTS_FOR_CATEGORY_FULLFILLED = 'FETCH_CLIENTS_FOR_CATEGORY_FULLFILLED'

export function fetchCategories(professional) {
    return {
        type : FETCH_CATEGORIES,
        payload : {
            professional
        }
    }
}

export function fetchCategoriesFullfilled(categories) {
    return {
        type : FETCH_CATEGORIES_FULLFILLED,
        payload : {
            categories
        }
    }
}

export function fetchClientsOfCategoryFullfilled(category, queryResult) {
    return {
        type: FETCH_CLIENTS_OF_CATEGORY_FULLFILLED,
        payload: {
            category,
            queryResult
        }
    }
}

export function fetchClientsOfCategory(professional, category, page=1, length=1) {
    return {
        type : FETCH_CLIENTS_OF_CATEGORY,
        payload : {
            professional,
            category,
            page,
            length
        }
    }
}

export function selectCategory(category) {
    return {
        type : SELECT_CATEGORY,
        payload : {
            category
        }
    }
}



export function saveSmartTag(smartTag) {
    return {
        type : SAVE_SMART_TAG,
        payload : {
            smartTag
        }
    }
}

export function saveSmartTagFullfilled(smartTag) {
    return {
        type : SAVE_SMART_TAG_FULLFILLED,
        payload : {
            smartTag
        }
    }
}

export function fetchClientsForCategory(professional, category, page, length) {
    return {
        type : FETCH_CLIENTS_FOR_CATEGORY,
        payload : {
            professional,
            category,
            page,
            length,
        }
    }
}

export function fetchClientsForCategoryFullfilled(category) {
    return {
        type : FETCH_CLIENTS_FOR_CATEGORY_FULLFILLED,
        payload : {
            category
        }
    }
}