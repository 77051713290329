import React from 'react'
import { Header, Popup, Icon, Divider, Form, Tab, Grid, Input, Button } from 'semantic-ui-react'
import { TextFormField } from 'widgets/FormWidgets';
import PhoneNumberSection from 'widgets/PhoneNumberSection';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import validateEmail from 'utils/emailValidation';
import { scorePassword } from 'utils/passwordUtils';

export const profileTab = (user, professional, onChange, onSave) => {
    let passwordLabel = <label>New Password{user.password&&scorePassword(user.password)<64?<span>{' - insuffcient complexity'} <Popup trigger={<Icon name="help circle" />} content='A password should consist of characters, numbers and special characters (!@$%^&amp;*()' /></span>:''}</label>
    let passwordVerificationFailed = (user.password)
    &&((user.password!==user.verify_password)
    ||user.password.length<8);
    return  (
        <Tab.Pane>
            <Form>
            <Header as='h3'>
                User Profile
            </Header>
                <Grid>
                    <Grid.Column width={8} textAlign="left"> 
                        <TextFormField 
                            placeholder='First Name' 
                            label='First Name' 
                            valueKey='given_name' 
                            object={professional}                 
                            update={(value, key) => onChange('professional',value,key)}
                            control={Input}
                        />
                        <TextFormField 
                            placeholder='Last Name' 
                            label='Last Name' 
                            valueKey='family_name' 
                            object={professional}                 
                            update={(value, key) => onChange('professional',value,key)}
                            control={Input}
                        />
                        <TextFormField 
                            error = {professional.email&&!validateEmail(professional.email)}
                            placeholder='Email' 
                            label='Email' 
                            valueKey='email' 
                            object={professional}                 
                            update={(value, key) => onChange('professional',value,key)}
                            control={Input}
                        />           
                    </Grid.Column>
                    <Grid.Column width={8} textAlign="right"> 
                        <PhoneNumberSection 
                            phones={professional.professional_phones} 
                            phoneProto={{}} 
                            phoneKey='professional_phones'
                            update={(value, key) => onChange('professional',value,key)}
                        />
                    </Grid.Column>
                </Grid>
                <Divider horizontal as='h3'>Update Password</Divider>
                <Form.Group widths='equal'>
                    <TextFormField 
                        placeholder='Username' 
                        label='Username (min 8 chars)' 
                        valueKey='user_name' 
                        object={user}                 
                        update={(value, key) => onChange('user',value,key)}
                        disabled
                        control={Input}
                    />  
                    <TextFormField 
                        placeholder='New Password' 
                        autoComplete='new-password'
                        label={passwordLabel} 
                        valueKey='password' 
                        object={user}  
                        error={user.password&&scorePassword(user.password) < 64}               
                        update={(value, key) => onChange('user',value,key)}
                        type='password'
                        control={Input}
                    />  
                    <TextFormField 
                        placeholder='Verify New Password' 
                        autoComplete='new-password'
                        label='Verify New Password' 
                        valueKey='verify_password' 
                        object={user}                 
                        update={(value, key) => onChange('user',value,key)}
                        type='password'
                        control={Input}
                        error = {passwordVerificationFailed}
                        errorMessage={passwordVerificationFailed?"Passwords don't mach":null}  
                    />  
                </Form.Group>
                <Button type='submit' color="green" onClick={() => onSave()}>Save</Button>
            </Form>
        </Tab.Pane>
    ) 
}