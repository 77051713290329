//@flow

import { BASE_URL } from 'App';



export function composePreviewURL(receiver?:string, sender:string, date?:string, template:string, communicationType:string, templatePersonalization?:string):string {
    const payload = {
        date,
        receiver,
        sender,
        templatePersonalization
        };

/*    let field;
     switch(communicationType) {
        case EMAIL:
            field = 'emailHTMLMessageTemplate';
            break;
        case SMS:
            field = 'smsMessageTemplate';
            break;
        case CALL:
            field = 'callMessageTemplate';
            break;
        case POSTCARD:
            field = 'postcardMessageTemplate';
            break;
        default:
            field = 'emailHTMLMessageTemplate';
    } */
    return `${BASE_URL}/prw/${template}/${communicationType}?pl=${encodeURIComponent(JSON.stringify(payload))}`;
}