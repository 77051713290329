import {connect} from 'react-redux'
import { ChangePassword } from 'components/ChangePassword/ChangePassword';
import { resetPassword } from 'actions/PasswordActions';


const mapStateToProps = (state, ownProps) => {
    return {
        loading:state.password.loading,
        change_completed:state.password.completed&&!(state.session.errors&&state.session.errors[0]),
        failed:state.password.failed,
        scope:state.session.scope
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        resetPassword: (token,newPassword) => dispatch(resetPassword(token,newPassword))
    }
}

export const ChangePasswordContainer  = connect(mapStateToProps, mapDispatchToProps)(ChangePassword);