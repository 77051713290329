//@flow

type ToStringArray = {toString:() => string};

export function arrayToSentence(array:Array<ToStringArray>, itemCount:number, key: ?string | Function) : string {
    if (itemCount === 0) {
        return '' + array.length;
    }
    let output:string = '';
    if(!itemCount || array.length < itemCount) {
        itemCount = array.length;
    }
    for(let i:number = 0; i < itemCount; i++) {
        let value = '';
        if(key) {
            if(typeof key === "function") {
                let calValue = key(array[i], i, array);
                
                value = calValue.toString();
            } else {
                if (array[i][key]) {
                    value = array[i][key].toString();
                }
            }
        } else {
            value = array[i];
        }
        if(value) {
            output += value.toString();
        }
        if (i + 1 < itemCount) {
            if (i + 2 === array.length) {
                output += ' and '
            } else {
                output += ', '
            }
        }
    }
    if (itemCount === array.length) {
        return output;
    } else {
        let othersCount = (array.length - itemCount);
        return output + ' and ' + othersCount + ' other' + (othersCount > 1? 's': '');
    }
}