import React, {Component} from 'react'
import { connect } from 'react-redux';
import { reloadIntegrationSettings } from '../../actions/IntegrationSettingsAction';
import { Icon, Popup, Menu } from 'semantic-ui-react';
import moment from 'moment';

class IntegrationStatus extends Component {

    componentDidMount() {
        this.props.reloadIntegrationSettings(this.props.professionalId);
        this.integrationSettingInverval = setInterval(() => this.props.reloadIntegrationSettings(this.props.professionalId), 2*60*1000);
        /* import('components/Tour/Welcome')
          .import { TourCard } from 'components/Tour/TourCard';
    then(({import { ChangePassword } from 'componentsChangePassword/ChangePassword';
     welcomimport { ChangePasswordContainer } from 'containers/ChangePasswordContainer/ChangePasswordContainer';
    eTour: import { reloadSyncSettings } from '../../actions/SettingsActions';
    welcomeimport { reloadSyncSettings } from '../../actions/SyncSettingsAction';
    TourSteimport { Icon } from 'semantic-ui-react';
ps }) => {
          this.setState({
            ...this.state,
            steps: welcomeTourSteps({
              _handleClickNextButton: () => this._handleClickNextButton(),
              _handleClickPreviousButton: () => this._handleClickPreviousButton(),
            }),
            run: true,
          })
        }) */
    
      }
    
      componentWillUnmount() {
        clearInterval(this.integrationSettingInverval);
      }

    render() {
        const {microsoftSyncSetting, googleSyncSetting, zoomSetting} = this.props;
        const syncSetting = microsoftSyncSetting || googleSyncSetting;
        const syncServiceName = syncSetting===microsoftSyncSetting?'Microsoft/Outlook':'Google';
        const syncingSuspended = (!syncSetting || syncSetting.status !== 'ACTIVE');
        const activeZoomSetting = zoomSetting&&zoomSetting.status === 'ACTIVE'
        const children = [];        
        if(syncSetting) {
            if(syncingSuspended) {
                children.push(<Menu.Item key={syncSetting.uuid} >
                    <Popup basic flowing header = {syncServiceName + ' Sync'} content={`currently suspended.  Last sync completed ${moment(syncSetting.last_successful_usage).fromNow()}. Reactivate in Settings.`} trigger={
                            <Icon.Group size='large'>
                                <Icon size='small' color='green' name="sync"/>
                                <Icon size='large' color='red' name='dont' />
                            </Icon.Group>}
                            /></Menu.Item>
                            );
            } else {
            children.push(<Menu.Item  key={syncSetting.uuid}><Popup basic flowing header={syncServiceName + ' Sync'} content={`Syncing is ACTIVE. Last sync completed ${moment(syncSetting.last_successful_usage).fromNow()}.`} trigger={<Icon size='small' name="sync" loading={true} color='green' />} /></Menu.Item>);
            }
        }
        return <React.Fragment>
            
            {children}
            
            </React.Fragment>
    }
}



function mapStateToProps(state) {
    return {
        professionalId: state.session.professional, 
        microsoftSyncSetting: state.integrationSettings.microsoftSyncSetting,
        googleSyncSetting: state.integrationSettings.googleSyncSetting,
        zoomSetting: state.integrationSettings.zoomSetting
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        reloadIntegrationSettings:(professionalId) => {
            if(professionalId) {
                dispatch(reloadIntegrationSettings(professionalId))
            } else {
                console.log('no professional ID')
            }
        }
    }

}

const IntegrationStatusContainer = connect(mapStateToProps, mapDispatchToProps)(IntegrationStatus)

export default IntegrationStatusContainer;