import React from 'react'
import { Header, Form, Tab, Button, Dropdown } from 'semantic-ui-react'
import {IS_DEV} from '../../App'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { OPENING_TIME_OPTIONS, WEEKDAYS, APPT_LENGTH_OPTIONS } from 'constants/BookingOptions';

export const bookingTab = (professional,onChange,onSave, bookingAPIKey) => {
  return (
    <Tab.Pane>
      <Form>
          <Header as='h3'>Online Booking Settings </Header>
          <Form.Group widths="equal">
          
          <Form.Field>
              <label>Open</label>
              <Dropdown options={OPENING_TIME_OPTIONS}
              value={professional.online_booking_start_time}
              selection
              onChange={(event,{value}) => onChange('professional', value, 'online_booking_start_time')}
              />
          </Form.Field>
          <Form.Field>
              <label>Close</label>
              <Dropdown options={OPENING_TIME_OPTIONS}
              value={professional.online_booking_end_time}
              selection
              onChange={(event,{value}) => onChange('professional', value, 'online_booking_end_time')}
              />
          </Form.Field>
          </Form.Group>
          <Form.Field>
              <label>Days Open</label>
              <Dropdown options={WEEKDAYS}
              value={professional.online_booking_weekdays.split('')}
              selection
              multiple
              onChange={(event,{value}) => onChange('professional', value.sort().join(''), 'online_booking_weekdays')}
              />
          </Form.Field>
          <Form.Field>
              <label>Appointment Length</label>
              <Dropdown options={APPT_LENGTH_OPTIONS}
              value={professional.online_booking_date_length}
              selection
              onChange={(event,{value}) => onChange('professional', value, 'online_booking_date_length')}
              />
          </Form.Field>
          
          <Button color='white' icon='eye' onClick={() => window.open(`https://assets.${IS_DEV?'dev.':''}mojo.accountants/shared/onlinebooking/widget/v3/index.html#${bookingAPIKey.api_key_string}`,'Online Booking Widget')}/><Button type='submit' color="green" onClick={() => onSave()}>Save</Button>
      </Form>
    </Tab.Pane>
  )
}