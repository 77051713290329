export const FETCH_ALL_CAMPAIGNS = "FETCH_ALL_CAMPAIGNS"
export const FETCH_ALL_CAMPAIGNS_FULLFILLED = "FETCH_ALL_CAMPAIGNS_FULLFILLED"
export const SELECT_CAMPAIGN = "SELECT_CAMPAIGN"
export const SELECT_CAMPAIGN_FULLFILLED = "SELECT_CAMPAIGN_FULLFILLED"
export const SAVE_SELECTED_CAMPAIGN = "SAVE_SELECTED_CAMPAIGN"
export const SAVE_SELECTED_CAMPAIGN_FULLFILLED = "SAVE_SELECTED_CAMPAIGN_FULLFILLED"
export const DELETE_SELECTED_CAMPAIGN = "DELETE_SELECTED_CAMPAIGN"
export const DELETE_SELECTED_CAMPAIGN_FULLFILLED = "DELETE_SELECTED_CAMPAIGN_FULLFILLED"


export function fetchAllCampaigns(professional) {
    return {
        type:FETCH_ALL_CAMPAIGNS,
        payload:{professional}
    }
}

export function fetchAllCampaignsFullfilled(campaigns, tags, categories, holiday_records, template) {
    return {
        type:FETCH_ALL_CAMPAIGNS_FULLFILLED,
        payload:{campaigns,
            tags,
            categories,
            holiday_records,
            template
            }
    }
}

export function selectCampaign(selectedCampaign) {
    return {
        type:SELECT_CAMPAIGN,
        payload:{selectedCampaign}
    }
}

export function saveSelectedCampaign(selectedCampaign) {
    return {
        type:SAVE_SELECTED_CAMPAIGN,
        payload:{selectedCampaign}
    }
}

export function saveSelectedCampaignFullfilled(selectedCampaign) {
    return {
        type:SAVE_SELECTED_CAMPAIGN_FULLFILLED,
        payload:{selectedCampaign}
    }
}

export function deleteSelectedCampaign(deletedCampaign) {
    return {
        type:DELETE_SELECTED_CAMPAIGN,
        payload:{deletedCampaign}
    }
}

export function deleteSelectedCampaignFullfilled(deletedCampaign) {
    return {
        type:DELETE_SELECTED_CAMPAIGN_FULLFILLED,
        payload:{deletedCampaign}
    }
}