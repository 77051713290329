// @flow
import {Observable} from 'rxjs'
import { FETCH_TAGS, fetchTagsFullfilled, fetchSelectedTagFullfilled, saveTagFullfilled, deleteTagFullfilled} from 'actions/TagActions';
import { SAVE_TAG } from 'actions/TagActions';
import { DELETE_TAG } from 'actions/TagActions';
import { requestForEndpointAndPayload } from 'App';
import { FETCH_SELECTED_TAG } from 'actions/TagActions';
import { ConfirmationStatus } from 'constants/ConfirmationStatus';
import moment from 'moment'
import _ from 'lodash';
import { completeName } from 'utils/clientUtils';
import { setCommunicationMessageTemplates, setCommunicationTypes, setOccasions } from 'actions/MetaDataActions';
import { setReminderOffsets } from 'actions/MetaDataActions';
import { handleAjaxError } from './sessionEpic';

export function fetchTagsEpic(action$) {
  
    return action$.ofType(FETCH_TAGS)
    .switchMap(({payload}) => {
        return Observable.ajax(requestForEndpointAndPayload('query',{
            tags:  [
                        {
                            and : [["professional","=",payload.professional]]
                        }
                    ],
            tag_properties:['color','name','description','uuid','clients', 'dates']
        })
            )
        .map(result => {

            return fetchTagsFullfilled(result.response);
        }).catch((e) => Observable.concat([handleAjaxError(e), fetchTagsFullfilled({tags:[]})]))
    });
}

export function fetchSelectedTagEpic(action$, store) {
    return action$.ofType(FETCH_SELECTED_TAG)
    .switchMap(({payload}) => {
        return Observable.ajax(requestForEndpointAndPayload('get',{
            tag_ids:  [payload.selectedTag.uuid],
            communication_message_template_ids:"*",
            communication_type_ids:"*",
            reminder_offset_ids:"*",
            occasion_ids:'*'          
        }))
        .switchMap(result => {
            store.dispatch(setCommunicationMessageTemplates(result.response.communication_message_templates));
            store.dispatch(setReminderOffsets(result.response.reminder_offsets));
            store.dispatch(setCommunicationTypes(result.response.communication_types));
            store.dispatch(setOccasions(result.response.occasions));
          return Observable.of(result.response.tags[0])
        })
        .switchMap(tag => appendClientProxyObservable(tag))
        .map(tag => {
            return fetchSelectedTagFullfilled(tag);
})
    }).catch((e) => Observable.concat([handleAjaxError(e),fetchSelectedTagFullfilled(null)]));
    
}

export function saveTagEpic(action$,store) {
    return action$.ofType(SAVE_TAG)
    .switchMap(({payload}) => {
        let {name, 
            color, 
            description, 
            uuid, 
            professional, 
            communication_type, 
            communication_message_template,
            status,
            personal_note,
            personal_note_email_subject,
            send_to_date_clients,
            send_to_clients
        } = payload.tag;
        return Observable.ajax(requestForEndpointAndPayload('put',{
            tags:  [{name, 
                color, 
                description, 
                uuid, 
                professional, 
                communication_type, 
                communication_message_template,
                status,
                personal_note,
                personal_note_email_subject,
                send_to_date_clients,
                send_to_clients}]
        }))
        .switchMap(result => {
            const savedTag = result.response.tags[0];
            return appendClientProxyObservable(savedTag);
        })
        .map(savedTag => {
            return saveTagFullfilled(savedTag);
        }).catch((e) => {
            console.log("error from server", e)
            return Observable.concat([handleAjaxError(e),saveTagFullfilled(null)]);
            //return Observable.of(setSaveTagErrors(e.response.details))
        })
    })
}

export function deleteTagEpic(action$) {
    return action$.ofType(DELETE_TAG)
    .switchMap(({payload}) => {
        return Observable.ajax(requestForEndpointAndPayload('delete',{
            tag_ids:  [payload.tag.uuid]
        }))
        .map(result => {
            return deleteTagFullfilled(result.response.tag_ids[0]);
        }).catch((e) => {
            console.log("error from server", e)
            return Observable.concat([handleAjaxError(e),deleteTagFullfilled([])]);
            //return Observable.of(setSaveTagErrors(e.response.details))
        })
    })
}


const appendClientProxyObservable = tag => {

    return Observable.ajax(requestForEndpointAndPayload('get',{
    client_ids: tag.clients,
    client_properties: ['given_name','family_name','email', 'company_name', 'uuid','client_type','dates'],
    date_ids: tag.dates,
    date_properties: ['start_timestamp','title','clients','confirmed_clients'],
}))
.flatMap(result => {
    const clients = result.response.clients.map(client => {
        return {
        ...client,
        complete_name: completeName(client)
    }
    })
    return Observable.of({
        ...tag,
        clients,
        dates:result.response.dates,
    });
})
.switchMap(_tag => {
    let clientIds = [];
    _tag.dates.forEach(date =>
        clientIds = clientIds.concat(date.clients)    
    )

    let dateIds = [];
    _tag.clients.forEach(client =>
        dateIds = dateIds.concat(client.dates)    
    )
   return Observable.ajax(requestForEndpointAndPayload('get',{
        client_ids: clientIds,
        client_properties: ['given_name', 'family_name', 'company_name', 'uuid','client_type'],
        date_ids: dateIds,
        date_properties: ['start_timestamp', 'uuid'],
    }))
    
    .switchMap(result => {
        const clientsInDates = result.response.clients;
        const datesInClients = result.response.dates;
        let expDates = _tag.dates.map(date => {
            let clients = clientsInDates.filter(cid => date.clients.indexOf(cid.uuid)!==-1)
            return {...date, 
                clients,
                status : date.confirmed_clients.length>0
                ?ConfirmationStatus.CONFIRMED
                :moment(date.start_timestamp).isAfter()?ConfirmationStatus.PENDING:ConfirmationStatus.EXPIRED,
                client_names : _.join(clients.map(client => completeName(client)), ', ')
            }
        })
        let expClients = _tag.clients.map(client => {
            let dates = datesInClients.filter(dic => client.dates.indexOf(dic.uuid)!==-1);
            let newest_date = dates.sort((a, b) => moment(b.start_timestamp).valueOf() - moment(a.start_timestamp).valueOf())[0];
            return {...client, 
                dates,
                newest_date
            }
        })
        return Observable.of({..._tag,dates:expDates, clients:expClients})
    })})
}