import React, { Component } from 'react';
import { connect } from 'react-redux';
import {ClientDetail} from 'components/ClientDetail/ClientDetail'

function mapStateToProps(state) {
    return {

    };
}

const ClientDetailContainer = connect(mapStateToProps)(
    class ClientDetailContainer extends Component {
        render() {
            return (
                <ClientDetail />
                );
        }
    }
);

export {ClientDetailContainer};