import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { ConfirmationStatus } from 'constants/ConfirmationStatus';
import _ from 'lodash'

export function ConfirmationStatusIcon(props) {
    let icon;
    let color;
    switch (props.status) {
        case ConfirmationStatus.CONFIRMED:
            color = 'green';
            icon = 'calendar check'; 
            break;
        case ConfirmationStatus.Pending:
            color = 'yellow';
            icon = 'calendar outline';
            break; 
        case ConfirmationStatus.EXPIRED:
            color = 'grey';
            icon = 'calendar outline';
            break;    
        case ConfirmationStatus.REJECTED:
            color = 'red';
            icon = 'calendar delete';
            break;   
        default:
            color = 'yellow';
            icon = 'calendar outline';
            break;
    }

    return   <Popup
    trigger={<Icon name={icon} color={color} inverted circular fitted />}
    content={_.startCase(props.status.toLowerCase())}
  />
}