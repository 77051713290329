export function arrayToSentence(array, itemCount, key) {
    if (itemCount === 0) {
        return '' + array.length;
    }
    var output = '';
    if(!itemCount || array.length < itemCount) {
        itemCount = array.length;
    }
    for(var i = 0; i < itemCount; i++) {
        var value = '';
        if(key) {
            if(typeof key === "function") {
                var calValue = key(array[i], i, array);
                
                value = calValue.toString();
            } else {
                if (array[i][key]) {
                    value = array[i][key].toString();
                }
            }
        } else {
            value = array[i];
        }
        if(value) {
            output += value.toString();
        }
        if (i + 1 < itemCount) {
            if (i + 2 === array.length) {
                output += ' and '
            } else {
                output += ', '
            }
        }
    }
    if (itemCount === array.length) {
        return output;
    } else {
        let othersCount = (array.length - itemCount);
        return output + ' and ' + othersCount + ' other' + (othersCount > 1? 's': '');
    }
}