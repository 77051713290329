

export function urlParams() {
    if(!window || !window.location) {
        return {};
    }
    const search = window.location.search.substring(1);
    if(!search || search === '') {
        return {}
    }
    return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
}