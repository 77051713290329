
import { FETCH_SETTINGS, FETCH_SETTINGS_FULLFILLED, SAVE_SETTINGS, SAVE_SETTINGS_FULLFILLED, IMPORT_CLIENTS, IMPORT_CLIENTS_FULLFILLED, RELOAD_SYNC_SETTINGS_FULLFILLED } from 'actions/SettingsActions';
import { TOGGLE_VISIBILITY_OF_MICROSOFT_CALENDAR, TOGGLE_VISIBILITY_OF_MICROSOFT_CALENDAR_FULLFILLED, TOGGLE_VISIBILITY_OF_GOOGLE_CALENDAR, TOGGLE_VISIBILITY_OF_GOOGLE_CALENDAR_FULLFILLED } from '../actions/SettingsActions';


export const SettingsReducer = (state = {offices:[], companies:[], professionals:[], users:[]}, action) => {

    switch(action.type) {
        case SAVE_SETTINGS:
        case FETCH_SETTINGS:
        return { ...state,
            loading: true};
        case FETCH_SETTINGS_FULLFILLED:
            return { ...state,
                ...action.payload,
                loading: false};
        case SAVE_SETTINGS_FULLFILLED:
            return { ...state,
                ...action.payload,
                loading:false};
        case IMPORT_CLIENTS:
            return {...state,
                uploadingImport:true
            }
        case IMPORT_CLIENTS_FULLFILLED:
            return {...state,
                uploadingImport:false,
                data_imports:action.payload
            }
        case TOGGLE_VISIBILITY_OF_MICROSOFT_CALENDAR:
            return state;
        case TOGGLE_VISIBILITY_OF_MICROSOFT_CALENDAR_FULLFILLED:
            return state;
        case TOGGLE_VISIBILITY_OF_GOOGLE_CALENDAR:
                return state;
        case TOGGLE_VISIBILITY_OF_GOOGLE_CALENDAR_FULLFILLED:
                return state;
        case RELOAD_SYNC_SETTINGS_FULLFILLED:
            return {...state,
                google_sync_settings:action.payload.google_sync_settings,
                google_calendar_syncs:action.payload.google_calendar_syncs,
                microsoft_sync_settings:action.payload.microsoft_sync_settings,
                microsoft_calendar_syncs:action.payload.microsoft_calendar_syncs,
                zoom_settings:action.payload.zoom_settings
            }
        default:
            return state;
        }
}