export const SETUP = 'SETUP';
export const SETUP_FULLFILLED = 'SETUP_FULLFILLED';
export const SETUP_FAILED = 'SETUP_FAILED';
export const VALIDATE_SCOPE = 'VALIDATE_SCOPE';
export const VALIDATE_SCOPE_FULLFILLED = 'VALIDATE_SCOPE_FULLFILLED';

export function setup(company_name, user_name, first_name, last_name, email, password, scope) {
    return {
        type : SETUP,
        payload : {
            admin_user:{username:user_name, password},
            companies:[{name:company_name,scope}],
            offices:[{
                    name:`${company_name} office`,
                    professionals:[{
                        given_name:first_name,
                        family_name:last_name,
                        email}]
                    }]
        }
    }
}

export function setupFullfilled() {
    return {
        type: SETUP_FULLFILLED
    }
}

export function setupFailed() {
    return {
        type: SETUP_FAILED
    }
}

export function validateScope(scope) {
    return {
        type: VALIDATE_SCOPE,
        payload: {scope}
    }
}

export function validateScopeFullfilled(scope) {
    return {
        type: VALIDATE_SCOPE_FULLFILLED,
        payload: {scope}
    }
}

/*{
	"admin_user":{"user_name":"keith","password":"7Ghe8iMpW"},
	"company":{"name":"Prosites","scope":"prosites"},
	"offices":[{
				"name":"Office in Temecula",
				"professionals":[{
								"given_name":"Keith",
								"athena_users":[],
								"tags":[
										{"color":"orange",
										"name":"401k"},
										{"color":"blue",
										"name":"tax day"}
									],
								"clients":[]
							}]
			}]
}
*/

