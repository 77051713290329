import React from 'react'
import { PhoneFormField } from './FormWidgets';
import { Form, Input, Message } from 'semantic-ui-react';
import _ from 'lodash'
import { formatPhonenumber, parsePhonenumber } from 'utils/phonenumberFormat';

const PhoneNumberSection = (props) => {

    const phoneForType = (type) => {
       let phoneWithType = props.phones.filter(phone => phone.type === type)[0];
       if (!phoneWithType) {
           phoneWithType = {...props.phoneProto, type}
       }
       return phoneWithType;
    }

    const phoneNumberForType = (type) => {
        let phone = phoneForType(type);
        return phone?formatPhonenumber(`${_.defaultTo(phone.area_code,'')}${_.defaultTo(phone.phone_number,'')}${_.defaultTo(phone.extension,'')}`):'';
    }

    const updatePhoneOfType = (value, type) => {
        
        let phone = phoneForType(type);
        
        phone = updatePhone(phone, parsePhonenumber(value));
        let phones = props.phones.filter(aPhone => aPhone.type !== type).concat(phone);
        props.update(phones, props.phoneKey)
    }
    const optedOut = phoneForType('Mobile')&&phoneForType('Mobile').uuid&&!phoneForType('Mobile').can_sms;
    return (
        
    <div>
    {optedOut&&<Message style={{display:'block'}}
      error
      header='Opted Out'
      content={<span>This client chose not to receive text messages by sending "STOP".  Receiving text messages can be resumed by sending "YES" or "START" to <b><u>{formatPhonenumber(props.smsCallerId)}</u></b></span>}
    />}
    <Form.Group widths='equal'>

    <PhoneFormField 
            valueKey='mobile_phone'
            control={Input} 
            label='Mobile Phone' 
            number={phoneNumberForType('Mobile')}
            update={(value, key) => updatePhoneOfType(value, 'Mobile')}
        />
    </Form.Group>
    <Form.Group widths='equal'>
        <PhoneFormField 
            valueKey='home_phone'
            control={Input} 
            label='Home Phone' 
            number={phoneNumberForType('Home')}
            update={(value, key) => updatePhoneOfType(value, 'Home')}
        />
    </Form.Group>
    <Form.Group widths='equal'>
        <PhoneFormField 
            valueKey='work_phone'
            control={Input} 
            label='Work Phone' 
            number={phoneNumberForType('Work')}
            update={(value, key) => updatePhoneOfType(value, 'Work')}
        />
    </Form.Group>
    <Form.Group widths='equal'>
        <PhoneFormField 
            valueKey='other_phone'
            control={Input} 
            label='Other Phone' 
            number={phoneNumberForType('Other')}
            update={(value, key) => updatePhoneOfType(value, 'Other')}
        />
    </Form.Group></div>)
}

export function updatePhone(phone, phoneNumber) {
    const _phone = {...phone}
    _phone.area_code = phoneNumber.substr(0,3);
    if(phoneNumber.length>3) {
        _phone.phone_number = phoneNumber.substr(3,7);
    } else {
        _phone.phone_number = ''
    }
    if(phoneNumber.length>10) {
        _phone.extension = phoneNumber.substr(10);
    } else {
        _phone.extension = ''
    }
    return _phone;
}


export default PhoneNumberSection;