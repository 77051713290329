// @flow
export const FETCH_SELECT_APPOINTMENT:string = "FETCH_SELECT_APPOINTMENT";
export const FETCH_APPOINTMENTS:string = "FETCH_APPOINTMENTS";
export const FETCH_APPOINTMENTS_FULLFILLED:string = "FETCH_APPOINTMENTS_FULLFILLED"; 
export const SAVE_APPOINTMENT:string = 'SAVE_APPOINTMENT';
export const SAVE_APPOINTMENT_FULLFILLED:string = 'SAVE_APPOINTMENT_FULLFILLED';
export const SAVE_APPOINTMENT_REQUEST:string = 'SAVE_APPOINTMENT_REQUEST';
export const SAVE_APPOINTMENT_REQUEST_FULLFILLED:string = 'SAVE_APPOINTMENT_REQUEST_FULLFILLED';
export const INSERT_NEW_APPOINTMENT:string = 'INSERT_NEW_APPOINTMENT';
export const INSERT_NEW_APPOINTMENT_FULLFILLED:string = 'INSERT_NEW_APPOINTMENT_FULLFILLED';
export const DELETE_APPOINTMENT:string = 'DELETE_APPOINTMENT';
export const DELETE_APPOINTMENT_FULLFILLED:string = 'DELETE_APPOINTMENT_FULLFILLED';
export const FETCH_REMINDERS:string = 'FETCH_REMINDERS';
export const FETCH_REMINDERS_FULLFILLED:string = 'FETCH_REMINDERS_FULLFILLED';
export const SELECT_APPOINTMENT:string = 'SELECT_APPOINTMENT';
export const SELECT_APPOINTMENT_FULLFILLED:string = 'SELECT_APPOINTMENT_FULLFILLED';
export const CLEAR_SELECTED_APPOINTMENT:string = 'CLEAR_SELECTED_APPOINTMENT';
export const SET_COMMUNICATION_PREFERENCES:string = 'SET_COMMUNICATION_PREFERENCES';
export const FETCH_APPOINTMENT_REQUESTS:string = 'FETCH_APPOINTMENT_REQUESTS';
export const FETCH_APPOINTMENT_REQUESTS_FULLFILLED:string = 'FETCH_APPOINTMENT_REQUESTS_FULLFILLED';
export const FETCH_GOOGLE_EVENTS:string = 'FETCH_GOOGLE_EVENTS';
export const FETCH_GOOGLE_EVENTS_FULLFILLED:string = 'FETCH_GOOGLE_EVENTS_FULLFILLED';
export const FETCH_MICROSOFT_EVENTS:string = 'FETCH_MICROSOFT_EVENTS';
export const FETCH_MICROSOFT_EVENTS_FULLFILLED:string = 'FETCH_MICROSOFT_EVENTS_FULLFILLED';
export const FETCH_HOLIDAY_EVENTS:string = 'FETCH_HOLIDAY_EVENTS';
export const FETCH_HOLIDAY_EVENTS_FULLFILLED:string = 'FETCH_HOLIDAY_EVENTS_FULLFILLED';
export const FETCH_BIRTHDAYS:string = 'FETCH_BIRTHDAYS';
export const FETCH_BIRTHDAYS_FULLFILLED:string = 'FETCH_BIRTHDAYS_FULLFILLED';
export const SELECT_APPOINTMENT_REQUEST:string = 'SELECT_APPOINTMENT_REQUEST';
export const REJECT_APPOINTMENT_REQUEST:string = 'REJECT_APPOINTMENT_REQUEST';
export const REJECT_APPOINTMENT_REQUEST_FULLFILLED:string = 'REJECT_APPOINTMENT_REQUEST_FULLFILLED';
export const CLEAR_SELECTED_APPOINTMENT_REQUEST:string = 'CLEAR_SELECTED_APPOINTMENT_REQUEST';
export const APPEND_APPOINTMENT_TO_APPT_REQUEST:string = 'APPEND_APPOINTMENT_TO_APPT_REQUEST';
export const APPEND_APPOINTMENT_TO_APPT_REQUEST_FULLFILLED:string = 'APPEND_APPOINTMENT_TO_APPT_REQUEST_FULLFILLED';

const fetchAppointments = (date, view, professional, tags=[]) =>  { 
    return {
        type:FETCH_APPOINTMENTS,
        payload: {
            professional,
            date,
            view,
            tags}
    }
}

const fetchAppointmentRequests = (date, view, professional, selectedAppointmentRequest) => {
    return {
        type:FETCH_APPOINTMENT_REQUESTS,
        payload: {
            professional,
            selectedAppointmentRequest,
            date,
            view}
    }
}

const fetchHolidayEvents = (date, view) => {
    return {
        type:FETCH_HOLIDAY_EVENTS,
        payload: {
            date,
            view}
    }
}

const fetchHolidayEventsFullfilled = (payload) =>  { 
    return {
        type:FETCH_HOLIDAY_EVENTS_FULLFILLED,
        payload
    }
}

const fetchBirthdays = (date, view, professional) => {
    return {
        type:FETCH_BIRTHDAYS,
        payload: {
            date,
            view,
            professional
        }
    }
}

const fetchBirthdaysFullfilled = (payload) =>  { 
    return {
        type:FETCH_BIRTHDAYS_FULLFILLED,
        payload
    }
}

const setCommunicationPreferences = (communicationPreferences) =>  { 
    return {
        type:SET_COMMUNICATION_PREFERENCES,
        payload: communicationPreferences
    }
}

const fetchSelectAppointment = ({uuid}:string) =>  { 
    return {
        type:FETCH_SELECT_APPOINTMENT,
        payload: uuid
    }
}


const fetchAppointmentsFullfilled = (dates) =>  { 
    return {
        type:FETCH_APPOINTMENTS_FULLFILLED,
        payload: dates
    }
}

const fetchAppointmentRequestsFullfilled = (payload) =>  { 
    return {
        type:FETCH_APPOINTMENT_REQUESTS_FULLFILLED,
        payload
    }
}

const fetchGoogleEvents = (date, view, professional) => {
    return {
        type:FETCH_GOOGLE_EVENTS,
        payload: {
            professional,
            date,
            view}
    }
}

const fetchGoogleEventsFullfilled = (payload) =>  { 
    return {
        type:FETCH_GOOGLE_EVENTS_FULLFILLED,
        payload
    }
}

const fetchMicrosoftEvents = (date, view, professional) => {
    return {
        type:FETCH_MICROSOFT_EVENTS,
        payload: {
            professional,
            date,
            view}
    }
}

const fetchMicrosoftEventsFullfilled = (payload) =>  { 
    return {
        type:FETCH_MICROSOFT_EVENTS_FULLFILLED,
        payload
    }
}

const fetchReminders = (appointmentId) => {
    return {
        type:FETCH_REMINDERS,
        payload:appointmentId
    }
}

const fetchRemindersFullfilled = (reminders) => {
    return {
        type:FETCH_REMINDERS_FULLFILLED,
        payload:reminders
    }
}

const selectAppointment = (selectedAppointment) => {
    return {
        type:SELECT_APPOINTMENT,
        payload:selectedAppointment
    }
}

const selectAppointmentFullfilled = (selectedAppointment) => {
    return {
        type:SELECT_APPOINTMENT_FULLFILLED,
        payload:selectedAppointment
    }
}

const selectAppointmentRequest = (selectedAppointmentRequest) => {
    return {
        type:SELECT_APPOINTMENT_REQUEST,
        payload:selectedAppointmentRequest
    }
}

const clearSelectedAppointment = (selectedAppointment) => {
    return {
        type:CLEAR_SELECTED_APPOINTMENT
    }
}

const clearSelectedAppointmentRequest = (selectedAppointmentRequest) => {
    return {
        type:CLEAR_SELECTED_APPOINTMENT_REQUEST
    }
}

const saveAppointment = (appointment) =>  { 
            return {
                type:SAVE_APPOINTMENT,
                payload:appointment
            }
        }

const saveAppointmentFullfilled = (appointment) =>  { 
            return {
                type:SAVE_APPOINTMENT_FULLFILLED,
                payload:appointment
            }
        }

    const saveAppointmentRequest = (appointmentRequest) =>  { 
            return {
                type:SAVE_APPOINTMENT_REQUEST,
                payload:appointmentRequest
            }
        }

const saveAppointmentRequestFullfilled = (appointmentRequest) =>  { 
            return {
                type:SAVE_APPOINTMENT_REQUEST_FULLFILLED,
                payload:appointmentRequest
            }
        }

const deleteAppointment = (appointment) => { 
            return {
                type:DELETE_APPOINTMENT,
                payload:appointment
            }
        }

const deleteAppointmentFullfilled = (appointment) => { 
            return {
                type:DELETE_APPOINTMENT_FULLFILLED,
                payload:appointment
            }
        }

const rejectAppointmentRequest = (appointmentRequest) => { 
            return {
                type:REJECT_APPOINTMENT_REQUEST,
                payload:appointmentRequest
            }
        }

const rejectAppointmentRequestFullfilled = (appointmentRequest) => { 
            return {
                type:REJECT_APPOINTMENT_REQUEST_FULLFILLED,
                payload:appointmentRequest
            }
        }

const appendAppointmentToApptRequest = (selectedAppointmentRequest, selectedClients) => {
            return {
                type:APPEND_APPOINTMENT_TO_APPT_REQUEST,
                payload:{selectedAppointmentRequest, selectedClients}
            }
}

const appendAppointmentToApptRequestFullfilled = (appointmentRequest) => {
    return {
        type:APPEND_APPOINTMENT_TO_APPT_REQUEST_FULLFILLED,
        payload:appointmentRequest
    }
}

export { 
    fetchSelectAppointment,
    fetchAppointmentRequests,
    fetchAppointmentRequestsFullfilled,
    selectAppointment,
    selectAppointmentFullfilled,
    clearSelectedAppointment,
    fetchReminders,
    fetchRemindersFullfilled,
    fetchAppointments,
    fetchAppointmentsFullfilled,
    rejectAppointmentRequest,
    rejectAppointmentRequestFullfilled,
    fetchGoogleEvents,
    fetchGoogleEventsFullfilled,
    fetchMicrosoftEvents,
    fetchMicrosoftEventsFullfilled,
    selectAppointmentRequest,
    saveAppointmentRequest, 
    saveAppointmentRequestFullfilled, 
    saveAppointment, 
    saveAppointmentFullfilled, 
    deleteAppointment, 
    deleteAppointmentFullfilled,
    setCommunicationPreferences,
    clearSelectedAppointmentRequest,
    appendAppointmentToApptRequest,
    appendAppointmentToApptRequestFullfilled,
    fetchHolidayEvents,
    fetchHolidayEventsFullfilled,
    fetchBirthdays,
    fetchBirthdaysFullfilled
}