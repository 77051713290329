

export function sortByOffsetTypes(offsetTypes, items) {
    let sortedOffsetTypes = offsetTypes.sort((a,b) => b.offset-a.offset);
    let result = sortedOffsetTypes.reduce((sortedItems, offsetType) => {
            return sortedItems.concat(items.filter(item => {
                return item.offset_type === offsetType.name
            }))
            }
            ,[]);
        return result;
}