import React from 'react';
import { Icon } from 'semantic-ui-react';
import './SelectionCalendarMonth.css';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';

const localizer = BigCalendar.momentLocalizer(moment);

const SelectionCalendarMonth = (data) => (
  <BigCalendar
    drilldownView={null}
    localizer={localizer}
    components={{
      toolbar: (tb) => (
        <div className="selection-month-toolbar">
            <Icon name="angle left" onClick={() => tb.onNavigate('PREV')} />
            <span>{tb.label}</span>
            <Icon name="angle right" onClick={() => tb.onNavigate('NEXT')} />
        </div>
      )
    }}
    selectable={true}
    onSelectSlot={(select) => data.handleNavigate(new Date(select.start))}
    startAccessor="start"
    endAccessor="end"
    events={[]}
    className="selection-month"
    defaultDate={data.date}
  />
)


export default SelectionCalendarMonth;