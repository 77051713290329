import React, { Component } from 'react';
import { CalendarContainer, ChangePasswordContainer, ClientsContainer, TagsContainer, SettingsContainer, NavigationTabsContainer, LoginContainer } from 'containers/';
import {Image} from 'semantic-ui-react';
import { Route, Switch } from 'react-router'
import { ConnectedRouter} from 'react-router-redux'
import MICIntegration from 'components/MICIntegration/MICIntegration';
import Joyride, { EVENTS, ACTIONS } from 'react-joyride';
import userSettingsImg from 'assets/shutterstock_524208406.jpg';
import {TourCard} from 'components/Tour/TourCard';

//import  from 'react-joyride/lib/constants';
import {
    Sidebar
} from 'semantic-ui-react';

import { ErrorDisplayContainer } from 'containers/ErrorDisplayContainer/ErrorDisplayContainer';
import MICButton from 'components/MICIntegration/MICButton';

import './Main.css'
import { SmartTagsContainer } from '../../containers/SmartTagsContainer/SmartTagsContainer';
import { IS_DEV } from '../../App';
import { CampaignsContainer } from '../../containers/CampaignsContainer/CampaignsContainer';

export class Main extends Component {
  constructor(props) {
    super(props);
    this.micBaseURL = `https://messagecenter.${IS_DEV?'dev.':''}mojo.accountants/cgi-bin/WebObjects/bullhornBackend.woa/wa/ClientAPI/`;
    this.state = {
      run: true,
      stepIndex: 0,
      steps: [
        {
          disableBeacon: true,
          target: 'body',
          content: (
            <div>
              <div style={{marginLeft: -17, marginRight: -17, marginTop: -17, marginBottom: 15}}>
                <Image src={userSettingsImg} className="header-img" fluid/>
              </div>
              <p>
                Appointment Booking and Management made easy
              </p>
              <p>
                Let's get started by highlighting some of the top features.
              </p>
            </div>
          ),
          placement: 'center',
          title: 'Welcome to OfficeMojo'
        },
        {
          disableBeacon: true,
          target: '.rbc-calendar',
          content: 'The Calendar - Here you\'ll create, edit and manage your edit appointment book. Click anywhere within the calendar or on an existing appointment to add/edit an appointment. For each appointment listed on your calendar we\'ll automatically send notifications and reminders to clients.',
          placement: 'bottom',
          title: 'The Calendar'
        },
        {
          disableBeacon: true,
          target: '.rbc-toolbar',
          content: 'Calendar Navigation – use these buttons to control the date and look of your calendar ',
          placement: 'bottom',
          title: 'Calendar Navigation'
        },
        {
          disableBeacon: true,
          target: '.mic_wrapper',
          content: 'Message Center - This allows you to connect with clients via a 2-way text channel. Send messages, confirm, and reschedule appointments and more.',
          placement: 'left',
          title: 'Message Center'
        },
        {
        disableBeacon: true,
        target: '#main-nav .navigation-clients-button',
        content: 'Clients – Here you\'ll be able to create and manage all your client records. Add new clients and all their details. Our support team can even import records from Google, Outlook, and CSV.',
        placement: 'bottom',
        title: 'Clients'
      },
      {
        disableBeacon: true,
        target: '#main-nav .navigation-tags-button',
        content: 'Tags – Tags are a powerful tool that allow you to group and flag clients into groups to make sending communications, marketing and other messages to easy. Anytime you add a new client or create a new appointment you\'ll be able to tag them appropriately to keep them organized.',
        placement: 'bottom',
        title: 'Tags'
      },
      {
        disableBeacon: true,
        target: '#main-nav .navigation-smarttags-button',
        content: 'SmartTags – SmartTags are a helpful tool that groups clients by categories to make it easier to send targeted emails. SmartTags are automatically assigned based on the clients’ attributes. Age, for example.',
        placement: 'bottom',
        title: 'SmartTags'
      },
      {
      disableBeacon: true,
        target: '#main-nav .navigation-settings-button',
      content: 'Settings – Here you finish setting up your account, manage your communication preferences and other settings.',
      placement: 'bottom',
      title: 'Settings'
    }
  ]
    }
  }

  _handleClickPreviousButton() {
    const { stepIndex } = this.state;

    if (this.state.stepIndex > 0) {
      this.setState({
        stepIndex: stepIndex - 1
      });
    }
  }

  _handleClickNextButton() {
    const { stepIndex } = this.state;

    if (this.state.stepIndex < this.state.steps.length) {
      this.setState({
        stepIndex: stepIndex + 1
      });
    }
  }

  componentDidMount() {
    if(window.location.search&&window.location.search.includes('?at=')) {
      const token = window.location.search.substring(4).split('&')[0];
      this.props.onSSOLogin(token)
    }
    /* import('components/Tour/Welcome')
      .import { TourCard } from 'components/Tour/TourCard';
then(({import { ChangePassword } from 'componentsChangePassword/ChangePassword';
 welcomimport { ChangePasswordContainer } from 'containers/ChangePasswordContainer/ChangePasswordContainer';
eTour: welcomeTourSteps }) => {
      this.setState({
        ...this.state,
        steps: welcomeTourSteps({
          _handleClickNextButton: () => this._handleClickNextButton(),
          _handleClickPreviousButton: () => this._handleClickPreviousButton(),
        }),
        run: true,
      })
    }) */
  }

  render() {
    const { isAuthorized, history, showMIC, mic_token, openMIC, closeMIC, reloadSyncSettings, professionalId} = this.props;
    const { steps, stepIndex } = this.state;
    if (!isAuthorized) {
      return (
        <ConnectedRouter history={history}>
        <React.Fragment>
        <ErrorDisplayContainer />
          <Switch>
            <Route path="/pwd" component={ChangePasswordContainer} />
            <Route path="/" component={LoginContainer} />
          </Switch>
          </React.Fragment>
        </ConnectedRouter>
      )
    }
    return (
      <ConnectedRouter history={history}>
        <div className="App">
          <MICButton
            showMIC={showMIC}
            token={mic_token}
            openOnStart={false}
            openMIC={() => openMIC()}
            closeMIC={() => closeMIC()}
            cssURL="https://www.cpasitesolutions.com/office-mojo/message-center.css"
            baseURL={this.micBaseURL}
          /> 
          <Joyride
            run={this.props.show_tour}
            steps={steps}
            continuous={true}
            locale={{ last: 'Complete Setup' }}
            //spotlightClicks={true}
            tooltipComponent={({...args}) => <TourCard {...args}/>}
            stepIndex={stepIndex}
            callback={({ action, index, type }) => {
              if ([EVENTS.STEP_AFTER, EVENTS.CLOSE, EVENTS.TARGET_NOT_FOUND].includes(type)) {
                if ( ACTIONS.CLOSE === action) {
                  this.props.endTour();
                    this.setState({
                      ...this.state,
                      run: false,
                      stepIndex: 0})
                } else {
                const newIndex = index + (action === ACTIONS.PREV ? -1 : 1);
                this.setState({ stepIndex: newIndex });
                if(newIndex === steps.length) {
                  this.props.history.push('/settings')
                  this.props.endTour();
                  this.setState({
                    ...this.state,
                    run: false,
                    stepIndex: 0})
                }
              }
            }
            }}
          />
          <Sidebar.Pushable id="pushable-main">
            <div id="container-main">
              <NavigationTabsContainer onLogOut={() => this.onLogout()} />
              <ErrorDisplayContainer />
              <Switch>
                <Route path="/clients" component={ClientsContainer} navKey="clients" />
                <Route path="/tags" component={TagsContainer} navKey="tag" />
                <Route path="/smarttags" component={SmartTagsContainer} navKey="smarttag" />
                <Route path="/campaigns" component={CampaignsContainer} navKey="campaigns" />
                <Route path="/settings" component={SettingsContainer} navKey="settings" />
                <Route path="/" component={CalendarContainer} navKey="calendar" />
              </Switch>              
            </div>
            <Sidebar
              animation='overlay'
              visible={showMIC}
              direction='right'
              id="container-MIC"
            >
              <MICIntegration
                showMIC={showMIC}
                token={mic_token}
                openOnStart={false}
                closeMIC={() => closeMIC()}
                baseURL={this.micBaseURL}
                cssURL="https://www.cpasitesolutions.com/office-mojo/message-center.css"
              />
            </Sidebar>
          </Sidebar.Pushable>
        </div>
      </ConnectedRouter>
    )
  }
}