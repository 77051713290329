import React from 'react';
import moment from 'moment'

const HeaderDay = ({ date }) => {
  return (
    <div>
      <p className="rbc-header-day">
        {moment(date).format('ddd')}
      </p>
      <p className="rbc-header-date">
        {moment(date).format('DD')}
      </p>
    </div>
  )
}

export default HeaderDay;