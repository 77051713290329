export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_FULLFILLED = 'RESET_PASSWORD_FULLFILLED';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
export const REQUEST_PASSWORD_RESET_FULLFILLED = 'REQUEST_PASSWORD_RESET_FULLFILLED';

export function resetPassword(token,newPassword) {
    return {
        type: RESET_PASSWORD,
        payload: {
            newPassword,
            token
        }
    }
}


export function resetPasswordFullfilled() {
    return {
        type: RESET_PASSWORD_FULLFILLED
    }
}

export function resetPasswordFailed() {
    return {
        type: RESET_PASSWORD_FAILED
    }
}


export function requestPasswordReset(username, scope) {
    return {
        type: REQUEST_PASSWORD_RESET,
        payload: {
            username,
            scope
        }
    }
}

export function requestPasswordResetFullfilled() {
    return {
        type: REQUEST_PASSWORD_RESET_FULLFILLED
    }
}